import { List, Map, fromJS } from "immutable";
import orderConstants from "../constants/orderConstants";
/**
 * Project reducer redux
 */

const initialState = Map({
	id: null,
	project: Map(),
	projectAction: false,
	projectRequest: false,
	company: Map(),
	projectsList: Map(),
	projectsMeta: Map(),
	orderUsers: List(),
	orderCompanies: List(),
	loadOffers: List(),
	getFailure: "",
	updateFailure: "",
	action: false,
	request: false,
	modal: {
		action: false,
		request: false,
		id: null,
		price: null,
	},
	errorMessage: "",
	successMessage: "",
	stats: null,
});

export default (state = initialState, action) => {
	/**
	 * Async load offers for select field
	 *
	 * @param {object} data - {offers, values,isForSingleView}
	 * @returns
	 */
	const updateAsyncLoadOffers = (data) => {
		const { offers, values, isForSingleView = false } = data;

		const offersJS = state.get("loadOffers")?.toJS() || [];
		let findOffers = [];

		if (isForSingleView) {
			const id = values && [+values?.value];

			findOffers =
				(offersJS.length > 0 &&
					id?.length > 0 &&
					offersJS.filter((offer) => id.includes(+offer.id))) ||
				[];

			if (findOffers.length === 0 && !!values?.value) {
				findOffers = [{ name: values?.label, id: values?.value }];
			}
		} else {
			const valuesIds = values && values.map((item) => +item?.value);

			if (valuesIds?.length > 0)
				findOffers =
					(offersJS.length > 0 &&
						offersJS.filter((offer) => valuesIds.includes(+offer.id))) ||
					[];
		}

		let tempOffers = [...findOffers, ...offers];

		//Remove duplicates from tempOffers
		const ids = tempOffers.map(({ id }) => id);
		const filteredOffers = tempOffers.filter(
			({ id }, index) => !ids.includes(id, index + 1)
		);

		return state.set("loadOffers", fromJS(filteredOffers));
	};

	switch (action.type) {
		case orderConstants.GET_PROJECTS:
			return state
				.set("projectsList", fromJS(action.data.result))
				.set("projectsMeta", fromJS(action.data.meta));

		case orderConstants.GET_PROJECT:
			let { offer, updateLodSPecificationIds } = action.data;
			const data = offer?.result || null;

			const buildings = data?.buildings;
			let lodSpecificationIds = [];

			buildings.forEach((building) => {
				lodSpecificationIds.push(building?.lodSpecification?.id);
			});

			if (updateLodSPecificationIds) {
				return state
					.set("project", fromJS(data))
					.setIn(["project", "lodSpecificationId"], lodSpecificationIds[0])
					.setIn(["project", "buildingId"], +buildings[0]?.id || null);
			}
			return state.set("project", fromJS(data));

		case orderConstants.GET_PROJECT_FILES:
			return state
				.setIn(["projectFiles", "filesList"], fromJS(action.data.result))
				.setIn(["projectFiles", "filesMeta"], fromJS(action.data.meta));

		case orderConstants.P_ACTION_FAILURE:
			return state
				.set("projectAction", false)
				.set("projectRequest", true)
				.set("getFailure", fromJS(action.error))
				.set("updateFailure", fromJS(action.error));

		case orderConstants.ACTION_GET_FAILURE:
			return state.set("getFailure", fromJS(action.error));

		case orderConstants.SET_SNACKBAR_MESSAGE:
			return state.set("snackbarMessage", fromJS(action.message));

		case orderConstants.CLEAR_PROJECT_REQUEST_STATE:
			return state
				.set("action", false)
				.set("request", false)
				.set("errorMessage", "")
				.set("successMessage", "");

		case orderConstants.PROJECT_ACTION_GET_FAILURE:
			return state
				.set("action", false)
				.set("request", true)
				.set("errorMessage", fromJS(action.data));

		case orderConstants.PROJECT_ACTION_GET_SUCCESS:
			return state
				.set("successMessage", fromJS(action.data))
				.set("action", true)
				.set("request", true);

		case orderConstants.PROJECT_ACTION_SUCCESS:
			return state.set("action", true).set("request", true);

		case orderConstants.PROJECT_ACTION_ERROR:
			return state.set("action", false).set("request", true);

		case orderConstants.PROJECT_SET_ERROR_MESSAGE:
			return state.set("errorMessage", fromJS(action.data));

		case orderConstants.PROJECT_SET_SUCCESS_MESSAGE:
			return state.set("successMessage", fromJS(action.data));

		case orderConstants.ORDER_STATS:
			return state.set("stats", fromJS(action.data.result));

		case orderConstants.GET_PROJECT_LOD_SPECIFICATION:
			return state
				.set("lodSpecification", fromJS(action.data.result))
				.setIn(["project", "lodSpecificationId"], null)
				.setIn(["project", "buildingId"], null);

		case orderConstants.OFFER_MODAL_ACTION_SUCCESS:
			const { offerId, price } = action.data;
			const modal = {
				action: true,
				request: true,
				id: offerId,
				price: price,
			};
			return state.set("modal", fromJS(modal));

		case orderConstants.CLEAR_OFFER_MODAL_REQUEST_STATE:
			return state.set("modal", initialState.get("modal"));

		case orderConstants.SET_HTML_ADDITIONAL_INFO:
			return state.set("additionalInfoHTML", action.data);

		case orderConstants.UPDATE_ASYNC_LOAD_OFFERS:
			return updateAsyncLoadOffers(action.data);

		case orderConstants.GET_ORDER_COMPANIES:
			return state.set("orderCompanies", fromJS(action.data));

		case orderConstants.GET_ORDER_USERS:
			return state.set("orderUsers", fromJS(action.data));

		case orderConstants.CLEAR_ORDER_USERS:
			return state.set("orderUsers", List());

		case orderConstants.CLEAR_PROJECT:
			return initialState;

		default:
			return state;
	}
};
