import { reset } from "redux-form";
import userConstants from "../constants/userConstants";
import { api } from "../api";
import { SubmissionError } from "redux-form";
import history from "../history";
import companyConstants from "../constants/companyConstants";
import localize, { LocKeys } from "../constants/localizations";
import { getToken, tokenParams } from "../utils/local-storage.util";
import { refreshToken } from "./authActions";
import { statusConstants } from "../constants/constants";
import { getPagination, getUserFilter } from "../utils/table-filter";

/**
 * GET Users by company
 * @param {number} companyId - unique company identifier
 * @param {number} page - current page
 * @param {string} searchQuery - search query
 * @param {Object} searchFilters - search filters
 * @param {boolean} usePagination - use pagination true/false
 * @param {number} limit - limit of items per page
 */
export const getUsersByCompany = ({
	companyId,
	page = 1,
	searchQuery = "",
	searchFilters = null,
	usePagination = true,
	limit = 10,
	isReseller = false,
	resellersByCompany = false
}) => {
	return (dispatch, getState) => {
		const state = getState();

		const userQueryParameter = state.getIn([
			"queryParameter",
			"userQueryParameter",
		]);
		const tablePagination = state.getIn(["form", "tablePagination", "values"]);

		const { filters, search } = getUserFilter({
			queryParameter: userQueryParameter,
			searchFilters,
			searchQuery,
		});

		const { currentPage, currentLimit } = getPagination({
			tablePagination,
			page,
			limit,
		});

		const requestOptions = {
			method: "PUT",
			url: `/user/by-company/${companyId}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data: {
				page: usePagination ? currentPage : page,
				search: usePagination ? search : "",
				filters: usePagination ? filters : searchFilters,
				limit: usePagination ? currentLimit : limit,
				usePagination,
			},
		};
		return api(requestOptions).then(
			(res) => {
				if (isReseller) {
					dispatch(getResellerUsers(res.data?.result));
				}

				if(resellersByCompany) {
					dispatch({ type: userConstants.GET_RESELLER_USERS_BY_COMPANY, data: res.data?.result });
				}

				if (!isReseller && !resellersByCompany) {
					dispatch({ type: userConstants.GET_USERS, data: res.data });
				}

				dispatch(refreshToken());
			},
			(err) => {
				dispatch(userActionFailure(err.response.data.message));
			}
		);
	};
};

//Method: Get single user - take single user data based on the id
export const getUser = (id) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/user/${id}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: userConstants.GET_USER,
					data: res.data.result,
				});

				dispatch({
					type: companyConstants.GET_COMPANY,
					data: res.data.result.company,
				});
			},
			(err) => {
				history.goBack();
				dispatch(userActionFailure(err.response.data.message));
			}
		);
	};
};

//Method: Create user - send data about new user to the endpoint and save it to the database
export const createUser = (data, cb = () => {}) => {
	let bodyFormData = new FormData();
	Object.keys(data).forEach((key) => bodyFormData.append(key, data[key]));
	return (dispatch) => {
		const requestOptions = {
			method: "POST",
			url: `/user`,
			headers: {
				Authorization: "Bearer " + getToken(),
				"Content-Type": "multipart/form-data",
			},
			data: bodyFormData,
		};
		return api(requestOptions).then(
			(res) => {
				dispatch(
					userActionSuccess(
						localize(LocKeys.ITEM_CREATED, [localize(LocKeys.USER)])
					)
				);

				dispatch(refreshToken());
				cb();
			},
			(err) => {
				const result = err.response.data?.result;

				if (result?.field) {
					switch (result.field) {
						case "email":
							throw new SubmissionError({
								email: result.message,
							});
						case "phone":
							throw new SubmissionError({
								phone: result.message,
							});
						default:
							history.goBack();
							dispatch(
								userActionFailure(
									localize(LocKeys.ERROR_ITEM_CREATED, [localize(LocKeys.USER)])
								)
							);
							break;
					}
				} else {
					history.goBack();
					dispatch(
						userActionFailure(
							localize(LocKeys.ERROR_ITEM_CREATED, [localize(LocKeys.USER)])
						)
					);
				}
			}
		);
	};
};

//Method: Update user - change data of single user and send it to the endpoint
export const editUser = (id, data) => {
	let bodyFormData = new FormData();
	Object.keys(data).forEach((key) => bodyFormData.append(key, data[key]));

	const user = tokenParams();

	const currentUserId = user.id;
	const profileUpdate = Number(currentUserId) === Number(id);
	const message = profileUpdate
		? localize(LocKeys.PROFILE_UPDATE_SUCCESS)
		: localize(LocKeys.ITEM_UPDATED, [localize(LocKeys.USER)]);

	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/user/${id}`,
			headers: {
				Authorization: "Bearer " + getToken(),
				"Content-Type": "multipart/form-data",
			},
			data: bodyFormData,
		};
		return api(requestOptions).then(
			(res) => {
				profileUpdate && dispatch(refreshToken());

				history.goBack();

				dispatch(userActionSuccess(message));
			},
			(err) => {
				const result = err.response.data.result;

				if (result && result.field) {
					switch (result.field) {
						case "email":
							throw new SubmissionError({
								email: result.message,
							});

						case "phone":
							throw new SubmissionError({
								phone: result.message,
							});

						default:
							history.goBack();
							dispatch(
								userActionFailure(
									localize(LocKeys.ERROR_ITEM_UPDATED, [localize(LocKeys.USER)])
								)
							);
							break;
					}
				} else {
					history.goBack();
					dispatch(
						userActionFailure(
							localize(LocKeys.ERROR_ITEM_UPDATED, [localize(LocKeys.USER)])
						)
					);
				}
			}
		);
	};
};

/**
 *
 * @param {*} isActive
 * @param {*} id
 * @param {*} companyId
 * @returns
 */
export const toggleUserStatus = (isActive, ids, companyId = null) => {
	let urlPart = "archive";
	let responseMessage = localize(LocKeys.ITEM_ARCHIVE_SUCCESS, [
		localize(LocKeys.USER),
	]);

	if (!isActive) {
		urlPart = "activate";
		responseMessage = localize(LocKeys.ITEM_ACTIVE_SUCCESS, [LocKeys.USER]);
	}

	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/user-${urlPart}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data: { ids: ids },
		};
		return api(requestOptions).then(
			(res) => {
				dispatch(getUsersByCompany({ companyId }));

				dispatch(userActionSuccess(responseMessage));
			},
			(err) => {
				dispatch(userActionFailure(err.response));
			}
		);
	};
};

/**
 *
 * @param {*} ids
 * @param {*} companyId
 * @returns
 */
export const deleteUser = (ids, companyId = null) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/user-soft-delete`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data: { ids: ids },
		};
		return api(requestOptions).then(
			(res) => {
				const filters = {
					status: [statusConstants.ARCHIVED],
				};
				dispatch(
					getUsersByCompany({
						companyId,
						page: 1,
						searchQuery: "",
						searchFilters: filters,
					})
				);

				dispatch(
					userActionSuccess(
						localize(LocKeys.ITEM_DELETED, [localize(LocKeys.USER)])
					)
				);
			},
			(err) => {
				dispatch(
					userActionFailure(
						localize(LocKeys.ERROR_ITEM_DELETED, [localize(LocKeys.USER)])
					)
				);
			}
		);
	};
};

//Method: Get companies - Get list of all companies in the system
export const getUserCompanies = (type = null) => {
	return (dispatch) => {
		const query = type ? `?type=${type}` : "";
		const requestOptions = {
			method: "GET",
			url: `/company/user-companies${query}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: userConstants.GET_USER_COMPANIES,
					data: res.data.result,
				});
			},
			(err) => {
				dispatch(userActionFailure(err.response.data.message));
			}
		);
	};
};

//Method: Resend actication link - triger endpoint to send activation link to the email sent in params
export const resendActivation = (id) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PATCH",
			url: `/user/${id}/resend-invite`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch(actionResendInvitation(res.data?.result, true));
			},
			(err) => {
				dispatch(actionResendInvitation(err.response?.data?.result, false));
			}
		);
	};
};

/**
 * Retrieves all user roles
 */
export const getUserRoles = () => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/user/roles`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: userConstants.GET_USER_ROLES,
					data: res.data.result,
				});
			},
			(err) => {
				console.log(err);
			}
		);
	};
};

/**
 * Get user roles by company type
 * @param {*} companyType
 * @returns
 */
export const getUserRolesByCompanyType = (companyType) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/user-roles-by-company-type`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data: { companyType },
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: userConstants.GET_USER_ROLES,
					data: res.data.result,
				});
			},
			(err) => {
				console.log(err);
			}
		);
	};
};

/**
 * PUT Method for Logged in user password change
 * Sends a change password request to endpoint
 * @param {string} oldPassword
 * @param {string} newPassword
 * @param {string} repeatPassword
 * @param {string} id
 */

export const changePassword = (
	oldPassword,
	newPassword,
	repeatPassword,
	id
) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
			url: `user/change-password/${id}`,
			data: {
				oldPassword: oldPassword,
				newPassword: newPassword,
				repeatPassword: repeatPassword,
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch(userActionSuccess(localize(LocKeys.PASSWORD_UPDATED)));
			},
			(err) => {
				const result = err.response.data.result;
				if (result?.field) {
					switch (result.field) {
						case "oldPassword":
							throw new SubmissionError({
								oldPassword: result.message,
							});

						default:
							dispatch(userActionFailure(err.response.data.result));
							break;
					}
				} else {
					dispatch(userActionFailure(err.response.data.result));
				}
			}
		);
	};
};

export const verifyEmailUpdate = (email, userId) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
			url: `/user/verify-email-update/${email}`,
		};
		return api(requestOptions).then(
			(res) => {
				history.push("/settings");
				dispatch(userActionSuccess(localize(LocKeys.EMAIL_UPDATE_SUCCESS)));
				dispatch(getUser(userId));
			},
			(err) => {
				history.goBack();
				dispatch(userActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * GET Users by logged user
 */
export const getUsersByLoggedUser = () => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/users-by-user`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: userConstants.GET_USERS_BY_LOGGED_USER,
					data: res.data.result,
				});
			},
			(err) => {
				dispatch(userActionFailure(err.response.data.message));
			}
		);
	};
};

//---------------------------------CUSTOM ACTIONS---------------------------------

export const getResellerUsers = (data) => {
	return (dispatch) => {
		dispatch({
			type: userConstants.GET_RESELLER_USERS,
			data,
		});
	};
};

export const clearEditUserForm = () => {
	return (dispatch) => {
		dispatch(reset("userForm"));
		dispatch({ type: userConstants.CLEAR_CURRENT_USER });
	};
};

export const setUserImage = (data) => {
	return { type: userConstants.SET_USER_IMAGE, data };
};

//ALL METHODS DOWN - Used for creating custom action with type and payload
export const userActionSuccess = (data = "") => {
	return (dispatch) => {
		dispatch({ type: userConstants.USER_ACTION_GET_SUCCESS, data });
	};
};

export const userActionFailure = (data) => {
	return { type: userConstants.USER_ACTION_GET_FAILURE, data };
};

export const userRequestDone = () => {
	return { type: userConstants.U_REQUEST_DONE };
};

export const clearUserRequestState = () => {
	return { type: userConstants.USER_CLEAR_REQUEST_STATE };
};

export const actionResendInvitation = (message, sent) => {
	return { type: userConstants.ACTION_ACTIVATION, data: { message, sent } };
};
