import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form/immutable";
import Level from "./Level";
import "./bimifyBuilding.scss";
import Typography from "@mui/material/Typography";
import CustomSkeleton from "../skeleton/CustomSkeleton";
import localize, { LocKeys } from "../../../constants/localizations";

/**
 * Shared component for building levels - only view
 * @param {*} props
 * @returns
 */
const CustomLevels = (props) => {
	const {
		levels,
		formName,
		setValue,
		hideRightSide,
		wrapperClasses = "overflow-y-auto page-content--xs  h-100 bg-n300 pb-24",
		wrapperClassesLevel,
		buildingModelClasses = "pt-24",
		renderField,
	} = props;
	let formatValid = [];

	//	const lowestLevel = levels?.slice(-1)[0]?.level || 0;

	return (
		<div className={`single-building__bimify  ${wrapperClasses}  w-100`}>
			{!hideRightSide && (
				<div className={`px-124 bg-n000`}>
					<div className="border-b-1 border-n150">
						<div className="row row--m-gutters pt-m pb-s">
							<div className="col col-60"></div>
							<div className="col col-40 d-flex align-items-end">
								<div className="row row--no-gutters">
									<div className="col col-20 pl-0 pr-0"></div>
									<div className="col col-40 pr-0">
										<h5 className="small color-n300">
											{localize(LocKeys.ELEVATION)}
										</h5>
									</div>
									<div className="col col-40 pr-0">
										<h5 className="small color-n300">
											{localize(LocKeys.THICKNESS)}
										</h5>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}

			<div className={`building-model ${buildingModelClasses} bg-n000 h-100`}>
				<div className="floors  bg-n300  h-100">
					{levels && levels.length > 0 ? (
						levels.map((level) => {
							let last = levels.length - 1;
							let isLowestFloor = levels[last]?.key === level.key;
							let isHighestFloor = levels[0]?.key === level.key;
							let isSecondFloor = levels[1]?.key === level.key;

							formatValid.push(level.fileFormatValid);
							return (
								<div
									className={`
                                    floor__wrapper w-100  ${wrapperClassesLevel}
									${isHighestFloor ? "h-46p" : ""}
                                    ${
																			level.level >= 0 || !!level.isRoof
																				? "bg-n000"
																				: "bg-n300"
																		}
                                `}
									key={level.level}
								>
									<Level
										isSecondFloor={isSecondFloor}
										formName={formName}
										setValue={setValue}
										isLowestFloor={isLowestFloor}
										isHighestFloor={isHighestFloor}
										hideRightSide={hideRightSide}
										floor={level}
										renderField={renderField}
									/>
								</div>
							);
						})
					) : (
						<div className="d-flex justify-content-center align-items-center h-100 w-100 bg-n000">
							<div className="w-50">
								<Typography variant={"h2"}>
									<CustomSkeleton />
									<CustomSkeleton />
									<CustomSkeleton />
									<CustomSkeleton />
									<CustomSkeleton />
									<CustomSkeleton />
								</Typography>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	return {
		form: ownProps.formName,
	};
};

export default connect(
	mapStateToProps,
	{}
)(
	reduxForm({
		//validate,
		touchOnBlur: false,
		destroyOnUnmount: false,
	})(CustomLevels)
);
