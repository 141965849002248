import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom-v5-compat";
import { reduxForm } from "redux-form/immutable";
import getURLParams from "../../../utils/getURLParams";
import {
	getPackage,
	setCancelFormOrder,
	setStateFormPackageConfiguration,
} from "../../../actions";
import { removeItemFromLocalStorage } from "../../../utils/local-storage.util";
import localize, { LocKeys } from "../../../constants/localizations";
import CustomStepper from "../../../shared/components/CustomStepper/CustomStepper";
import { HeadLayout } from "../../layout/HeadLayout";
import { ContentLayout } from "../../layout/ContentLayout";
import OrderInformation from "../../orders/crud/steps/Step1.0-OrderInformation";
import BuildingConfiguration from "./steps/BuildingConfiguration";
import FileUpload from "./steps/FileUpload";
import AdditionalInformation from "../../orders/crud/steps/Step3.0-AdditionalInformation";

/**
 * CREATE offer form steps (1-4)
 * 		1. OrderInformation
 * 		2. BuildingConfiguration
 * 		3. FileUpload
 * 		4. AdditionalInformation
 */
const CreateOrder = (props) => {
	const { getPackage, setStateFormPackageConfiguration, setCancelFormOrder } =
		props;
	const navigate = useNavigate();
	const [page, setPage] = useState(1);
	const completedSteps = [];
	const params = getURLParams();
	const packageId = params?.get("packageId");
	const isActiveConfiguration = params?.get("isActiveConfiguration");

	useEffect(() => {
		if (packageId && isActiveConfiguration) {
			setStateFormPackageConfiguration();
		} else if (!!packageId && !isActiveConfiguration) {
			getPackage(packageId, false, true);
		}
	}, [
		packageId,
		getPackage,
		isActiveConfiguration,
		setStateFormPackageConfiguration,
	]);

	const nextPage = () => {
		setPage(page + 1);
	};

	const previousPage = () => {
		setPage(page - 1);
	};

	const goTo = (specificPage = 0) => {
		setPage(specificPage);
	};

	const cancelButtonAction = useCallback(() => {
		if (isActiveConfiguration) {
			setCancelFormOrder(true);
			removeItemFromLocalStorage("packageConfiguration");
		}
		navigate(-1, { replace: true });
	}, [isActiveConfiguration, navigate, setCancelFormOrder]);

	let steps = [
		{
			number: 1,
			step: 0,
			title: localize(LocKeys.OFFER_INFO),
			template: () => (
				<OrderInformation
					isNaviate={true}
					isActiveConfiguration={isActiveConfiguration}
					cancelButtonAction={() => cancelButtonAction()}
					formName="createOrderForm"
					nextPage={nextPage}
				/>
			),
		},
		{
			number: 2,
			step: 1,
			title: localize(LocKeys.BUILDING_CONFIGURATION),
			template: () => (
				<BuildingConfiguration
					formName="createOrderForm"
					nextPage={nextPage}
					cancelButtonAction={() => cancelButtonAction()}
					previousPage={previousPage}
				/>
			),
		},
		{
			number: 3,
			step: 2,
			title: localize(LocKeys.FILE_UPLOAD),
			template: () => (
				<FileUpload
					formName="createOrderForm"
					nextPage={nextPage}
					cancelButtonAction={() => cancelButtonAction()}
					previousPage={previousPage}
				/>
			),
		},
		{
			number: 4,
			step: 3,
			title: localize(LocKeys.ADDITIONAL_INFORMATION),
			template: () => (
				<AdditionalInformation
					isNaviate={true}
					cancelButtonAction={() => cancelButtonAction()}
					previousPage={previousPage}
				/>
			),
		},
	];

	const setStepCompleted = (stepNumber) => {
		completedSteps.push(stepNumber);
	};

	const renderStepper = () => {
		if (!currentPage) {
			return;
		}

		return (
			<CustomStepper
				completed={completedSteps}
				steps={steps}
				activeStep={currentPage}
				goTo={goTo}
				setStepCompleted={setStepCompleted}
			/>
		);
	};

	let currentPage = steps.find((step) => step.number === page);

	const renderView = () => {
		let selectedStep = steps.find((step) => step.number === page);

		return selectedStep.template();
	};

	return (
		<>
			<div className="d-flex flex-column">
				<>
					<HeadLayout
						showHeaderLayout={true}
						wrapperClasses="mb-24 page-header card pos-rel card--border--none card--2 bg-n000"
					>
						<div className="w-100  my-34 ml-regular">{renderStepper()}</div>
					</HeadLayout>
					<div className={`flex-1 pos-rel d-flex flex-column`}>
						<ContentLayout
							wrapperClasses="card card--border--none  card--2 bg-n000"
							showContentLayout={true}
						>
							{renderView()}
						</ContentLayout>
					</div>
				</>
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		companies: state.getIn(["user", "companies"]),
	};
};

export default connect(mapStateToProps, {
	getPackage,
	setStateFormPackageConfiguration,
	setCancelFormOrder,
})(
	reduxForm({
		form: "createOrderForm",
		destroyOnUnmount: false,
		touchOnBlur: false,
	})(CreateOrder)
);
