import React, { useState } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form/immutable";
import AuthenticationRequestPopups from "./AuthenticationRequestPopups";
import validate from "../fields/validateAuth";
import { integrationSignup, setPassword } from "../../actions";
import renderPasswordField from "../fields/renderPasswordField";
import getURLParams from "../../utils/getURLParams";
import Container from "./parts/Container";
import localize, { LocKeys } from "../../constants/localizations";
import renderCheckBoxField from "../fields/renderCheckBoxField";
import { useNavigate } from "react-router-dom-v5-compat";

/**
 * Set password component used for Registration component (Set password) and Reset password component
 */
const SetPassword = (props) => {
	const {
		isNaviate = false,
		userData = null,
		hidePolicy = false,
		integrationSignup,
	} = props;
	const navigate = useNavigate();
	const queryParams = getURLParams();
	const [termsAgreement, setTermsAgreement] = useState(false);

	const onSubmit = (formValues) => {
		let email = queryParams.get("email");
		let token = queryParams.get("setpasswordtoken");
		const data = {
			password: formValues.get("rePassword"),
			privacyPolicy: !!hidePolicy ? true : formValues.get("privacyPolicy"),
		};
		if (isNaviate) {
			data.email = userData.email;
			data.firstName = userData?.firstName || "";
			data.lastName = userData?.lastName || "";
			data.companyName = userData?.companyName || "";
			data.apiKey = userData?.apiKey || "";

			integrationSignup(data, (url) => navigate(url));
		} else {
			props.setPassword(data, email, token);
		}
	};

	const handleChange = (e) => {
		setTermsAgreement(e.target.checked);
	};

	const renderPrivacyPolicyLabel = () => {
		return (
			<>
				{localize(LocKeys.I_ACCEPT)} &nbsp;
				<a
					href="https://www.bimify.com/privacy-policy/"
					target="_blank"
					rel="noopener noreferrer"
				>
					{localize(LocKeys.PRIVACY_POLICY)}
				</a>
				&nbsp; {localize(LocKeys.AND)} &nbsp;
				<a
					href="https://www.bimify.com/terms-of-service/"
					target="_blank"
					rel="noopener noreferrer"
				>
					{localize(LocKeys.TERMS_OF_SERVICE)}.
				</a>
			</>
		);
	};
	return (
		<>
			<AuthenticationRequestPopups />

			<Container
				isNaviate={isNaviate}
				pageTitle={props.pageTitle}
				pageSubTitle={props.pageSubTitle}
			>
				<form
					className={"form standardized-form"}
					onSubmit={props.handleSubmit(onSubmit)}
					autoComplete="off"
					noValidate
				>
					<div className="mb-24">
						<Field
							name="rePassword"
							component={renderPasswordField}
							label="Password"
						/>
					</div>
					<div className="mb-24">
						<Field
							name="reConfirmPassword"
							component={renderPasswordField}
							label="Confirm Password"
						/>
					</div>
					{!hidePolicy && (
						<div className="mb-24">
							<Field
								name="privacyPolicy"
								component={renderCheckBoxField}
								label={renderPrivacyPolicyLabel()}
								handleChange={handleChange}
								checked={termsAgreement}
							/>
						</div>
					)}

					<button
						type="submit"
						className="btn btn--fluid btn--primary form__btn"
					>
						{localize(LocKeys.SAVE_AND_LOGIN)}
					</button>
				</form>
			</Container>
		</>
	);
};

export default connect(null, { setPassword, integrationSignup })(
	reduxForm({ form: "setPasswordForm", validate, touchOnBlur: false })(
		SetPassword
	)
);
