import React from "react";
import { connect } from "react-redux";
import RevitForgeViewer from "./parts/RevitForgeViewer";
import build from "../../../../assets/images/projects/building_gif.gif";
import FileViewer from "./parts/FileViewer";
import "./viewer.scss";
import { ErrorBoundary } from "react-error-boundary";

/**
 * VIEWER - FORGER (rvt,pdf,dwg,dxf.. file) viewer and FILE (image files) viewer
 * @param {*} props
 * @returns
 */
const Viewer = (props) => {
	const {
		shareModelUrn = null,
		enableResize = false,
		viewerUrn,
		forgeFileNotSupportedMessage,
		previewLink,
		fileName,
	} = props;

	const renderViewer = () => {
		if (forgeFileNotSupportedMessage) {
			return <>{forgeFileNotSupportedMessage}</>;
		}

		if (shareModelUrn || viewerUrn) {
			return (
				<RevitForgeViewer
					enableResize={enableResize}
					viewerUrn={shareModelUrn || viewerUrn}
				/>
			);
		} else if (previewLink) {
			return <FileViewer previewLink={previewLink} name={fileName} />;
		} else {
			return <img alt="Default gif" src={build}></img>;
		}
	};

	return (
		<ErrorBoundary FallbackComponent={() => <div>Error viewer!</div>}>
			<div
				className={`viewer  ${
					shareModelUrn || viewerUrn || previewLink ? "p-0" : " "
				}  text-center`}
			>
				{renderViewer()}
			</div>
		</ErrorBoundary>
	);
};

const mapStateToProps = (state, ownProps) => {
	const { jobView = false, isForPackage = false } = ownProps;
	let viewerUrn,
		fileName,
		previewLink,
		forgeFileNotSupportedMessage = null;

	if (jobView) {
		viewerUrn = state.getIn(["job", "bimModel", "viewerUrn"]);
		fileName = state.getIn(["job", "bimModel", "fileName"]);
		previewLink = state.getIn(["job", "bimModel", "previewLink"]);
		forgeFileNotSupportedMessage = state.getIn([
			"job",
			"bimModel",
			"forgeFileNotSupportedMessage",
		]);
	} else if (isForPackage) {
		viewerUrn = state.getIn([
			"dashboard",
			"salesPackage",
			"revitModel",
			"forgeUrnPreviewLink",
		]);
	} else {
		viewerUrn = state.getIn(["building", "buildingViewer", "viewerUrn"]);
		fileName = state.getIn(["building", "buildingViewer", "fileName"]);
		previewLink = state.getIn(["building", "buildingViewer", "previewLink"]);
		forgeFileNotSupportedMessage = state.getIn([
			"building",
			"forgeFileNotSupportedMessage",
		]);
	}
	return {
		viewerUrn: viewerUrn,
		fileName,
		previewLink,
		forgeFileNotSupportedMessage,
	};
};

export default connect(mapStateToProps, {})(Viewer);
