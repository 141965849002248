import { api } from "../api";
import { SubmissionError } from "redux-form";
import userConstants from "../constants/userConstants";
import { actionSuccess, actionFailure, loginUser } from "./authActions";
import { setItemToLocalStorage } from "../utils/local-storage.util";

export const integrationLogin = (data, cbSuccess = () => {}, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "POST",
			url: `/integration/login`,
			headers: {
				"Content-Type": "application/json",
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				const user = res.data.result?.user || false;

				if (user?.accessToken) {
					setItemToLocalStorage("isNaviate", true);

					dispatch(loginUser(user, cbSuccess));
				} else {
					cb();
				}
			},
			(err) => {
				throw new SubmissionError({
					email: err.response.data.message,
					password: err.response.data.message,
				});
			}
		);
	};
};

export const integrationSignup = (data, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "POST",
			url: `/integration/signup`,
			headers: {
				"Content-Type": "application/json",
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				dispatch(
					integrationLogin({ email: data.email, apiKey: data.apiKey }, cb)
				);
			},
			(err) => {
				dispatch(actionFailure(err.response.data.result));
			}
		);
	};
};

/**
 * SIGN UP action - (create company and user)
 * @param {object} data
 * @returns
 */
export const signUp = (data) => {
	return (dispatch) => {
		const requestOptions = {
			method: "POST",
			url: `/user/signup`,
			headers: {
				"Content-Type": "application/json",
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				const user = res.data.result.user;
				dispatch({
					type: userConstants.SET_TEMP_USER,
					data: user,
				});

				return res;
			},
			(err) => {
				if (err.response.status === 403) {
					switch (err.response.data.result?.field) {
						case "name":
							throw new SubmissionError({
								name: err.response.data.result.message,
							});
						case "email":
							throw new SubmissionError({
								email: err.response.data.result.message,
							});
						case "phone":
							throw new SubmissionError({
								phone: err.response.data.result.message,
							});
						case "registrationNumber":
							throw new SubmissionError({
								registrationNumber: err.response.data.result.message,
							});
						default:
							dispatch(actionFailure(err.response.data.result));
							break;
					}
				}

				dispatch(actionFailure(err.response.data.result));
			}
		);
	};
};

/**
 * CHECK code for phone number verification
 *
 * @param {object} data  - {userId,verifyCode}
 * @param {Function} cb - callback function
 * @returns
 */
export const verifyPhoneNumber = (data, cb) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/public/verify/phone-number`,
			headers: {
				"Content-Type": "application/json",
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				dispatch(actionSuccess());
				cb();
			},
			(err) => {
				throw new Error(err.response.data.result);
			}
		);
	};
};

/**
 * RESEND code for phone number verification
 * @param {object} data  - {userId}
 * @returns
 */
export const resendCode = (data) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/public/user/resend-sms-code`,
			headers: {
				"Content-Type": "application/json",
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				return res;
			},
			(err) => {
				dispatch(actionFailure(err.response.data.result));
			}
		);
	};
};

/**
 * CHECK code for 2FA - code verification
 *
 * @param {object} data  - {email,code}
 * @param {Function} cbSuccess - callback function for success response
 * @param {Function} cbError - callback function for error response
 * @returns
 */
export const check2FACode = (
	data,
	cbSuccess = () => {},
	cbError = () => {}
) => {
	return (dispatch) => {
		const requestOptions = {
			method: "POST",
			url: `/user/two-fa-auth`,
			headers: {
				"Content-Type": "application/json",
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				const user = res.data.result?.user || false;

				if (user) {
					dispatch(loginUser(user, cbSuccess));
				}
			},
			(err) => {
				cbError(err.response.data.result);
			}
		);
	};
};

/**
 * RESEND code for 2FA
 * @param {object} data  - {email}
 * @param {Function} cb  - callback function
 * @returns
 */
export const resend2FACode = (data, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "POST",
			url: `/user/two-fa-auth-resend`,
			headers: {
				"Content-Type": "application/json",
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				cb();
			},
			(err) => {
				dispatch(actionFailure(err.response.data.result));
			}
		);
	};
};

/**
 * DELETE  Unverified user
 * @param {*} data
 * @returns
 */
export const deleteUnverifiedUser = (data) => {
	return (dispatch) => {
		const requestOptions = {
			method: "DELETE",
			url: `/public/unverified-user`,
			headers: {
				"Content-Type": "application/json",
			},
			data: data,
		};
		return api(requestOptions).catch((err) => {
			dispatch(actionFailure(err.response.data.result));
		});
	};
};

/**
 * Set code for  phone number verification
 * @param {string} field - field name
 * @param {number} value - field value
 * @returns
 */
export const setVerifyCode = (field, value) => {
	return (dispatch) => {
		dispatch({
			type: userConstants.SET_VERIFY_CODE,
			data: { field, value },
		});
	};
};

/**
 * Set code for 2FA
 * @param {string} field - field name
 * @param {number} value - field value
 * @returns
 */
export const setAuthenticationCode = (field, value) => {
	return (dispatch) => {
		dispatch({
			type: userConstants.SET_AUTHENTICATION_CODE,
			data: { field, value },
		});
	};
};

/**
 * Clear code for 2FA
 * @returns
 */
export const resetAuthenticationCode = () => {
	return (dispatch) => {
		dispatch({
			type: userConstants.RESET_AUTHENTICATION_CODE,
		});
	};
};

/**
 * Clear code for phone number verification
 * @returns
 */
export const resetVerifyCode = () => {
	return (dispatch) => {
		dispatch({
			type: userConstants.RESET_VERIFY_CODE,
		});
	};
};
