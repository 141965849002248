import React, { useCallback, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import {
	Field,
	reduxForm,
	change,
	formValueSelector,
} from "redux-form/immutable";
import localize, { LocKeys } from "../../../../constants/localizations";
import renderTextField from "../../../fields/renderTextField";
import {
	setStateTempFieldValue,
	removeBuilding,
	getBuildingCategories,
	getInputFormats,
} from "../../../../actions";
import {
	adornmentTypeConstants,
	generalConstants,
	sizeConstants,
} from "../../../../constants/constants";
import IconWrapper from "../../../../shared/icons/IconWrapper";
import TrashIcon from "../../../../shared/icons/actions/TrashIcon";
import CustomSelect from "../../../fields/CustomSelect";
import CustomLabel from "../../../fields/CustomLabel";
import CustomAutocomplete from "../../../fields/CustomAutocomplete";
import {
	getBuildingTypeIcon,
	getInputFormatIcon,
} from "../../../../utils/getTypeIcon";
import { renderPrice } from "../../single-view/view/SpecificationView";
import ExternalLinkIcon from "../../../../shared/icons/misc/ExternalLinkIcon";
import { Link } from "react-router-dom";
import QuestionIcon from "../../../../shared/icons/misc/QuestionIcon";
import { renderNumberModeling } from "../../../administration/pricing/modeling/helper";
import renderError from "../../../fields/renderError";
import { isBimify } from "../../../../utils/permission/user";
import ComponentTooltip from "../../../../shared/components/component-tooltip/ComponentTooltip";
import { getNewValueFromDiffPercentage } from "../../../../utils/math-helper/MathHelper";

export const customRenderLodSpecification = (props, option) => {
	return (
		<li {...props} key={option.value}>
			<div className="d-flex align-items-center justify-content-between w-100">
				<div className="text-overflow-ellipsis">{option.label}</div>
				<Link target="_blank" to={`/bim-model-specification/${option.value}`}>
					<IconWrapper
						icon={
							<ExternalLinkIcon iconClass="d-flex icon--target-fill fill--n300" />
						}
					/>
				</Link>
			</div>
		</li>
	);
};
/**
 * Render Buildings list information FORM for create and edit offer (TYPE, INPUT FORMAT, SIZE, LOD SPECIFICATION, PRICE)
 * @param {*} props
 * @returns
 */
const BuildingInformation = (props) => {
	const {
		isNaviate = false,
		priceLoader,
		totalPrice,
		formType = generalConstants.VIEW,
		tempBuildings,
		numberOfBuildings,
		inputFormats,
		initialValues,
		dispatch,
		formName,
		buildingModelingPriceTotal,
		pricePerBuilding,
		//Actions
		setStateTempFieldValue,
		removeBuilding,
		getBuildingCategories,
		getInputFormats,
	} = props;
	const isEdit = formType === generalConstants.EDIT && isBimify();

	useEffect(() => {
		getBuildingCategories();
		getInputFormats();
	}, [getBuildingCategories, getInputFormats]);

	const setValue = useCallback(
		(fieldKey, value, buildingId = null) => {
			setStateTempFieldValue(
				fieldKey,
				value,
				buildingId,
				formType === generalConstants.EDIT
			);
		},
		[setStateTempFieldValue, formType]
	);

	const setComplexityAdjustment = useCallback(
		(value, id, size, pricePerSquare) => {
			const adjustedPrice = getNewValueFromDiffPercentage(
				+pricePerSquare,
				+value
			)?.toFixed(3);
			setValue(`customComplexityAdjustment`, value, id);
			setValue(`customAdjustedPricePerSquare`, adjustedPrice, id);
			setValue(`price`, +(+adjustedPrice * +size).toFixed(2), id);
		},
		[setValue]
	);

	let buildingTypeOptions = [];

	if (props.buildingTypes && props.buildingTypes.size > 0) {
		props.buildingTypes.map((buildingType, i) => {
			let subHeader = (
				<div
					className={`dropdown-subheader body-font--small  semi  ${+i === 0 ? "" : "dropdown-subheader--border"}`}
				>
					<div className="d-flex align-items-center w-100">
						<IconWrapper
							size="24"
							wrapperClasses="mr-s mt-neg-xs"
							icon={getBuildingTypeIcon(buildingType.get("type"))}
						/>

						<div className="text-overflow-ellipsis">
							{buildingType.get("type")}
						</div>
					</div>
				</div>
			);

			const children = buildingType?.get("children");
			children &&
				children.map((item) => {
					return buildingTypeOptions.push({
						subHeader,
						label: item.get("type"),
						value: item.get("id"),
					});
				});

			return buildingTypeOptions;
		});
	}

	let inputFormatOptions = [];
	if (inputFormats && inputFormats.size > 0) {
		inputFormats.map((inputFormat) => {
			return inputFormatOptions.push({
				value: inputFormat.get("id"),
				label: (
					<div className="d-flex align-items-center w-100">
						<IconWrapper
							size="24"
							wrapperClasses="mr-s"
							icon={getInputFormatIcon(inputFormat.get("type"))}
						/>

						<div className="text-overflow-ellipsis">
							{inputFormat.get("type")}
						</div>
					</div>
				),
			});
		});
	}

	const removeBuildingRow = (id) => {
		dispatch(change(formName, "numberOfBuildings", numberOfBuildings - 1));
		dispatch(change(formName, `size_${id}`, null));
		dispatch(change(formName, `name_${id}`, null));
		dispatch(change(formName, `pricePerSquare_${id}`, null));
		dispatch(change(formName, `price_${id}`, null));
		dispatch(change(formName, `levels_${id}`, null));
		dispatch(change(formName, `uniqueLevels_${id}`, null));
		removeBuilding(id);
	};

	const renderTotalPrice = useMemo(() => {
		return isEdit ? totalPrice : buildingModelingPriceTotal;
	}, [totalPrice, buildingModelingPriceTotal, isEdit]);

	const renderTotalSize = useMemo(() => {
		let totalSize = 0;
		tempBuildings &&
			tempBuildings.forEach((building) => {
				totalSize += +building.size;
			});

		return totalSize;
	}, [tempBuildings]);

	return (
		<>
			<div className="border-b-1 border-n150">
				<div className="row row--6p-gutters align-items-center pt-m pb-s">
					<div className={`col ${isEdit ? "col-16" : "col-18"}`}>
						<h5 className={`small ${!isNaviate ? "ml-36" : ""}`}>
							{localize(LocKeys.BUILDING_NAME)}
						</h5>
					</div>
					<div className={`col ${isEdit ? "col-12" : "col-16"}`}>
						<CustomLabel
							label={
								<h5 className="small">{localize(LocKeys.BUILDING_TYPE)}</h5>
							}
							required={true}
						/>
					</div>
					<div className={`col ${isEdit ? "col-10" : "col-14"}`}>
						<CustomLabel
							label={
								<h5 className="small">{localize(LocKeys.INPUT_FORMAT)}</h5>
							}
							required={true}
						/>
					</div>
					<div className={`col ${isEdit ? "col-10" : "col-12"}`}>
						<CustomLabel
							label={
								<h5 className="small">{localize(LocKeys.BUILDING_SIZE)}</h5>
							}
							required={true}
						/>
					</div>
					<div className={`col ${isEdit ? "col-8" : "col-10"}`}>
						<CustomLabel
							label={<h5 className="small">{localize(LocKeys.LEVELS)}</h5>}
						/>
					</div>
					<div
						className={`col ${isEdit ? "col-8" : "col-10"} align-items-center`}
					>
						<ComponentTooltip
							content={localize(LocKeys.UNIQUE_LEVELS_EXPLANATION)}
							direction={"top"}
						>
							<div className="d-flex align-items-start">
								<h5 className={`text-overflow-ellipsis small mr-xs`}>
									{localize(LocKeys.UNIQUE_LEVELS)}
								</h5>

								<IconWrapper
									size={sizeConstants.M}
									icon={
										<QuestionIcon iconClass="d-flex icon--target-fill fill--n300" />
									}
								/>
							</div>
						</ComponentTooltip>
					</div>

					<div
						className={`col ${isEdit ? "col-9" : "col-10"} justify-content-end`}
					>
						<h5 className="small">
							{localize(LocKeys.PRICE)} / m<sup>2</sup>
						</h5>
					</div>
					{isEdit && (
						<>
							<div className="col col-9 justify-content-end">
								<ComponentTooltip
									content={localize(LocKeys.COMPLEXITY_ADJUSTMENT)}
									direction={"top"}
								>
									<h5 className={`text-overflow-ellipsis small`}>
										{localize(LocKeys.COMPLEXITY_ADJUSTMENT)}
									</h5>
								</ComponentTooltip>
							</div>
							<div className="col col-9 justify-content-end">
								<ComponentTooltip
									content={localize(LocKeys.ADJUSTED_PRICE)}
									direction={"top"}
								>
									<h5 className={`text-overflow-ellipsis small`}>
										{localize(LocKeys.ADJUSTED_PRICE)} / m<sup>2</sup>
									</h5>
								</ComponentTooltip>
							</div>
						</>
					)}
					<div
						className={`col ${isEdit ? "col-9" : "col-10"} justify-content-end`}
					>
						<h5 className="small">{localize(LocKeys.TOTAL_PRICE)}</h5>
					</div>
				</div>
			</div>

			{tempBuildings &&
				tempBuildings.map((building, i) => {
					const isDisabled = tempBuildings.length > 1;

					return (
						<div key={i} className="border-b-1 border-n150 py-m">
							<div className="row row--6p-gutters align-items-center">
								<div
									className={`col ${isEdit ? "col-16" : "col-18"} align-items-center`}
								>
									{!isNaviate && (
										<button
											disabled={!isDisabled}
											onClick={() =>
												isDisabled && removeBuildingRow(building.id)
											}
											type="button"
											className="btn btn--icon btn--icon--solo btn--icon--after btn--stripped"
										>
											<IconWrapper
												size="24"
												icon={
													<TrashIcon iconClass="d-flex icon--target-fill fill--n300" />
												}
											/>
										</button>
									)}

									<Field
										name={`name_${building.id}`}
										id={`name_${building.id}`}
										placeholder={localize(LocKeys.ENTER_BUILDING_NAME) + "..."}
										required={false}
										onKeyDown={(value) => {
											setValue(`name`, value, building.id);

											if (value === `Building ${building.id}`) {
												setValue(`isCustomName`, false, building.id);
											} else {
												setValue(`isCustomName`, true, building.id);
											}
										}}
										component={renderTextField}
										min="0"
										type="text"
										showPlaceholder={true}
									/>
								</div>
								<div className={`col ${isEdit ? "col-12" : "col-16"}`}>
									<Field
										name={`type_${building.id}`}
										id={`type_${building.id}`}
										buildingList={true}
										dropdownHeight="300px"
										disableClearable={true}
										component={CustomAutocomplete}
										groupBy={true}
										showPlaceholder={true}
										placeholder={localize(LocKeys.SELECT_TYPE) + "..."}
										initialValue={
											initialValues?.get(`type_${building.id}`) || null
										}
										options={buildingTypeOptions}
										customOnChange={(value) =>
											setValue(`type`, value, building.id)
										}
									/>
								</div>
								<div className={`col ${isEdit ? "col-10" : "col-14"}`}>
									<Field
										name={`input_format_${building.id}`}
										component={CustomSelect}
										placeholder={localize(LocKeys.SELECT_FORMAT) + "..."}
										buildingList={true}
										showPlaceholder={true}
										options={inputFormatOptions}
										initialValue={
											initialValues?.get(`input_format_${building.id}`) || ""
										}
										customOnChange={(value) =>
											setValue(`inputFormat`, value, building.id)
										}
										required={true}
									/>
								</div>
								<div className={`col ${isEdit ? "col-10" : "col-12"}`}>
									<Field
										name={`size_${building.id}`}
										id={`size_${building.id}`}
										component={renderTextField}
										type="number"
										adornmentType={adornmentTypeConstants.METERS}
										min="0"
										placeholder={localize(LocKeys.BUILDING_SIZE) + "..."}
										required={true}
										//debounce={true}
										onKeyDown={(value) => setValue(`size`, value, building.id)}
										onlyInteger={true}
									/>
								</div>
								<div className={`col ${isEdit ? "col-8" : "col-10"}`}>
									<Field
										name={`levels_${building.id}`}
										id={`levels_${building.id}`}
										component={renderTextField}
										//	debounce={true}
										type="number"
										min="0"
										placeholder={localize(LocKeys.LEVELS) + "..."}
										required={false}
										onKeyDown={(value) =>
											setValue(`levels`, value, building.id)
										}
										customError={!building?.validity?.levelsValidity}
									/>
								</div>
								<div className={`col ${isEdit ? "col-8" : "col-10"}`}>
									<Field
										name={`uniqueLevels_${building.id}`}
										id={`uniqueLevels_${building.id}`}
										component={renderTextField}
										//debounce={true}
										type="number"
										min="0"
										placeholder={localize(LocKeys.UNIQUE_LEVELS) + "..."}
										required={true}
										onKeyDown={(value) =>
											setValue(`uniqueLevels`, value, building.id)
										}
										customError={!building?.validity?.levelsValidity}
									/>
								</div>
								<div
									className={`col ${isEdit ? "col-9" : "col-10"} justify-content-end`}
								>
									{priceLoader.loader &&
									(priceLoader.buildingId === building.id ||
										!priceLoader.buildingId) ? (
										<span className="loading-icon"></span>
									) : isEdit ? (
										<Field
											name={`pricePerSquare_${building.id}`}
											id={`pricePerSquare_${building.id}`}
											component={renderTextField}
											type="number"
											//debounce={true}
											adornmentType={adornmentTypeConstants.EURO_METERS}
											onKeyDown={(value = 0) => {
												setValue(`customPricePerSquare`, value, building.id);

												const adjustedPrice = getNewValueFromDiffPercentage(
													value || 0,
													building.customComplexityAdjustment
												)?.toFixed(2);

												setValue(
													`customAdjustedPricePerSquare`,
													adjustedPrice,
													building.id
												);
												setValue(
													`price`,
													+(+adjustedPrice * +building.size).toFixed(2),
													building.id
												);
											}}
											min="0"
											placeholder={localize(LocKeys.EMPTY_PLACHOLDER)}
											required={true}
										/>
									) : (
										<div>
											{/* {renderPrice(
												building.customAdjustedPricePerSquare ||
													building.customPricePerSquare,
												"color-n300",
												true
											)} */}
											{renderPrice(
												pricePerBuilding
													?.get(String(building.id))
													?.get("pricePerSquareAdjusted") ||
													pricePerBuilding
														?.get(String(building.id))
														?.get("pricePerSquare"),
												"color-n300",
												true
											)}
										</div>
									)}
								</div>
								{isEdit && (
									<>
										<div className="col col-9 justify-content-end">
											{priceLoader.loader &&
											(priceLoader.buildingId === building.id ||
												!priceLoader.buildingId) ? (
												<span className="loading-icon"></span>
											) : (
												<Field
													name={`complexityAdjustment_${building.id}`}
													id={`complexityAdjustment_${building.id}`}
													component={renderTextField}
													type="number"
													//debounce={true}
													adornmentType={adornmentTypeConstants.PERCENTAGE}
													onKeyDown={(value) =>
														setComplexityAdjustment(
															value,
															building.id,
															building.size,
															building.customPricePerSquare
														)
													}
													min="0"
													placeholder={localize(LocKeys.EMPTY_PLACHOLDER)}
													required={true}
												/>
											)}
										</div>
										<div className="col col-9 justify-content-end">
											{priceLoader.loader &&
											(priceLoader.buildingId === building.id ||
												!priceLoader.buildingId) ? (
												<span className="loading-icon"></span>
											) : (
												<Field
													name={`adjustedPrice_${building.id}`}
													id={`adjustedPrice_${building.id}`}
													component={renderTextField}
													type="number"
													//debounce={true}
													adornmentType={adornmentTypeConstants.EURO_METERS}
													onKeyDown={(value) => {
														setValue(
															`customAdjustedPricePerSquare`,
															value,
															building.id
														);
														setValue(
															`price`,
															+(+value * +building.size).toFixed(2),
															building.id
														);
													}}
													min="0"
													placeholder={localize(LocKeys.EMPTY_PLACHOLDER)}
													required={true}
												/>
											)}
										</div>
									</>
								)}
								<div
									className={`col ${isEdit ? "col-9" : "col-10"} justify-content-end`}
								>
									{priceLoader.loader &&
									(priceLoader.buildingId === building.id ||
										!priceLoader.buildingId) ? (
										<span className="loading-icon"></span>
									) : isEdit ? (
										<Field
											name={`price_${building.id}`}
											id={`price_${building.id}`}
											component={renderTextField}
											type="number"
											disabled={true}
											adornmentType={adornmentTypeConstants.EURO}
											onKeyDown={(value) => {
												setValue(`price`, value, building.id);
											}}
											min="0"
											placeholder={localize(LocKeys.EMPTY_PLACHOLDER)}
											required={true}
										/>
									) : (
										<div>
											{renderPrice(
												pricePerBuilding
													?.get(String(building.id))
													?.get("buildingPrice"),
												"color-n300"
											)}
										</div>
									)}
								</div>
							</div>
							{!building?.validity?.levelsValidity && (
								<div className="row row--6p-gutters">
									<div className={`col ${isEdit ? "col-48" : "col-60"}`}></div>
									<div className={`col ${isEdit ? "col-22" : "col-20"}`}>
										{renderError(
											false,
											false,
											true,
											localize(LocKeys.CUSTOM_LEVELS_ERROR)
										)}
									</div>
									<div className={`col  ${isEdit ? "col-30" : "col-20"}`}></div>
								</div>
							)}
						</div>
					);
				})}

			{(!isNaviate || isEdit) && (
				<div className="row row--6p-gutters align-items-start py-m">
					<div className={`col ${isEdit ? "col-18" : "col-20"}  color-n500`}>
						<h4>{localize(LocKeys.TOTAL)}</h4>
					</div>

					<div
						className={`col ${isEdit ? "col-30" : "col-40"} justify-content-end`}
					>
						<h4>
							{renderNumberModeling({
								value: renderTotalSize,
								toFixed: 0,
								type: adornmentTypeConstants.SQUARE_METERS,
								typeClassName: "color-n500",
							})}
						</h4>
					</div>

					{isEdit ? (
						<>
							<div className={`col col-7`}></div>
							<div
								className={`col col-18 justify-content-end align-items-center`}
							>
								<div className="color-n300 body-font--xs pr-6">
									{localize(LocKeys.PRICE_ADJUSTMENT_LABEL)}
								</div>
								<Field
									name={`pricePerSquarePercentage`}
									id={`pricePerSquarePercentage`}
									component={renderTextField}
									type="number"
									//debounce={true}
									classField="pl-6"
									adornmentType={adornmentTypeConstants.PERCENTAGE}
									onKeyDown={(value) => {
										setValue(`pricePerSquarePercentage`, value);
									}}
									size={"w-50"}
									min="0"
									placeholder={localize(LocKeys.EMPTY_PLACHOLDER)}
									required={true}
								/>
								{/* <Field
										name={`pricePerSquarePercentage`}
										id={`pricePerSquarePercentage`}
										component={renderTextField}
										disabled={priceLoader.loader}
										type="number"
										//	debounce={true}
										adornmentType={adornmentTypeConstants.PERCENTAGE}
										onKeyDown={(value) => {
											setValue(`pricePerSquarePercentage`, value);
										}}
										min="0"
										placeholder={localize(LocKeys.EMPTY_PLACHOLDER)}
										required={true}
									/> */}
							</div>
						</>
					) : (
						""
					)}

					<div
						className={`col ${isEdit ? "col-27" : "col-40"}  justify-content-end`}
					>
						{priceLoader.loader ? (
							<span className="loading-icon"></span>
						) : (
							<h4>{renderPrice(renderTotalPrice, "color-n500")}</h4>
						)}
					</div>
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state, ownProps) => {
	const { isNaviate = false } = ownProps;
	const selector = formValueSelector(ownProps.formName);

	return {
		form: ownProps.formName,
		inputFormats: state.getIn(["administration", "inputFormat"]),
		numberOfBuildings: selector(state, "numberOfBuildings") || 1,
		enableReinitialize: isNaviate ? false : true,
		tempBuildings: state.getIn(["projectTemp", "buildings"]),
		projectTemp: state.getIn(["projectTemp"]),
		buildingTypes: state.getIn(["administration", "types"]),
		buildingModelingPriceTotal: state.getIn([
			"price",
			"buildingPrice",
			"total",
			"modelingPrice",
		]),
		pricePerBuilding: state.getIn([
			"price",
			"buildingPrice",
			"pricePerBuildingDetailed",
		]),
	};
};

export default connect(mapStateToProps, {
	setStateTempFieldValue,
	removeBuilding,
	getBuildingCategories,
	getInputFormats,
})(
	reduxForm({
		touchOnBlur: false,
		destroyOnUnmount: false,
	})(BuildingInformation)
);
