/**
 * Function for validating fields from project form
 */

import localize, { LocKeys } from "../../../../constants/localizations";

export default (formValues) => {
	const errors = {};

	if (!formValues.get("name")) {
		errors.name = localize(LocKeys.FIELD_REQUIRED, [localize(LocKeys.NAME)]);
	}
	if (!formValues.get("type")) {
		errors.type = localize(LocKeys.FIELD_REQUIRED, [localize(LocKeys.TYPE)]);
	}

	if (!formValues.get("size")) {
		errors.size = localize(LocKeys.FIELD_REQUIRED, [
			localize(LocKeys.BUILDING_SIZE),
		]);
	}
	if (!formValues.get("customer")) {
		errors.customer = localize(LocKeys.FIELD_REQUIRED, [
			localize(LocKeys.CLIENT_COMPANY),
		]);
	}

	// if (!formValues.get("buildingLevelTo")) {
	// 	errors.buildingLevelTo = localize(LocKeys.FIELD_REQUIRED, ["Level to"]);
	// }
	// if (!formValues.get("buildingLevelDefaultHeight")) {
	// 	errors.buildingLevelDefaultHeight = localize(LocKeys.FIELD_REQUIRED, [
	// 		"Default Height",
	// 	]);
	// }

	return errors;
};
