import React, { useCallback, useEffect, useMemo, useState } from "react";
import localize, { LocKeys } from "../../../constants/localizations";
import { Field, change, reduxForm } from "redux-form/immutable";
import renderTextField from "../../fields/renderTextField";
import {
	setAuthenticationCode,
	resetAuthenticationCode,
	resetForm,
	resend2FACode,
	check2FACode,
} from "../../../actions";
import { connect } from "react-redux";
import ArrowLeft from "../../../shared/icons/ArrowLeft";
import renderError from "../../fields/renderError";
import TimeCounter from "../parts/TimeCounter";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import { useNavigate } from "react-router-dom-v5-compat";

const AuthenticationCode = (props) => {
	const {
		dispatch,
		//state
		numberOfDigits,
		digitValues,
		email,
		//actions
		check2FACode,
		setAuthenticationCode,
		resend2FACode,
		resetAuthenticationCode,
		resetForm,
		handleClose,
	} = props;
	const navigate = useNavigate();
	let numericArray = useMemo(() => [1, 2, 3, 4, 5, 6], []);

	const [disableButton, setDisableButton] = useState(true);
	const [remainingDigits, setRemainingDigits] = useState(numericArray.length);
	const [resetTimer, setResetTimer] = useState(false);
	const [[isValid, message], setIsValid] = useState([true, null]);
	let digitsLength = numericArray.length;

	//Auto focus on the next input field
	const setValue = useCallback(
		(field, value, item) => {
			let nextField = item;

			if (value !== "") {
				nextField = ++item;
			} else {
				nextField = --item;
			}

			if (!isValid) setIsValid([true, null]);

			const nextSibling = document.getElementById(`number_${nextField}`);

			if (nextSibling !== null) {
				nextSibling.focus();
			}

			setAuthenticationCode(field, value);
		},
		[isValid, setAuthenticationCode]
	);

	//Handle paste event
	useEffect(() => {
		const handlePasteAnywhere = (event) => {
			const pastedValues = event.clipboardData
				.getData("text")
				.toString()
				.split("");

			numericArray.forEach((item, index) => {
				if (pastedValues[index]) {
					setValue(`number_${item}`, pastedValues[index], item);
					dispatch(
						change(
							"authenticationCodeForm",
							`number_${item}`,
							pastedValues[index]
						)
					);
				}
			});

			if (pastedValues.length !== numericArray.length) {
				setValue(
					`number_${pastedValues.length + 1}`,
					"",
					pastedValues.length + 1
				);
				dispatch(
					change(
						"authenticationCodeForm",
						`number_${pastedValues.length + 1}`,
						""
					)
				);
			}
		};

		window.addEventListener("paste", handlePasteAnywhere);

		return () => {
			window.removeEventListener("paste", handlePasteAnywhere);
		};
	}, [numericArray, setValue, dispatch]);

	useEffect(() => {
		//Set Verify button property

		if (Number(digitsLength) > Number(numberOfDigits)) {
			setDisableButton(true);
			setRemainingDigits(Number(digitsLength) - Number(numberOfDigits));
		} else {
			dispatch(props.handleSubmit(onSubmit));
			setDisableButton(false);
		}
		//Focus on the first input field
		if (!numberOfDigits) {
			document.getElementById("number_1").focus();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [numberOfDigits, digitsLength]);

	const onSubmit = () => {
		const data = digitValues;
		const codeValue = [];

		try {
			numericArray.forEach((item) => {
				codeValue.push(data[`number_${item}`]);
				delete data[`number_${item}`];
			});

			const code = codeValue.join("").toString();

			if (code.length < 6) {
				return;
			}
			const requestData = {
				code,
				email,
			};

			check2FACode(
				requestData,
				(url) => {
					navigate(url);
					handleClose();
				},
				(message) => {
					setIsValid([false, message]);
					setDisableButton(true);
					setRemainingDigits(0);
				}
			);
		} catch (error) {
			console.log(error);
			return;
		}
	};

	/**
	 * Resend authentication code
	 * ON SUCCESS: Reset form, timer and Verify button property
	 */
	const resendCodeFunc = () => {
		const data = {
			email: email,
		};

		resend2FACode(data, () => {
			dispatch(resetForm("authenticationCodeForm"));
			setDisableButton(true);
			setResetTimer(true);
			setRemainingDigits(numericArray.length);
			resetAuthenticationCode();
		});
	};

	/**
	 * Back to login form
	 * Reset form and timer
	 */
	const goBack = () => {
		dispatch(resetForm("authenticationCodeForm"));
		setRemainingDigits(numericArray.length);
		resetAuthenticationCode();
		handleClose();
	};

	const renderNumberFields = () => {
		return numericArray.map((item) => {
			return (
				<div key={item} className="col text-center align-items-center">
					<Field
						name={`number_${item}`}
						id={`number_${item}`}
						textAlign={true}
						onKeyUp={(value) => setValue(`number_${item}`, value, item)}
						component={renderTextField}
						type="text"
						min={0}
						max={9}
						maxLength={1}
					/>
				</div>
			);
		});
	};

	return (
		<form
			className={
				"form d-flex  flex-column  pb-32 pt-40  flex-auto standardized-form"
			}
			onSubmit={props.handleSubmit(onSubmit)}
			noValidate
			autoComplete="off"
		>
			<div className="px-36 px-desktop-36 w-100">
				<div
					className="return-link d-flex flex-wrap align-items-center"
					onClick={() => goBack()}
				>
					<ArrowLeft iconClass="mr-10 d-flex" />
					<h6 className="text--uppercase  ">{localize(LocKeys.BACK)}</h6>
				</div>
				<div className="text-center align-items-center  mb-32 d-flex flex-column w-100">
					<DraftsOutlinedIcon style={{ fontSize: 120 }} />

					<div className="body-font--small">
						{localize(LocKeys.VERIFICATION_MESSAGE)}
						<br />
						<strong className="semi">{email}</strong>
						<br />
						{localize(LocKeys.TWOFA_CODE_MESSAGE)}
					</div>
				</div>

				<div className="d-flex  row row--m-gutters row--no-gutters  w-100 mt-24">
					{renderNumberFields()}
				</div>
				<div className="ml-s">{renderError(null, null, !isValid, message)}</div>

				<div className="d-flex  row row--m-gutters row--no-gutters w-100 mt-regular flex-column">
					<div className="col">
						{disableButton && remainingDigits > 0 ? (
							<button disabled={true} className="btn btn--primary w-100">
								{`${remainingDigits} digits remaining`}
							</button>
						) : (
							<button
								type="submit"
								disabled={disableButton}
								className="btn btn--success w-100"
							>
								{localize(LocKeys.VERIFY)}
							</button>
						)}
					</div>
					<div className="col">
						<button
							type="button"
							onClick={() => resendCodeFunc()}
							className="btn  mt-m border-none w-100 btn--tertiary"
						>
							{localize(LocKeys.RESEND_CODE)}
						</button>
					</div>
				</div>

				<TimeCounter
					goBack={goBack}
					resetTimer={resetTimer}
					setResetTimer={setResetTimer}
				/>
			</div>
		</form>
	);
};

const mapStateToProps = (state) => {
	return {
		email: state.getIn(["user", "tempUserData", "email"]),
		numberOfDigits: state.getIn(["user", "authenticationCode", "size"])
			? state.getIn(["user", "authenticationCode", "size"])
			: 0,

		digitValues: state.getIn(["user", "authenticationCode", "values"]),
	};
};

export default connect(mapStateToProps, {
	setAuthenticationCode,
	resend2FACode,
	resetAuthenticationCode,
	resetForm,
	check2FACode,
})(
	reduxForm({
		form: "authenticationCodeForm",
		destroyOnUnmount: false,
		touchOnBlur: false,
	})(AuthenticationCode)
);
