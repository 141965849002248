import { List, Map, fromJS } from "immutable";
import {
	responseContentTypeConstants,
	responseDeliveryTypeConstants,
} from "../constants/constants";
import localize, { LocKeys } from "../constants/localizations";
import userConstants from "../constants/userConstants";
/**
 * User reducer redux
 */

const initialState = Map({
	action: false,
	request: false,
	errorMessage: localize(LocKeys.SUCCESS),
	successMessage: localize(LocKeys.SOMETHING_WENT_WRONG),
	responseType: responseDeliveryTypeConstants.SNACKBAR,
	responseContentType: responseContentTypeConstants.DEFAULT,

	user: Map(),
	companies: Map(),
	usersList: Map(),
	resellerUsersList: Map(),
	usersMeta: Map(),
	getFailure: "",
	resendActivation: fromJS({
		sent: false,
		message: "",
	}),
	verifyCode: Map(),
	authenticationCode: Map(),
	tempUserData: Map(),
	usersByLoggedUser: List(),
	resellerUsersByCompany: List(),
});

export default (state = initialState, action) => {
	const setVerifyCode = (data) => {
		const { field, value } = data;

		let newData = [];
		const oldData = state.getIn(["verifyCode", "digits"])
			? state.getIn(["verifyCode", "digits"])
			: [];

		oldData.map((dr) => {
			return newData.push(dr);
		});

		if (value.trim() !== "") {
			if (newData.length < 1) {
				newData.push(field);
			} else {
				if (!newData.includes(field)) {
					newData.push(field);
				}
			}
		} else {
			newData = newData.filter((item) => {
				return item !== field;
			});
		}

		return state
			.setIn(["verifyCode", "size"], newData.length)
			.setIn(["verifyCode", "digits"], fromJS(newData));
	};

	const set2FAuthenticationCode = (data) => {
		const { field, value } = data;

		let newData = [];
		const oldData = state.getIn(["authenticationCode", "digits"])
			? state.getIn(["authenticationCode", "digits"])
			: [];
		const oldValues = state.getIn(["authenticationCode", "values"])
			? state.getIn(["authenticationCode", "values"])
			: {};

		let newValues = { ...oldValues };
		newValues[field] = value;

		oldData.map((dr) => {
			return newData.push(dr);
		});

		if (value.trim() !== "") {
			if (newData.length < 1) {
				newData.push(field);
			} else {
				if (!newData.includes(field)) {
					newData.push(field);
				}
			}
		} else {
			newData = newData.filter((item) => {
				return item !== field;
			});
		}

		return state
			.setIn(["authenticationCode", "size"], newData.length)
			.setIn(["authenticationCode", "digits"], fromJS(newData))
			.setIn(["authenticationCode", "values"], newValues);
	};
	switch (action.type) {
		case userConstants.USER_ACTION_GET_FAILURE:
			return state
				.set("action", false)
				.set("request", true)
				.set("errorMessage", fromJS(action.data));

		case userConstants.USER_ACTION_GET_SUCCESS:
			return state
				.set("action", true)
				.set("request", true)
				.set(
					"responseType",
					fromJS(
						action.data?.responseType || responseDeliveryTypeConstants.SNACKBAR
					)
				)
				.set(
					"responseContentType",
					fromJS(
						action.data?.contentType || responseContentTypeConstants.DEFAULT
					)
				)
				.set("successMessage", fromJS(action.data?.message || action.data));

		case userConstants.USER_CLEAR_REQUEST_STATE:
			return state.set("action", false).set("request", false);

		case userConstants.GET_USERS:
			return state
				.set("usersList", fromJS(action.data.result))
				.set("usersMeta", fromJS(action.data.meta));

		case userConstants.GET_RESELLER_USERS:
			return state.set("resellerUsersList", fromJS(action.data));

		case userConstants.GET_RESELLER_USERS_BY_COMPANY:
			return state.set("resellerUsersByCompany", fromJS(action.data));

		case userConstants.GET_USER:
			return state.set("user", fromJS(action.data));

		case userConstants.U_REQUEST_DONE:
			return state.set("request", false);

		case userConstants.GET_USER_COMPANIES:
			return state.set("companies", fromJS(action.data));

		case userConstants.GET_USERS_BY_LOGGED_USER:
			return state.set("usersByLoggedUser", fromJS(action.data));

		case userConstants.ACTION_ACTIVATION:
			return state.set(
				"resendActivation",
				fromJS({ sent: action.data.sent, message: action.data.message })
			);

		case userConstants.CLEAR_CURRENT_USER:
			return state.set("user", Map()).set("tempUserData", Map());

		case userConstants.SET_USER_IMAGE:
			return state.setIn(["tempUserData", "image"], action.data);

		case userConstants.SET_TEMP_USER:
			return state.set("tempUserData", action.data);

		case userConstants.SET_VERIFY_CODE:
			return setVerifyCode(action.data);

		case userConstants.RESET_VERIFY_CODE:
			return state.set("verifyCode", Map());

		case userConstants.SET_AUTHENTICATION_CODE:
			return set2FAuthenticationCode(action.data);

		case userConstants.RESET_AUTHENTICATION_CODE:
			return state.set("authenticationCode", Map());

		case userConstants.SET_USER:
			return state.set("user", fromJS(action.data));

		case userConstants.GET_USER_ROLES:
			return state.set("userRoles", fromJS(action.data));

		default:
			return state;
	}
};
