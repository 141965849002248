import React, { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
	Field,
	reduxForm,
	formValueSelector,
	change,
} from "redux-form/immutable";
import { integrationLogin, login } from "../../actions";
import validate from "../fields/validateAuth";
import renderTextField from "../fields/renderTextField";
import renderPasswordField from "../fields/renderPasswordField";
import localize, { LocKeys } from "../../constants/localizations";
import { Link } from "react-router-dom";
import Container from "./parts/Container";
import ArrowLeft from "../../shared/icons/ArrowLeft";
import Modal from "../Modal";
import AuthenticationCode from "./2fa/AuthenticationCode";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";
import SetPassword from "./SetPassword";
import { useCallback } from "react";

/**
 * Render LOGIN component (email, password)
 * @param {*} props
 * @returns
 */
const Login = (props) => {
	const { login, integrationLogin } = props;
	const navigate = useNavigate();
	const location = useLocation();
	const [authenticationCodeModal, setAuthenticationCodeModal] = useState(false);
	const [data, setData] = useState(null);
	const [loginValue, setLoginValue] = useState(false);

	const dispatch = useDispatch();

	// Naviate login action
	const naviateLogin = useCallback(
		(data, cb) => {
			return integrationLogin(
				data,
				(url) => {
					navigate(url);
				},
				cb
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[integrationLogin]
	);

	const isNaviate = useMemo(
		() => location.pathname === "/naviate-login",
		[location.pathname]
	);

	useEffect(() => {
		// Ensure WebView2 is available
		if (window.chrome?.webview && isNaviate) {
			//get data from C# app and set to local state
			const handleMessage = (event) => {
				console.log("Message from C#:", event.data);
				if (event.data.Data === "BimifyInformation") {
					console.log("Message from C#:", event.data?.Payload);

					const userData = JSON.parse(event.data?.Payload);

					console.log({ userData });
					dispatch(change("loginForm", `email`, userData.email));

					setData(userData);
				}
			};
			// Attach the event listener
			window.chrome.webview.addEventListener("message", handleMessage);
			// Cleanup: Remove the event listener when component unmounts
			return () => {
				window.chrome.webview.removeEventListener("message", handleMessage);
			};
		}
	}, [dispatch, isNaviate]);

	const onSubmit = (formValues) => {
		if (formValues)
			try {
				let formValuesJS = formValues.toJS();

				if (isNaviate) {
					// let tempData = {
					// 	email: data?.email?.toLowerCase()
					// 	apiKey:data?.apiKey
					// };

					//This is temporary data for testing, you need to remove this. Replace with commented code above
					// and after that set readOnly to email field
					const tempData = {
						email:
							data?.email?.toLowerCase() || formValuesJS.email.toLowerCase(),
						apiKey: data?.apiKey || "test-naviate-login",
					};

					//Check if user already exists in our database
					//if yes, then we will login the user
					return naviateLogin(tempData, () => {
						//if user does not exist, then we will set the loginValue to true
						//after that user will be redirected to set password page
						setLoginValue(true);
					});
				} else {
					let data = {
						...formValuesJS,
						email: formValuesJS.email.toLowerCase(),
					};
					return login(
						data,
						() => setAuthenticationCodeModal(true),
						(url) => {
							navigate(url);
						},
						isNaviate
					);
				}
			} catch (error) {
				console.log({ error });
				return;
			}
	};

	if (loginValue && isNaviate) {
		return (
			<SetPassword
				pageTitle={localize(LocKeys.CHOOSE_PASSWORD)}
				pageSubTitle={localize(LocKeys.FILL_TO_LOGIN)}
				isNaviate={true}
				userData={data}
			/>
		);
	}

	return (
		<>
			<Modal show={authenticationCodeModal} hideClose={true}>
				<AuthenticationCode
					handleClose={() => setAuthenticationCodeModal(false)}
				/>
			</Modal>
			<Container
				isNaviate={isNaviate}
				pageTitle={localize(LocKeys.WELCOME_TO_BIMIFY)}
			>
				<form
					className={"form standardized-form"}
					onSubmit={props.handleSubmit(onSubmit)}
					noValidate
					autoComplete="off"
				>
					{!isNaviate && (
						<a
							className="btn--stripped return-link d-flex flex-wrap align-items-center"
							href="https://bimify.com"
						>
							<ArrowLeft iconClass="mr-10 d-flex" />
							<h6 className="text--uppercase">
								{localize(LocKeys.BACK_TO_HOME)}
							</h6>
						</a>
					)}
					<div className="form__input-wrapper mb-24">
						{/* IMPORTANT - allow readOnly for email field */}
						<Field
							name="email"
							id="email"
							//readOnly={isNaviate}
							component={renderTextField}
							required={true}
							label={localize(LocKeys.EMAIL)}
							placeholder={localize(LocKeys.EMAIL)}
						/>
					</div>
					{!isNaviate && (
						<div className="form__input-wrapper mb-40">
							<Field
								id="password"
								name="password"
								component={renderPasswordField}
								label={localize(LocKeys.PASSWORD)}
								placeholder={localize(LocKeys.PASSWORD)}
							/>
						</div>
					)}
					<button
						type="submit"
						className="btn btn--primary btn--bg-primary w-100"
					>
						{localize(LocKeys.LOG_IN)}
					</button>
					{!isNaviate && (
						<div className="d-flex align-items-center body-font--small">
							<Link
								to={{
									pathname: "/password-reset",
									state: { email: props.emailValue, isNaviate },
								}}
								className="mt-32"
							>
								{localize(LocKeys.FORGOT_PASSWORD)}
							</Link>

							{/* <Link className="mt-32 ml-auto" to={"/signup"}>
						{localize(LocKeys.SIGN_UP)}
					</Link> */}
						</div>
					)}
				</form>
			</Container>
		</>
	);
};

const selector = formValueSelector("loginForm"); // <-- same as form name

const mapStateToProps = (state) => {
	return {
		emailValue: selector(state, "email"),
		redirectPath: state.getIn(["authentication", "redirectToPath"]),
	};
};

export default connect(mapStateToProps, { login, integrationLogin })(
	reduxForm({
		form: "loginForm",
		validate,
		destroyOnUnmount: true,
		touchOnBlur: false,
	})(Login)
);
