import orderConstants from "../constants/orderConstants";
import { api } from "../api";
import history from "../history";
import {
	getItemFromLocalStorage,
	getToken,
	tokenParams,
} from "../utils/local-storage.util";
import localize, { LocKeys } from "../constants/localizations";
import orderTempConstants from "../constants/orderTempConstants";
import { statusConstants } from "../constants/constants";
import { clearTempProject } from "./orderTempActions";
import { change } from "redux-form";
import { refreshToken } from "./authActions";
import { clearPricing } from "./priceActions";
import { jobActionSuccess, setJobSuccessMessage } from "./jobActions";
import { getPagination, getQuery } from "../utils/table-filter";
import { clearAllBuildingState } from "./buildingActions";

/**
 * Retrieves offers based on the given parameters.
 *
 * SUCCESS:
 *    1. Triggers refresh token
 *    2. Populates offer.projectsList & offer.projectsMeta
 *
 * @param {number} page
 * @param {string} searchQuery
 * @param {object} searchFilters - Contains filter info
 * @param {number} customer
 * @param {number} limit
 * @returns http response with the list of offer
 */
export const getOrders = ({
	page = 1,
	searchQuery = "",
	searchFilters,
	customer = getItemFromLocalStorage("Customer"),
	resellerCompanyId = getItemFromLocalStorage("Reseller"),
	limit = 8,
	isForSelectField = false,
	cbSelectField = () => {},
}) => {
	return (dispatch, getState) => {
		const state = getState();

		const offerQueryParameter = state.getIn([
			"queryParameter",
			"offerQueryParameter",
		]);
		const tablePagination = state.getIn(["form", "tablePagination", "values"]);

		const { filters, search } = getQuery({
			queryParameter: offerQueryParameter,
			searchFilters,
			searchQuery,
		});
		const { currentPage, currentLimit } = getPagination({
			tablePagination,
			page,
			limit,
		});

		const data = {
			page: isForSelectField ? page : currentPage,
			search,
			limit: isForSelectField ? limit : currentLimit,
			filters,
			selectedCompany: customer,
			resellerCompanyId,
		};

		if (isForSelectField) {
			data.filters = null;
			data.search = searchQuery;
		}

		const requestOptions = {
			method: "PUT",
			url: `/order/get-all`,
			headers: {
				Authorization: "Bearer " + getToken(),
				"Content-Type": "application/json",
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				let data = res.data;
				if (!!isForSelectField) {
					cbSelectField(data?.result);
				} else {
					const user = tokenParams();
					data.user = user;

					dispatch({ type: orderConstants.GET_PROJECTS, data });
					dispatch(
						getOrderStats({ customer, filters, search, resellerCompanyId })
					);
					dispatch(refreshToken());
				}
			},
			(err) => {
				dispatch(orderActionGetFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Retrieves Offer stats
 *
 * SUCCESS: Populates 'stats' in the offer state.
 *
 * @returns array of offer stats
 */
export const getOrderStats = ({
	customer = getItemFromLocalStorage("Customer"),
	resellerCompanyId = getItemFromLocalStorage("Reseller"),
	filters,
	search,
}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: "/order-stats",
			headers: {
				Authorization: "Bearer " + getToken(),
				"Content-Type": "application/json",
			},
			data: {
				customer,
				filters,
				search,
				resellerCompanyId,
			},
		};
		return api(requestOptions).then(
			(res) => {
				let data = res.data;
				dispatch({ type: orderConstants.ORDER_STATS, data });
			},
			(err) => {
				dispatch(orderActionGetFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Method: Get single offer  - take single offer data based on the offerId
 *
 * @param {number} orderId - offer unique identifier
 * @param {boolean} prefillProjectTemp - when true projectTemp will be prefilled with response values
 * @returns
 */
export const getOrder = ({
	orderId,
	prefillProjectTemp = false,
	prefillCompanyTemp = false,
	updateLodSPecificationIds = false,
	cbSuccess = () => {},
	cbError = () => {},
}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/order/` + orderId,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: orderConstants.GET_PROJECT,
					data: { offer: res.data, updateLodSPecificationIds },
				});

				if (prefillProjectTemp) {
					const order = res.data?.result;
					const buildings = order?.buildings;
					dispatch(
						change("editOfferInfoForm", "numberOfBuildings", buildings?.length)
					);
					dispatch({
						type: orderTempConstants.SET_TEMP_PROJECT,
						data: order,
					});
				}

				if (prefillCompanyTemp) {
					dispatch({
						type: orderTempConstants.SET_TEMP_COMPANY_INFO,
						data: res.data?.result,
					});
				}
				cbSuccess();
			},
			(err) => {
				cbError();
				if (err.response.status === 403) {
					history.push("/offers");
				}
				dispatch(orderActionGetFailure(err.response.data.message));
			}
		);
	};
};

export const createOrderSuccess = (offer, price = null) => {
	return (dispatch) => {
		dispatch(
			projectActionSuccess(
				localize(LocKeys.ITEM_CREATED, [localize(LocKeys.OFFER)])
			)
		);
		dispatch(offerModalActionSuccess(+offer?.id, price?.totalOfferPrice));
		clearTempProject({});
		dispatch(refreshToken());
		dispatch(clearPricing());
	};
};

/**
 * CREATE offer
 * Function used for offer creation
 *
 * SUCCESS:
 * 		1. refreshToken
 * 		2. projectActionSuccess
 * 		3. clearTempProject
 * 		3. clearPricing
 *
 * @param {formData} data - contains all relevant data for the CREATE offer request
 * @param {boolean} isNaviate - when true, the offer will be created in the naviate mode
 * @param {function} cbSuccess - callback function to be called when the offer is created
 */
export const createOrder = (data, isNaviate = false, cbSuccess = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "POST",
			url: `/order`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				const offer = res.data?.result;

				if (isNaviate) {
					cbSuccess(offer, () => {
						dispatch(createOrderSuccess(offer, data?.price));
					});
				} else {
					dispatch(createOrderSuccess(offer));
				}
			},
			(err) => {
				dispatch(
					projectActionFailure(
						localize(LocKeys.ERROR_ITEM_CREATED, [localize(LocKeys.OFFER)])
					)
				);
			}
		);
	};
};

/**
 * UPDATE offer
 * Function used for update offer
 *
 * SUCCESS:
 * 		1. getOrder
 * 		2. projectActionSuccess
 *
 * @param {formData} data - contains all relevant data for the UPDATE offer request
 */
export const updateOrder = ({
	orderId,
	data,
	cb = () => {},
	updateLodSPecificationIds = false,
	isJobView = false,
	canGetOrder = false,
}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/order/${orderId}`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				if (!isJobView) {
					dispatch(
						projectActionSuccess(
							localize(LocKeys.ITEM_UPDATED, [localize(LocKeys.OFFER)])
						)
					);

					if (updateLodSPecificationIds || canGetOrder) {
						dispatch(
							getOrder({
								orderId,
								prefillProjectTemp: true,
								updateLodSPecificationIds,
								cbSuccess: cb,
							})
						);
					} else {
						cb();
					}
				} else {
					dispatch(
						setJobSuccessMessage(
							localize(LocKeys.ITEM_UPDATED, [localize(LocKeys.JOB)])
						)
					);
					dispatch(jobActionSuccess());

					cb();
				}
			},
			(err) => {
				dispatch(
					projectActionFailure(
						localize(LocKeys.ERROR_ITEM_UPDATED, [localize(LocKeys.OFFER)])
					)
				);
			}
		);
	};
};

/**
 * Update Offer Company Information
 *
 * @param {*} orderId - Unique offer identifier
 * @param {*} data -  Contains all offer company info form data in a key-value pair
 * @returns
 */
export const updateOrderCompanyInfo = (orderId, data, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/order/${orderId}/general-info`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				dispatch(
					projectActionSuccess(
						localize(LocKeys.ITEM_UPDATED, [localize(LocKeys.OFFER)])
					)
				);
				dispatch(
					getOrder({
						orderId,
						//	prefillProjectTemp: true,
						prefillCompanyTemp: true,
						cbSuccess: cb,
					})
				);
			},
			(err) => {
				dispatch(
					projectActionFailure(
						localize(LocKeys.ERROR_ITEM_UPDATED, [localize(LocKeys.OFFER)])
					)
				);
			}
		);
	};
};

/**
 * Update offer status (single & multiple offers)
 *
 * SUCCESS:
 * 		1. projectActionSuccess
 * 		2. getOrder or getOrders
 *
 * @param {Array<number>} ids -  offer unique identifier
 * @param {Array<string>} status - offer status
 * @param {boolean} isSingleView - true - get single offer , false - get offers
 * @param {string} additionalInfo - additional info
 * @returns
 */
export const updateOrderStatus = ({
	ids,
	status,
	isSingleView = false,
	additionalInfo = null,
	cb = () => {},
}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/order-status-multiple`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
			data: { status, ids, additionalInfo },
		};
		return api(requestOptions).then(
			() => {
				switch (status) {
					case statusConstants.DECLINED:
						dispatch(
							projectActionSuccess(localize(LocKeys.DECLINED_ORDER_SUCCESS))
						);
						break;

					case statusConstants.ARCHIVED:
						dispatch(
							projectActionSuccess(
								localize(LocKeys.ITEM_ARCHIVE_SUCCESS, [
									localize(LocKeys.OFFER),
								])
							)
						);

						break;

					default:
						dispatch(projectActionSuccess(localize(LocKeys.STATUS_UPDATED)));
						break;
				}
				if (isSingleView) {
					dispatch(getOrder({ orderId: ids[0] }));
				} else {
					dispatch(getOrders({}));
				}
				cb();
			},

			(err) => dispatch(projectActionFailure(err.response.data.message))
		);
	};
};

/**
 * Action triggered by the offer maker in order to approve the offer.
 *
 * @param {number} orderId - Unique order identifier
 * @param {formdata} data -  Contains all form data in a key-value pair
 * @param {function} cb - callback function
 */
export const acceptOrder = (orderId, data, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/order/offer/${orderId}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				cb();
				dispatch(projectActionSuccess(localize(LocKeys.ORDER_ACCEPT_SUCCESS)));
			},
			(err) => {
				dispatch(projectActionFailure(err.response.data.result));
			}
		);
	};
};

/**
 * Delete offer
 *
 * @param {number} orderId - offer unique identifier
 * @returns
 */
export const deleteOrder = (orderId) => {
	return (dispatch) => {
		const requestOptions = {
			method: "DELETE",
			url: `/order/${orderId}`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch(
					projectActionSuccess(
						localize(LocKeys.ITEM_DELETED, [localize(LocKeys.OFFER)])
					)
				);
				return;
			},
			(err) => {
				dispatch(
					projectActionFailure(
						localize(LocKeys.ERROR_ITEM_DELETED, [localize(LocKeys.OFFER)])
					)
				);
			}
		);
	};
};

/**
 * SOFT delete offer
 *
 * SUCCESS:
 * 		1. projectActionSuccess
 * 		2. getOrders
 *
 * @param {Array<number>} ids - offer unique identifiers
 * @returns
 */
export const softDeleteOrder = (ids) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/order-soft-delete`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data: { ids: ids },
		};
		return api(requestOptions).then(
			(res) => {
				const filters = {
					status: [statusConstants.ARCHIVED],
				};
				dispatch(getOrders({ searchFilters: filters }));
				dispatch(
					projectActionSuccess(
						localize(LocKeys.ITEM_DELETED, [localize(LocKeys.OFFER)])
					)
				);
			},
			(err) => {
				dispatch(
					projectActionFailure(
						localize(LocKeys.ERROR_ITEM_DELETED, [localize(LocKeys.OFFER)])
					)
				);
			}
		);
	};
};

/**
 * Send offer to CLIENT contact person
 *
 * @param {number} id  -  offer unique identifier
 * @returns
 */
export const sendOfferEmail = (
	id,
	recipients,
	offerName,
	additionalInfo = null
) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PATCH",
			url: `/send-offer/${id}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data: { additionalInfo, recipients },
		};
		return api(requestOptions).then(
			(res) => {
				let recipientsString = ``;

				recipients.forEach((recipient, index) => {
					const isLast = index === recipients.length - 1;
					recipientsString += `${recipient}${isLast ? "." : ",\n"}`;
				});

				dispatch(
					projectActionSuccess(
						localize(LocKeys.SEND_OFFER_SUCCESS, [offerName, recipientsString])
					)
				);
			},
			(err) => {
				dispatch(projectActionFailure(localize(LocKeys.ERROR)));
			}
		);
	};
};
/**
 * GET offer users
 * @param {number} orderId - offer unique identifier
 * @returns
 */
export const getOrderUsers = (orderId) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/order-users/${orderId}`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: orderConstants.GET_ORDER_USERS,
					data: res.data.result,
				});
			},
			(err) => {
				dispatch(projectActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * GET offer companies
 * @param {number} orderId - offer unique identifier
 * @returns
 */
export const getOrderCompanies = (orderId) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/order-companies/${orderId}`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: orderConstants.GET_ORDER_COMPANIES,
					data: res.data.result,
				});
			},
			(err) => {
				dispatch(projectActionFailure(err.response.data.message));
			}
		);
	};
};

//---------------------------------END ENDPOINTS----------------------------------------------

//---------------------------------OFFER STATE FUNCTION-------------------------------------

export const orderActionGetFailure = (error) => {
	return { type: orderConstants.ACTION_GET_FAILURE, error };
};

// Updated request handling actions
export const clearOrderRequestState = () => {
	return { type: orderConstants.CLEAR_PROJECT_REQUEST_STATE };
};

export const projectActionFailure = (data) => {
	return {
		type: orderConstants.PROJECT_ACTION_GET_FAILURE,
		data,
	};
};

export const projectActionSuccess = (data) => {
	return {
		type: orderConstants.PROJECT_ACTION_GET_SUCCESS,
		data,
	};
};

export const offerModalActionSuccess = (offerId, price) => {
	return {
		type: orderConstants.OFFER_MODAL_ACTION_SUCCESS,
		data: { offerId, price },
	};
};

export const clearOfferModalRequestState = () => {
	return {
		type: orderConstants.CLEAR_OFFER_MODAL_REQUEST_STATE,
	};
};

export const clearOrder = () => {
	return (dispatch) => {
		dispatch({
			type: orderConstants.CLEAR_PROJECT,
		});
		dispatch(clearAllBuildingState());
	};
};

/**
 * Set TEMP Additional Information - HTML FORMAT
 * @param {*} data
 * @returns
 */
export const setHTMLAdditionalInfo = (data) => {
	return (dispatch) => {
		dispatch({
			type: orderConstants.SET_HTML_ADDITIONAL_INFO,
			data,
		});
	};
};

export const updateAsyncLoadOffers = (offers, values, isForSingleView) => {
	return (dispatch) => {
		dispatch({
			type: orderConstants.UPDATE_ASYNC_LOAD_OFFERS,
			data: { offers, values, isForSingleView },
		});
	};
};

export const clearOrderUsers = () => {
	return (dispatch) => {
		dispatch({
			type: orderConstants.CLEAR_ORDER_USERS,
		});
	};
};
