import React, { Suspense } from "react";
import CanPerform from "../../roles/CanPerform";
import Sidebar from "../sidebar/Sidebar";
import { isLogin } from "../../utils/local-storage.util";
import Page404 from "../../shared/components/default/Page404";
import Topbar from "../topbar/Topbar";
import SuspenseLoader from "../loader/SuspenseLoader";
import { CompatRoute, Navigate } from "react-router-dom-v5-compat";
import { Route } from "react-router-dom";

/**
 * Private route component - only for logged user
 *
 * @param {string} title - Route title
 * @param {Array} requiredPermissions - required permissions array
 * @param {Array} permission - permissions array
 * @param {React.ReactNode} component - Component
 * @param {boolean} hideOutletPadding - Used to trigger hide/show outlet, Default: false
 * @param {React.ReactNode} rest - Route Details
 *
 * @return {React.FC} Private Route component
 *
 */
const PrivateRoute = ({
	hideOutletPadding = false,
	requiredPermissions,
	component: Component,
	title,
	permission,
	priorityAccess = false,
	catchAllRoutes = false,
	...rest
}) => {
	document.title = title ? title + " - Bimify" : "Bimify";

	const renderComponent = (props) => {
		if (isLogin()) {
			return (
				<>
					<Sidebar />
					<CanPerform
						permission={permission}
						requiredPermissions={requiredPermissions}
						priorityAccess={priorityAccess}
						yes={() => {
							return (
								<>
									<main className="main main--has-sidebar">
										<Topbar />
										<Suspense fallback={<SuspenseLoader />}>
											<div className="outlet">
												{!hideOutletPadding ? (
													<Component {...props} />
												) : (
													<Component {...props} />
												)}
											</div>
										</Suspense>
									</main>
								</>
							);
						}}
						no={() => {
							return (
								<>
									<main className="main main--has-sidebar">
										<Topbar />
										<Page404 />
									</main>
								</>
							);
						}}
					/>
				</>
			);
		} else {
			return <Navigate to="/login" />;
		}
	};

	if (catchAllRoutes) {
		return <Route {...rest} component={renderComponent} />;
	}
	return <CompatRoute {...rest} render={renderComponent} />;
};

export default PrivateRoute;
