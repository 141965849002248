import React, { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form/immutable";
import {
	createLodSpecification,
	createOrder,
	getPresignedPostUploadUrl,
	setTempAdditionalInfo,
	updateJob,
	uploadToDigitalOcean,
} from "../../../../actions";
import localize, { LocKeys } from "../../../../constants/localizations";
import CustomStepNavigation from "../../../../shared/components/CustomStepper/CustomStepNavigation";
import BodyLayout from "../../../layout/content/BodyLayout";
import FooterLayout from "../../../layout/content/FooterLayout";
import HeaderLayout from "../../../layout/content/HeaderLayout";
import AdditionalInfoEditor from "../../single-view/parts/AdditionalInfoEditor";
import { useNavigate } from "react-router-dom-v5-compat";
import { uploadFile } from "../../../../shared/components/UploadFile/uploadFile";

/**
 * Step 3 - Create offer (AdditionalInformation - LexicalEditor)
 * @param {*} props
 * @returns
 */
const AdditionalInformation = (props) => {
	const navigate = useNavigate();
	const {
		tempBuilding,
		isNaviate = false,
		customer,
		additionalInfo,
		price,
		//Actions
		createOrder,
		setTempAdditionalInfo,
		createLodSpecification,
		cancelButtonAction,
		uploadToDigitalOcean,
		getPresignedPostUploadUrl,
		updateJob,
	} = props;
	const [isValid, setIsValid] = useState(false);
	const [loading, setLoading] = useState(false);
	const countFile = React.useRef(0);

	const setValidity = useCallback(() => {
		if (!customer) {
			setIsValid(false);
		} else {
			setIsValid(true);
		}
	}, [customer]);

	useEffect(() => {
		setValidity();
	}, [setValidity]);

	const setNewDescriptionValue = useCallback(
		(newEditorState) => {
			setTempAdditionalInfo(newEditorState);
		},
		[setTempAdditionalInfo]
	);
	//offer, disciplineLevels = [], files = [], cb
	const uploadFiles = useCallback(
		(buildingId, disciplineLevels, files = [], cb) => {
			countFile.current = 0;

			const filesData = [];
			const deliveredToBuildingFileIds = [];

			if (files.length === 0) {
				cb();
			}

			for (const fileItem of files) {
				const { file, id } = fileItem;

				const disciplineLevel = disciplineLevels.find((disciplineLevel) => {
					const findLevel = disciplineLevel.levels.find(
						(level) => level.tempFileId === id
					);

					if (findLevel) {
						return disciplineLevel;
					}

					return null;
				});

				const level = disciplineLevel?.levels.find(
					(level) => level.tempFileId === id
				);

				const dbFileData = {
					file: {
						name: file.name,
						size: file.size,
					},
					path: null,
					buildingId,
					disciplineId: disciplineLevel ? disciplineLevel?.discipline : 1,
					uploadFromOffer: true,
				};
				const preSignedPostUploadUrlBody = {
					fileName: file.name,
					buildingId,
					disciplineId: disciplineLevel ? disciplineLevel?.discipline : 1,
				};

				//------------------
				uploadFile({
					dbFileData,
					file: file,
					preSignedPostUploadUrlBody,
					uploadToDigitalOcean,
					getPresignedPostUploadUrl,
					cbDBUpload: (result) => {
						countFile.current++;

						if (!!disciplineLevel?.discipline) {
							filesData.push({
								id: id,
								file: result.id,
								level: level?.level,
								discipline: disciplineLevel?.discipline,
							});
						} else {
							deliveredToBuildingFileIds.push({
								fileId: +result.id,
								disciplineId: null,
							});
						}

						if (countFile.current === files.length) {
							cb(filesData, deliveredToBuildingFileIds);
						}
					},
				});
			}
		},
		[uploadToDigitalOcean, getPresignedPostUploadUrl]
	);

	const updateJobFile = useCallback(
		(jobId, levels = [], filesData, deliveredToBuildingFileIds, cb) => {
			const disciplineBuildingLevelFiles = [];

			levels &&
				levels.forEach((level) => {
					const findFile =
						filesData && filesData.find((file) => +file.level === +level.level);
					const file = findFile ? findFile?.file || null : null;
					const discipline = findFile ? findFile?.discipline || 1 : 1;

					disciplineBuildingLevelFiles.push({
						level: level.id,
						file: file || null,
						discipline: discipline,
					});
				});

			const body = {
				disciplineBuildingLevelFiles: disciplineBuildingLevelFiles,
			};

			if (jobId) {
				updateJob({
					jobId,
					isNaviate,
					data: body,
					cb: () => {
						cb();
						navigate("/offers");
					},
				});
			}
		},

		// eslint-disable-next-line react-hooks/exhaustive-deps
		[updateJob, isNaviate]
	);

	const createOrderAction = useCallback(
		(projectTemp, newSpecificationId = null) => {
			const priceJS = price ? price?.toJS() : null;
			let levels = [];
			let levelFrom = null;
			let levelTo = null;
			let defaultLevelHeight = null;

			if (isNaviate) {
				const building = tempBuilding && tempBuilding?.toJS();

				levelFrom = building?.levelFrom;
				levelTo = building?.levelTo;
				defaultLevelHeight = building?.defaultLevelHeight;

				building &&
					building.floors.forEach((floor) => {
						levels.push({
							id: floor.id,
							isRoof: floor?.isRoof || false,
							name: floor.name,
							elevation: floor.elevation,
							thickness: floor?.thickness || 0,
							height: floor.height,
							level: floor.level,
						});
					});
			}

			//Buildings
			const tempBuildings = projectTemp?.buildings;
			const pricePerBuilding = priceJS
				? priceJS.buildingPrice?.pricePerBuildingDetailed
				: null;

			let buildings = [];
			let size = 0;
			tempBuildings.forEach((building) => {
				size += +building.size;

				buildings.push({
					price: pricePerBuilding
						? pricePerBuilding?.[building.id]?.buildingPrice
						: null,
					pricePerSquare: pricePerBuilding
						? pricePerBuilding?.[building.id]?.pricePerSquare
						: null,
					type: building.type,
					levelsTotal: !!building.levels ? building.levels : null,
					isCustomName: building.isCustomName,
					uniqueLevels: !!building.uniqueLevels ? building.uniqueLevels : null,
					inputFormat: building.inputFormat,
					size: building.size,
					lodSpecification: newSpecificationId
						? newSpecificationId
						: projectTemp?.specification || null,
					name: building.name,
					additionalInfo: "",
					levels: isNaviate ? levels : null,
					levelFrom,
					levelTo,
					defaultLevelHeight,
				});
			});

			//Export versions
			const tempExports = projectTemp?.exports;
			const pricePerExport = priceJS
				? priceJS.exportsPrice?.pricePerExport
				: null;

			let exports = [];
			tempExports.forEach((item) => {
				exports.push({
					id: item.exportId,
					price: pricePerExport ? pricePerExport?.[item.exportId] : null,
				});
			});

			const body = {
				name: projectTemp.name,
				size: size,
				numberOfBuildings: tempBuildings.length,
				additionalInfo: projectTemp.additionalInfo,
				deliveryFormats: [projectTemp.deliveryFormat],
				revitFileId: projectTemp?.template,
				buildings,
				exports: isNaviate ? [] : exports,
				clientCompany: customer || null,
				status: isNaviate ? "accepted" : "requested",
				price: priceJS,
			};

			createOrder(body, isNaviate, (offer, cb) => {
				if (isNaviate) {
					const building = offer.buildings[0];

					uploadFiles(
						building?.id,
						projectTemp.disciplineLevels,
						projectTemp.tempFiles,
						(filesData, deliveredToBuildingFileIds) => {
							updateJobFile(
								building?.jobs[0]?.id,
								building?.levels,
								filesData,
								deliveredToBuildingFileIds,
								cb
							);
						}
					);
				} else {
					navigate("/offers");
				}
			}).then(() => navigate("/offers"));
		},
		[
			price,
			isNaviate,
			customer,
			createOrder,
			tempBuilding,
			uploadFiles,
			updateJobFile,
			navigate,
		]
	);

	const onSubmit = () => {
		try {
			if (isValid) {
				setLoading(true);
				const projectTemp = props.projectTemp?.toJS();

				const isDraft =
					`${projectTemp.configurationSpecification}-draft` ===
					projectTemp.specification;

				if (isDraft) {
					const disciplineComponentCategoryLods = [];

					projectTemp.buildingDisciplineComponentCategoryLods.forEach(
						(disciplineLod) => {
							disciplineComponentCategoryLods.push({
								discipline: disciplineLod.discipline?.id || null,
								componentCategory: disciplineLod.componentCategory?.id || null,
								lod: disciplineLod.lod?.id || null,
							});
						}
					);

					const body = {
						name: projectTemp.name,
						company: customer,
						disciplineComponentCategoryLods:
							disciplineComponentCategoryLods || [],
					};

					createLodSpecification(
						body,
						(result) => {
							createOrderAction(projectTemp, result.id);
						},
						true
					);
				} else {
					createOrderAction(projectTemp);
				}
			}
		} catch (error) {
			console.log({ error });
			return;
		}
	};

	return (
		<>
			<form
				className={`form d-flex flex-column flex-auto standardized-form	`}
				onSubmit={props.handleSubmit(onSubmit)}
				autoComplete="off"
				noValidate
			>
				<HeaderLayout wrapperClasses="px-24 d-flex align-items-center">
					<h3> {localize(LocKeys.ADDITIONAL_INFORMATION)} </h3>
				</HeaderLayout>
				<BodyLayout wrapperClasses="p-24">
					<div className="row">
						<div className="col col-100 flex-column">
							<div className="card">
								<AdditionalInfoEditor
									scrollerClasses="Lexical-content--515"
									isEdit={true}
									additionalInfo={additionalInfo}
									updateEditorState={setNewDescriptionValue}
									editView={true}
									placeholder={`${localize(
										LocKeys.ADDITIONAL_INFORMATION_PLACEHOLDER
									)}...`}
								/>
							</div>
						</div>
					</div>
				</BodyLayout>

				<FooterLayout wrapperClasses="px-24">
					<CustomStepNavigation
						showCancel={true}
						cancelButtonAction={() => cancelButtonAction()}
						leftButtonLabel={localize(LocKeys.BACK)}
						rightButtonClasses="btn--success"
						rightButtonLabel={localize(LocKeys.SEND_REQUEST)}
						leftButtonAction={() => props.previousPage()}
						rightButtonLoading={loading}
						rightButtonDisabled={!isValid || loading}
					/>
				</FooterLayout>
			</form>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		additionalInfo: state.getIn(["projectTemp", "additionalInfo"]) || "",
		orderId: state.getIn(["projectTemp", "project", "id"]),
		projectTemp: state.getIn(["projectTemp"]),
		customer: state.getIn(["global", "customer"]),
		tempBuilding: state.getIn(["building", "tempBuilding"]),
		price: state.get("price"),
	};
};

export default connect(mapStateToProps, {
	setTempAdditionalInfo,
	createOrder,
	createLodSpecification,
	uploadToDigitalOcean,
	getPresignedPostUploadUrl,
	updateJob,
})(
	reduxForm({
		form: "createOrderForm",
		touchOnBlur: false,
		destroyOnUnmount: false,
		enableReinitialize: false,
	})(AdditionalInformation)
);
