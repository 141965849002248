import buildingConstants from "../constants/buildingConstants";
import { jobConstants } from "../constants/jobConstants";
import { getItemFromLocalStorage, getToken } from "../utils/local-storage.util";
import localize, { LocKeys } from "../constants/localizations";
import { destroy } from "redux-form";
import { api } from "../api";
import { statusConstants, viewerConstants } from "../constants/constants";
import { getPagination, getQuery } from "../utils/table-filter";
import {
	clearAllBuildingState,
	setBuildingSuccessMessage,
} from "./buildingActions";
import administrationConstants from "../constants/administrationConstants";
import {
	setTempDXFServerComponentParameters,
	setTempDXFServerComponents,
} from "./tempAdministrationAction";

/**
 * Retrieves all jobs
 *
 * SUCCESS:
 *    1. Populates job.jobList with a building array
 *    2. Populates buildings.jobsMeta with the response meta info (limit, total, offset, page)
 *
 * ERROR: Triggers jobActionFailure()
 *
 * @param {Object} searchFilters - object that contains status
 * @param {number} page - page number
 * @param {number} limit - number of items per page
 * @param {string} searchQuery - search query
 * @param {number} customer - customer id
 * @param {number} resellerCompanyId - reseller company id
 * @param {boolean} isForSelectField - when true, cbSelectField will be called with the response data
 * @param {Function} cbSelectField - callback function
 * @param {boolean} isForBuildingActivity - when true, response data will be used to populate building activity
 * @param {number} buildingId - building id
 *
 * @returns http response with array of jobs
 */

export const getJobs = ({
	page = 1,
	searchQuery = null,
	searchFilters,
	customer = getItemFromLocalStorage("Customer"),
	resellerCompanyId = getItemFromLocalStorage("Reseller"),
	limit = 8,
	isForSelectField = false,
	cbSelectField = () => {},
	isForBuildingActivity = false,
	buildingId = null,
}) => {
	return (dispatch, getState) => {
		const state = getState();

		const jobQueryParameter = state.getIn([
			"queryParameter",
			"jobQueryParameter",
		]);
		const tablePagination = state.getIn(["form", "tablePagination", "values"]);

		const { filters, search } = getQuery({
			queryParameter: jobQueryParameter,
			searchFilters,
			searchQuery,
		});

		const { currentPage, currentLimit } = getPagination({
			tablePagination,
			page,
			limit,
		});

		const data = {
			page: isForSelectField || isForBuildingActivity ? page : currentPage,
			search,
			limit: isForSelectField || isForBuildingActivity ? limit : currentLimit,
			filters,
			selectedCompany: customer,
			resellerCompanyId,
		};

		if (isForSelectField) {
			data.filters = null;
			data.search = searchQuery;
		}

		if (isForBuildingActivity) {
			data.filters = searchFilters;
			data.search = searchQuery;
			data.buildingId = buildingId;
		}

		const requestOptions = {
			method: "PUT",
			url: `/job`,
			headers: {
				Authorization: "Bearer " + getToken(),
				"Content-Type": "application/json",
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				if (isForSelectField) {
					cbSelectField(res.data?.result);
				} else if (isForBuildingActivity) {
					dispatch({
						type: buildingConstants.GET_BUILDING_ACTIVITY,
						data: res.data,
					});
				} else {
					dispatch({ type: jobConstants.GET_JOBS, data: res.data });
					dispatch(
						getJobStats({ filters, customer, search, resellerCompanyId })
					);
				}
			},
			(err) => {
				dispatch(jobActionFailure(err.response.data.result));
			}
		);
	};
};

/**
 * GET all building jobs
 * @param {number} buildingId - building unique identifier
 * @returns
 */
export const getAllJobs = (buildingId) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/job-get-all?buildingId=${buildingId}`,
			headers: {
				Authorization: "Bearer " + getToken(),
				"Content-Type": "application/json",
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: buildingConstants.GET_ALL_BUILDING_ACTIVITY,
					data: res.data,
				});
			},
			(err) => {
				dispatch(jobActionFailure(err.response.data.result));
			}
		);
	};
};

/**
 * Used to retrieve a single job based on the provided id
 *
 * SUCCESS: Populates 'job' with the response job object
 *
 * ERROR: Triggers jobActionFailure()
 *
 * @param {number} id -  job unique identifier
 * @param {number} step - job step
 * @param {number} cb -  callback function
 * @param {boolean} prefillJobTemp - when true tempJob will be prefilled with response values
 * @returns
 */
export const getJob = (
	id,
	prefillJobTemp = false,
	step = null,
	cb = () => {},
	controller
) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/job-step`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data: {
				step,
				id,
			},
			signal: controller?.signal || null,
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({ type: jobConstants.GET_JOB, data: res.data?.result, step });

				if (prefillJobTemp) {
					dispatch({
						type: jobConstants.SET_TEMP_JOB,
						data: res.data?.result,
					});
				}
			},
			(err) => {
				if (!err?.isCancel) {
					cb();
					dispatch(jobActionFailure(err.response.data.result));
				}
			}
		);
	};
};

/**
 * Retrieves Job Stats
 *
 * SUCCESS: Populates 'stats' in the job state.
 *
 * @returns array of job stats
 */
export const getJobStats = ({
	filters,
	customer = getItemFromLocalStorage("Customer"),
	resellerCompanyId = getItemFromLocalStorage("Reseller"),
	search,
}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: "/job-stats",
			headers: {
				Authorization: "Bearer " + getToken(),
				"Content-Type": "application/json",
			},
			data: {
				customer,
				filters,
				search,
				resellerCompanyId,
			},
		};
		return api(requestOptions).then(
			(res) => {
				let data = res.data;
				dispatch({ type: jobConstants.JOB_STATS, data });
			},
			(err) => {
				dispatch(jobActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * CREATE JOB
 *
 * SUCCESS:
 * 		1. getJobs
 * 		2. jobActionSuccess && setJobSuccessMessage
 * 		3. clearTempJob
 *
 * @param {*} data - Contains all job form data in a key-value pair
 *
 */
export const createJob = ({ data, isModelUpdate = false, cb = () => {} }) => {
	return (dispatch) => {
		const requestOptions = {
			method: "POST",
			url: `/job`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				if (!isModelUpdate) {
					dispatch(getJobs({}));
					dispatch(
						setJobSuccessMessage(
							localize(LocKeys.ITEM_CREATED, [localize(LocKeys.JOB)])
						)
					);
					dispatch(jobActionSuccess());
					dispatch(clearTempJob());
					cb();
				} else {
					dispatch(
						setBuildingSuccessMessage(
							localize(LocKeys.ITEM_CREATED, [localize(LocKeys.JOB)])
						)
					);

					cb();
				}
			},
			(err) => {
				dispatch(
					jobActionFailure(
						localize(LocKeys.ERROR_ITEM_CREATED, [localize(LocKeys.JOB)])
					)
				);
			}
		);
	};
};

/**
 * Get job status file
 *
 * SUCCESS:
 * 		1. changeJobFileViewerUrl or changeJobForgeViewerUrn depends of result.viewer value
 * 		2. Populates bimModel.file in the job state.
 *
 * @param {*} data - { jobId, jobStatusId }
 * @returns
 */
export const getJobStatusFile = (data) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/job-status-file`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				const result = res.data.result;

				if (result) {
					const { viewer, previewLink, file } = result;

					switch (viewer) {
						case viewerConstants.LIGHTBOX:
							dispatch(
								changeJobFileViewerUrl({ previewLink, fileName: file.name })
							);
							break;

						default:
							dispatch(changeJobForgeViewerUrn(previewLink));
							break;
					}
				} else {
					dispatch(changeJobFileViewerUrl(null));
					dispatch(changeJobForgeViewerUrn(null));
				}
				dispatch({ type: jobConstants.GET_JOB_STATUS_FILE, data: result });
			},
			(err) => {
				dispatch(jobActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Update job status (single & mutlipple jobs)
 *
 * SUCCESS:
 * 		1. jobActionSuccess && setJobSuccessMessage
 * 		2. getJobs or getJob
 *
 * @param {*} ids -  job unique identifier
 * @param {*} status - job status
 * @param {*} isSingleView - true - get single job , false - get jobs
 * @returns
 */
export const updateJobStatus = (
	ids,
	status,
	isSingleView = false,
	step = null
) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/job-status-multiple`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
			data: { status, ids },
		};
		return api(requestOptions).then(
			() => {
				switch (status) {
					case statusConstants.ARCHIVED:
						dispatch(
							setJobSuccessMessage(
								localize(LocKeys.ITEM_ARCHIVE_SUCCESS, [localize(LocKeys.JOB)])
							)
						);
						break;
					default:
						dispatch(setJobSuccessMessage(localize(LocKeys.UPDATE_JOB_STATUS)));
						break;
				}
				dispatch(jobActionSuccess());

				if (isSingleView) {
					dispatch(getJob(ids[0], false, step));
				} else {
					dispatch(getJobs({}));
				}
			},

			(err) => dispatch(jobActionFailure(err.response.data.message))
		);
	};
};

/**
 * Delete jobs
 *
 * SUCCESS:
 * 		1. jobActionSuccess && setJobSuccessMessage
 * 		2. getJobs or getJob
 *
 * @param {Array} ids - jobs unique identifiers
 * @returns
 */
export const deleteJob = (ids) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/job-soft-delete`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data: { ids: ids },
		};
		return api(requestOptions).then(
			(res) => {
				const filters = {
					status: [statusConstants.ARCHIVED],
				};
				dispatch(getJobs({ page: 1, searchQuery: "", searchFilters: filters }));
				dispatch(
					setJobSuccessMessage(
						localize(LocKeys.ITEM_DELETED, [localize(LocKeys.JOB)])
					)
				);
				dispatch(jobActionSuccess());
			},
			(err) => {
				dispatch(
					jobActionFailure(
						localize(LocKeys.ERROR_ITEM_DELETED, [localize(LocKeys.JOB)])
					)
				);
			}
		);
	};
};

/**
 * Get job status (Normalisation, Qustomisation, QA)
 *
 * SUCCESS: Populates 'jobStatus' in the job state.
 *
 * @returns
 */
export const getJobStatus = () => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/job-status`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: jobConstants.GET_JOB_STATUS,
					data: res.data?.result,
				});
			},
			(err) => {
				dispatch(jobActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Function used for UPDATE job
 *
 * SUCCESS: Get job endpoint
 *
 * @param {number} jobId - job unique identifier
 * @param {*} data - contains all relevant data for the UPDATE job request
 * @param {number} step - job step (used for get job)
 * @param {boolean} isNaviate -  false - update job
 * @returns
 */
export const updateJob = ({
	jobId,
	data,
	step = null,
	isNaviate = false,
	cb = () => {},
	isModelHistory = false,
}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/job/${jobId}`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				if (!isNaviate) {
					if (isModelHistory) {
						dispatch(
							setBuildingSuccessMessage(
								localize(LocKeys.ITEM_UPDATED, [localize(LocKeys.ACTIVITY)])
							)
						);
					} else {
						dispatch(
							setJobSuccessMessage(
								localize(LocKeys.ITEM_UPDATED, [localize(LocKeys.JOB)])
							)
						);
						dispatch(jobActionSuccess());
					}

					dispatch(getJob(jobId, true, step));
				}

				cb();
			},
			(err) => {
				dispatch(
					jobActionFailure(
						localize(LocKeys.ERROR_ITEM_UPDATED, [localize(LocKeys.JOB)])
					)
				);
			}
		);
	};
};

/**
 * Function used for UPDATE job MANUALS
 *
 * @param {*} id - job unique identifier
 * @param {*} data - contains all relevant data for the UPDATE job manuals request
 * @param {number} step - step
 * @returns
 */
export const updateJobManuals = (id, data, step) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/job-manuals/${id}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				dispatch(
					jobActionSuccess(
						localize(LocKeys.ITEM_UPDATED, [localize(LocKeys.JOB)])
					)
				);
				dispatch(getJob(id, true, step));
			},
			(err) => {
				dispatch(jobActionFailure(err.response.data.result));
			}
		);
	};
};

/**
 * Get all job comments
 *
 * SUCCESS: Populates 'jobComments.list' in the job state.
 *
 * @param {*} data - {jobId, jobStatus}
 * @returns
 */
export const getJobComments = (data) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/job-comment`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: jobConstants.GET_COMMENTS,
					data: res.data?.result,
				});
			},
			(err) => {
				dispatch(jobActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Get unread job comments count
 *
 * @param {*} data - contains all relevant data
 * @returns
 */
export const getUnreadJobCommentsCount = (data) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/job-count-unread-comments`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				return res.data.result;
			},
			(err) => {
				dispatch(jobActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Function used for job comment creation
 *
 * @param {*} data -(job, jobStatus, comment) contains all relevant data for the CREATE job comment request
 * @returns getJobComments
 */
export const createJobComments = (data) => {
	return (dispatch) => {
		const requestOptions = {
			method: "POST",
			url: `job-comment`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				dispatch(getJobComments(data));
			},
			(err) => {
				dispatch(jobActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Send JOB to Queue
 *
 * @param {*} jobId - job unique identifier
 * @param {*} jobStatusId - job status unique identifier
 * @param {*} cb - callback function
 */
export const sendJobToQueue = (jobId, jobStatusId, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "POST",
			url: "/job-send",
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
			data: {
				jobId,
				jobStatusId,
			},
		};
		return api(requestOptions).then(
			(res) => {
				cb(true);
			},
			(err) => {
				cb(false);
				dispatch(jobActionFailure(err.response.data.message));
			}
		);
	};
};

export const cancelApsJob = (jobId, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PATCH",
			url: "/job-cancel-aps",
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
			data: {
				jobId,
			},
		};
		return api(requestOptions).then(
			(res) => {
				cb(true, false);
			},
			(err) => {
				cb(false, true);
				//	dispatch(jobActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * UPDATE job viewer settings
 *
 * @param {number} jobId - unique job identifier
 * @param {boolean} isViewerEnabled - true - enable viewer, false - disable viewer
 * @param {number} jobStatusId - unique job status identifier
 * @param {Function} cb - callback function
 * @returns
 */
export const updateJobViewer = (
	jobId,
	isViewerEnabled,
	jobStatusId,
	cb = () => {}
) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PATCH",
			url: `/job-viewer/${jobId}`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
			data: { isViewerEnabled },
		};
		return api(requestOptions).then(
			(res) => {
				dispatch(getJobViewer(jobId));
				if (isViewerEnabled) dispatch(getJobStatusFile({ jobId, jobStatusId }));

				cb();
			},
			(err) => {
				dispatch(jobActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * GET job viewer settings
 * @param {number} jobId - unique job identifier
 * @returns
 */
export const getJobViewer = (jobId) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/job-viewer/${jobId}`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: jobConstants.GET_JOB_VIEWER,
					data: res.data?.result,
				});
			},
			(err) => {
				dispatch(jobActionFailure(err.response.data.message));
			}
		);
	};
};

//-------------------------------------------------------

//--------------------Discipline estimations--------------

/**
 * Get job estimations (job estimate)
 * @param {number} jobId - unique job identifier
 * @returns
 */
export const getJobEstimation = (jobId) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/job-specification/${jobId}`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: jobConstants.GET_JOB_SPECIFICATION,
					data: res.data?.result,
				});
			},
			(err) => {
				dispatch(jobActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Update job estimation
 *
 * @param {number} jobId - unique Job identifier
 * @param {object} data - {id, consultantEstimateHours}
 * @param {Function} cb - callback function
 * @returns
 */
export const updateJobEstimation = (jobId, data, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PATCH",
			url: `/job-estimate/${jobId}`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				cb();

				dispatch(getJobEstimation(jobId));
				dispatch(
					jobActionSuccess(
						localize(LocKeys.ITEM_UPDATED, [localize(LocKeys.ESTIMATE)])
					)
				);
			},
			(err) => {
				dispatch(jobActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Re-calculate job estimate
 *
 * @param {number} jobId - unique Job identifier
 * @param {Object} data - ids of job estimation
 * @returns
 */
export const reCalculateJobEstimate = (jobId, data) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/job-estimate-recalculate/${jobId}`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: jobConstants.RE_CALCULATE_TEMP_JOB_SPECIFICATION,
					data: res.data?.result,
				});
			},
			(err) => {
				dispatch(jobActionFailure(err.response.data.message));
			}
		);
	};
};

//-----------------------------------------------------------------

//--------------------Export estimations----------------------------

/**
 * Finish the estimation review for a job.
 *
 * @param {number} jobId - The ID of the job.
 * @return {Function} A dispatch function.
 */
export const finishEstimationReview = (jobId, data) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/job-estimate-finish/${jobId}`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				dispatch(jobActionSuccess(localize(LocKeys.FINISH_ESTIMATION_REVIEW)));
				dispatch(getJob(jobId, false, 3));
			},
			(err) => {
				dispatch(jobActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * GET export estimations
 *
 * @param {number} jobId - unique job identifier
 * @returns
 */
export const getExportEstimation = (jobId) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/job-export-input-format-adjustments/${jobId}`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: jobConstants.SET_EXPORT_ESTIMATIONS,
					data: res.data?.result,
				});
			},
			(err) => {
				dispatch(jobActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Update export estimation
 *
 * @param {number} jobId - unique Job identifier
 * @param {object} data - {id, consultantEstimateHours}
 * @param {Function} cb - callback function
 * @returns
 */
export const updateExportEstimation = (jobId, data, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PATCH",
			url: `/job-export-input-format-adjustments/${jobId}`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				cb();
				dispatch(getExportEstimation(jobId));
				dispatch(getJob(jobId, false, 3));
				dispatch(
					jobActionSuccess(
						localize(LocKeys.ITEM_UPDATED, [localize(LocKeys.ESTIMATE)])
					)
				);
			},
			(err) => {
				dispatch(jobActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Re-calculate export estimate
 *
 * @param {number} jobId - unique Job identifier
 * @param {Object} data - ids of export estimation
 * @returns
 */
export const reCalculateExportEstimate = (jobId, data) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/job-export-input-format-adjustments-re-estimate/${jobId}`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: jobConstants.RE_CALCULATE_TEMP_EXPORT_ESTIMATIONS,
					data: res.data?.result,
				});
			},
			(err) => {
				dispatch(jobActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * GET job customer
 *
 * @param {number} jobId - unique job identifier
 * @param {Function} cb - callback function
 *
 * @returns
 */
export const getJobCustomer = (jobId, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/job-customer-company/${jobId}`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				cb(+res.data?.result);
			},
			(err) => {
				dispatch(jobActionFailure(err.response.data.message));
			}
		);
	};
};

//--------------------------------------------------------------------------

//-------------------------Service estimations------------------------------
/**
 * GET service estimations
 *
 * @param {number} jobId - unique job identifier
 * @returns
 */
export const getServiceEstimation = (jobId) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/job-status-input-format-manual-adjustments/${jobId}`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: jobConstants.SET_SERVICE_ESTIMATIONS,
					data: res.data?.result,
				});
			},
			(err) => {
				dispatch(jobActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Update service estimation
 *
 * @param {number} jobId - unique Job identifier
 * @param {object} data - {id, consultantEstimateHours}
 * @param {Function} cb - callback function
 * @returns
 */
export const updateServiceEstimation = (jobId, data, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PATCH",
			url: `/job-status-input-format-manual-adjustments/${jobId}`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				cb();
				dispatch(getServiceEstimation(jobId));
				dispatch(getJob(jobId, false, 3));
				dispatch(
					jobActionSuccess(
						localize(LocKeys.ITEM_UPDATED, [localize(LocKeys.ESTIMATE)])
					)
				);
			},
			(err) => {
				dispatch(jobActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Re-calculate service estimate
 *
 * @param {number} jobId - unique Job identifier
 * @param {Object} data - ids of service estimation
 * @returns
 */
export const reCalculateServiceEstimate = (jobId, data) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/job-status-input-format-manual-adjustments-re-estimate/${jobId}`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: jobConstants.RE_CALCULATE_TEMP_SERVICE_ESTIMATIONS,
					data: res.data?.result,
				});
			},
			(err) => {
				dispatch(jobActionFailure(err.response.data.message));
			}
		);
	};
};

//--------------------------------------------------------------------------

/**
 * GET job assigned users
 * @param {number} jobId - unique job identifier
 * @returns
 */
export const getJobAssignedUsers = (jobId) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/job-assignee-users/${jobId}`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: jobConstants.GET_ASSIGNED_USERS,
					data: res.data?.result,
				});
			},
			(err) => {
				dispatch(jobActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * UPDATE/CREATE/DELETE job assigned users
 *
 * @param {number} jobId - unique job identifier
 * @param {number[]} assigneeIds - array of assignee ids
 * @param {boolean} isSingleView - true - get job assigned users , false - get jobs
 * @param {Function} cb - callback function
 * @returns
 */
export const updateJobAssignedUsers = (
	jobId,
	assigneeIds,
	isSingleView,
	cb = () => {}
) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/job-assignee-users/${jobId}`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
			data: { assigneeIds },
		};
		return api(requestOptions).then(
			(res) => {
				if (!!isSingleView) {
					dispatch(getJobAssignedUsers(jobId));
				} else {
					dispatch(getJobs({}));
				}
				cb();
			},
			(err) => {
				dispatch(jobActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 *
 * @param {number} jobId - unique job identifier
 * @param {object} body - {normalisationDeliveryDate,customisationDeliveryDate,qaDeliveryDate}
 * @param {Function} cb - callback function
 * @returns
 */
export const updateJobDeliveryDate = (
	jobId,
	body,
	isSingleView,
	cb = () => {},
	cbError = () => {}
) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PATCH",
			url: `/job-delivery-date/${jobId}`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
			data: body,
		};
		return api(requestOptions).then(
			(res) => {
				if (!!isSingleView) {
					dispatch(getJobDeliveryDate(jobId));
				}
				cb();
			},
			(err) => {
				dispatch(jobActionFailure(err.response.data?.message));
				cbError(err.response.data?.message);
			}
		);
	};
};

/**
 * GET job delivery date
 * @param {number} jobId - unique job identifier
 * @returns
 */
export const getJobDeliveryDate = (jobId) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/job-delivery-dates/${jobId}`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: jobConstants.GET_DELIVERY_DATE,
					data: res.data?.result,
				});
			},
			(err) => {
				dispatch(jobActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Publish files to building
 *
 * @param {number} jobId
 * @param {object} data - {disciplineId, fileId}
 * @param {Function} cb - callback function
 * @returns
 */
export const publishFilesToBuilding = (jobId, data, step, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "POST",
			url: `/job-publish-files-to-building/${jobId}`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				dispatch(jobActionSuccess(localize(LocKeys.FILES_DELIVERED)));
				cb();
				dispatch(
					updateJobStatus([jobId], statusConstants.DELIVERED, true, step)
				);
			},
			(err) => {
				dispatch(jobActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * GET job users
 * @param {number} jobId - unique job identifier
 * @returns
 */
export const getJobUsers = (jobId) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/job-users/${jobId}`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: jobConstants.GET_JOB_USERS,
					data: res.data.result,
				});
			},
			(err) => {
				dispatch(jobActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * GET job companies
 * @param {number} jobId - unique job identifier
 * @returns
 */
export const getJobCompanies = (jobId) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/job-companies/${jobId}`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: jobConstants.GET_JOB_COMPANIES,
					data: res.data.result,
				});
			},
			(err) => {
				dispatch(jobActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Get job specification
 *
 * @param {number} id - unique job identifier
 * @returns
 */
export const getJobSpecification = ({ id }) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/job/${id}/specs`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: buildingConstants.GET_BUILDING_SPECIFICATION,
					data: res.data.result,
				});
			},
			(err) => {
				dispatch(jobActionFailure(err.response?.data?.message));
			}
		);
	};
};

/**
 * SEND point cloud job to extraction
 *
 * @param {number} id - unique job identifier
 * @param {object} data - {pointCloudFileId} - point cloud file unique identifier
 * @param {Function} cb - callback function
 * @returns
 */
export const sendJobPCExtraction = ({ id, data, cb = () => {} }) => {
	return (dispatch) => {
		const requestOptions = {
			method: "POST",
			url: `/job-send-point-cloud/${id}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				cb();
			},
			(err) => {
				dispatch(jobActionFailure(err.response?.data?.message));
			}
		);
	};
};

//-----------------------DXF_SETTINGS------------------------------

/**
 * GET Job DXF Server Components
 *
 * @param {number} jobId - unique job identifier
 * @param {number} disciplineId - unique discipline identifier
 *
 * @returns Set response to DXF_SERVER_COMPONENTS and TEMP_DXF_SERVER_COMPONENTS
 */
export const getJobDXFServerComponents = ({ jobId, disciplineId }) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/job-discipline-component-category-dxf-prop?jobId=${jobId}&disciplineId=${disciplineId}`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: administrationConstants.GET_DXF_SERVER_COMPONENTS,
					data: { disciplineId, result: res.data?.result },
				});
				dispatch(
					setTempDXFServerComponents({ disciplineId, result: res.data.result })
				);
			},
			(err) => {
				dispatch(jobActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * GET Job DXF Server Component Parameters
 *
 * @param {number} jobId - unique job identifier
 * @param {number} componentId - unique component identifier
 * @param {number} disciplineId - unique discipline identifier
 * @param {Function} cb - callback function
 *
 * @returns Set response to DXF_SERVER_COMPONENTS_PARAMETERS and TEMP_DXF_SERVER_COMPONENT_PARAMETERS
 */
export const getJobDXFServerComponentParameters = ({
	jobId,
	componentId,
	disciplineId,
	cb = () => {},
}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/job-discipline-component-category-parameter-category?jobId=${jobId}&disciplineComponentCategoryId=${componentId}`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: administrationConstants.GET_DXF_SERVER_COMPONENT_PARAMETERS,
					data: { disciplineId, componentId, result: res.data?.result },
				});
				dispatch(
					setTempDXFServerComponentParameters({
						disciplineId,
						componentId,
						result: res.data.result,
					})
				);
				cb();
			},
			(err) => {
				dispatch(jobActionFailure(err.response.data.message));
			}
		);
	};
};

export const updateJobDXFServerComponentParameters = ({
	jobId,
	componentId,
	disciplineId,
	data,
	cb = () => {},
}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/job-discipline-component-category-parameter-category/${componentId}`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				dispatch(
					getJobDXFServerComponentParameters({
						jobId,
						disciplineId,
						componentId,
						cb: () => {
							cb();
							dispatch(jobActionSuccess());
							dispatch(
								setJobSuccessMessage(
									localize(LocKeys.ITEM_UPDATED, [localize(LocKeys.PARAMETERS)])
								)
							);
						},
					})
				);
			},
			(err) => {
				dispatch(
					jobActionFailure(
						localize(LocKeys.ERROR_ITEM_UPDATED, [localize(LocKeys.PARAMETERS)])
					)
				);
			}
		);
	};
};

//---------------------------------END ENDPOINTS----------------------------------------------

//---------------------------------JOB STATE FUNCTION-------------------------------------

/**
 * Set value on field inside TEMP JOB object
 * @param {string} stateKey - field name
 * @param {number} value
 * @returns
 */
export const setJobTempFieldValue = (stateKey, value) => {
	let isValid = false;

	switch (stateKey) {
		default:
			isValid = !!value;
			break;
	}

	let data = {
		stateKey,
		value,
		isValid,
	};

	return {
		type: jobConstants.SET_TEMP_FIELD_VALUE,
		data,
	};
};

/**
 * Set job description inside TEMP JOB object
 * @param {*} id - Job status unique identifier
 * @param {*} value
 * @returns
 */
export const setJobDescription = (id, fieldKey, value) => {
	return {
		type: jobConstants.SET_JOB_DESCRIPTION,
		data: {
			id,
			fieldKey,
			value,
		},
	};
};

/**
 * Set initial job description for all job statuses inside TEMP JOB object
 * @returns
 */
export const setInitJobDescription = () => {
	return {
		type: jobConstants.SET_INIT_JOB_DESCRIPTION,
	};
};

/**
 * Set file per level inside TEMP JOB object, depends on disciplineId and field name (key)
 * Update files per discipline: Files list depends on frist selected file format
 * @param {*} key - field name
 * @param {*} value
 * @param {*} disciplineId
 * @returns
 */
export const setFilePerLevel = (key, value, disciplineId) => {
	return (dispatch, getState) => {
		const state = getState();
		let findFile = null;
		let disciplineFiles = state.getIn(["building", "filesList"]);
		let disciplineLevels = state.getIn(["job", "tempJob", "disciplineLevels"]);
		let files = disciplineFiles && disciplineFiles.toJS();
		if (value) {
			findFile = files && files.find((file) => file.id === value);
			const updateFiles =
				files && files.filter((file) => file.extension === findFile?.extension);

			files = updateFiles;
		} else {
			const disciplineLevelsJS = disciplineLevels && disciplineLevels?.toJS();
			let isLastFile = false;

			const disciplineLevel =
				disciplineLevelsJS &&
				disciplineLevelsJS.find(
					(disciplineLevel) => +disciplineLevel.discipline === +disciplineId
				);

			const levels = disciplineLevel?.levels;

			const removeItemIndex =
				levels &&
				levels.findIndex((level) => {
					return level.key === key;
				});

			if (removeItemIndex !== -1) {
				levels.splice(removeItemIndex, 1);

				isLastFile = levels.every((item) => item.valid === false);

				if (isLastFile) {
					const tempFiles = state.getIn(["building", "tempFilesList"]);
					files = tempFiles && tempFiles.toJS();
				}
			}
		}

		dispatch({
			type: buildingConstants.UPDATE_DISCIPLINE_FILES,
			data: files,
		});

		const data = {
			key,
			value,
			disciplineId,
			extension: findFile?.extension || null,
			path: findFile?.path || null,
		};
		dispatch({
			type: jobConstants.SET_FILE_PER_LEVEL,
			data,
		});
	};
};

/**
 * Set slected building id inside TEMP JOB object
 * @param {*} buildingId
 * @returns
 */
export const setBuildingId = (buildingId) => {
	return (dispatch) => {
		dispatch({
			type: jobConstants.SET_BUILDING_ID,
			data: {
				buildingId,
			},
		});
	};
};

/**
 * Set initial component category and lods per decipline inside TEMP JOB object
 * @returns
 */
export const setJobLods = () => {
	return (dispatch, getState) => {
		const state = getState();
		let disciplines = state.getIn(["administration", "disciplines"]);
		dispatch({
			type: jobConstants.SET_LODS,
			data: { disciplines },
		});
	};
};
/**
 * Set initial levels per decipline inside TEMP JOB object
 * @param {*} building
 * @returns
 */
export const setDisciplineLevels = (building) => {
	return (dispatch, getState) => {
		const state = getState();
		let disciplines = state.getIn(["administration", "disciplines"]);
		dispatch({
			type: jobConstants.SET_LEVELS,
			data: { building, disciplines },
		});
	};
};

/**
 * Update lods,component category per decipline inside TEMP JOB object
 * @param {object} data - {componentCategoryId, lodValueId, disciplineId}
 * @returns
 */
export const updateJobLods = (data) => {
	return (dispatch) => {
		dispatch({
			type: jobConstants.UPDATE_LODS,
			data,
		});
	};
};

/**
 * Set Exports versions (TEMP JOB)
 * @param {*} data
 * @returns
 */
export const setJobExports = (data) => {
	return (dispatch) => {
		dispatch({
			type: jobConstants.SET_EXPORTS,
			data: data,
		});
	};
};

/**
 * Clear TEMP JOB object and destory FORM
 * @param {string} formName
 * @returns
 */
export const clearTempJob = (formName = "createJobForm") => {
	return (dispatch) => {
		dispatch({ type: jobConstants.CLEAR_TEMP_JOB });
		formName && dispatch(destroy(formName));
	};
};

/**
 * RESET TEMP JOB object
 *
 * @returns
 */
export const resetTempJob = () => {
	return (dispatch) => {
		dispatch({ type: jobConstants.RESET_TEMP_JOB });
	};
};

/**
 * Clear JOB object
 */
export const clearJob = (clearBuilding = false, step = 0) => {
	return (dispatch) => {
		dispatch({ type: jobConstants.CLEAR_JOB, data: step });
		clearBuilding && dispatch(clearAllBuildingState());
	};
};

/**
 * Clear JOB estimation
 */
export const clearJobEstimation = () => {
	return (dispatch) => {
		dispatch({ type: jobConstants.CLEAR_JOB_ESTIMATION });
	};
};

/**
 * Update forge URN
 * @param {*} data
 * @returns
 */
export const changeJobForgeViewerUrn = (data) => {
	return { type: jobConstants.CHANGE_FORGE_VIEWER_URN, data };
};

/**
 * Update Temp Job Estimation
 * @param {*} data  = {id,value}
 * @returns
 */
export const updateTempJobEstimation = (
	id = null,
	value,
	disciplineId = null
) => {
	return (dispatch) => {
		dispatch({
			type: jobConstants.UPDATE_TEMP_JOB_ESTIMATION,
			data: { id, value, disciplineId },
		});
	};
};

export const updateTempJobEstimationBimifyModelSaving = ({
	id,
	value,
	disciplineId,
}) => {
	return (dispatch) => {
		dispatch({
			type: jobConstants.UPDATE_TEMP_JOB_ESTIMATION_BIMIFY_MODEL_SAVING,
			data: { id, value, disciplineId },
		});
	};
};

/**
 * Reset Temp Job Estimation object
 */
export const resetTempJobEstimation = () => {
	return (dispatch) => {
		dispatch({ type: jobConstants.RESET_TEMP_JOB_ESTIMATION });
	};
};

/**
 * UPDATE ASYNC LOAD JOBS
 * @param {Array} jobs - array of jobs
 * @param {object|Array} values - input values
 * @param {boolean} isForSingleView - true/false - single or multi select
 * @returns
 */
export const updateAsyncLoadJobs = (jobs, values, isForSingleView) => {
	return (dispatch) => {
		dispatch({
			type: jobConstants.UPDATE_ASYNC_LOAD_JOBS,
			data: { jobs, values, isForSingleView },
		});
	};
};

//------------------------------ASSIGNED USER-------------------------------------

/**
 * UPDATE TEMP assigned user
 * @param {number} tempId - unique TEMP identifier
 * @param {number} id - unique user identifier
 * @param {string} status - job status
 * @returns
 */
export const updateTempAssignedUser = (tempId, id, status) => {
	return (dispatch, getState) => {
		const state = getState();
		const userOptions = state.getIn(["user", "usersList"])?.toJS() || [];

		//find user from users list by id
		const user = userOptions && userOptions.find((user) => user.id === id);

		dispatch({
			type: jobConstants.UPDATE_TEMP_ASSIGNED_USER,
			data: { tempId, id, user, status },
		});
	};
};

/**
 * REMOVE TEMP assigned user
 * @param {number} tempId - unique TEMP identifier
 * @param {string} status - job status
 * @returns
 */
export const removeTempAssignedUser = (tempId, status) => {
	return (dispatch) => {
		dispatch({
			type: jobConstants.REMOVE_TEMP_ASSIGNED_USER,
			data: { tempId, status },
		});
	};
};

/**
 * ADD TEMP assigned user
 * @param {string} status  - job status
 * @returns
 */
export const addTempAssignedUser = (status) => {
	return (dispatch) => {
		dispatch({ type: jobConstants.ADD_TEMP_ASSIGNED_USER, data: { status } });
	};
};

/**
 * SET TEMP assigned user
 * @param {Array} users  - array of users
 * @param {string} status - job status
 * @returns
 */
export const setTempAssignedUsers = (users, status) => {
	return (dispatch) => {
		dispatch({
			type: jobConstants.SET_TEMP_ASSIGNED_USERS,
			data: { users, status },
		});
	};
};

/**
 * RESET TEMP assigned user
 * @returns
 */
export const resetTempAssignedUsers = () => {
	return (dispatch) => {
		dispatch({
			type: jobConstants.RESET_TEMP_ASSIGNED_USERS,
		});
	};
};

/**
 * CLEAR TEMP assigned user
 * @returns
 */
export const clearTempAssignedUsers = () => {
	return (dispatch) => {
		dispatch({
			type: jobConstants.CLEAR_TEMP_ASSIGNED_USERS,
		});
	};
};

export const addTempDeliveryDate = (status, date) => {
	return (dispatch) => {
		dispatch({
			type: jobConstants.ADD_TEMP_DELIVERY_DATE,
			data: { status, date },
		});
	};
};

// ------------------------------Export estimations----------------------------

export const updateTempExportEstimation = (id = null, value) => {
	return (dispatch) => {
		dispatch({
			type: jobConstants.UPDATE_TEMP_EXPORT_ESTIMATIONS,
			data: { id, value },
		});
	};
};

export const resetTempExportEstimation = () => {
	return (dispatch) => {
		dispatch({ type: jobConstants.RESET_TEMP_EXPORT_ESTIMATION });
	};
};

// ----------------------------------------------------------------------

// ------------------------------Service estimations----------------------------

export const updateTempServiceEstimation = (id, value) => {
	return (dispatch) => {
		dispatch({
			type: jobConstants.UPDATE_TEMP_SERVICE_ESTIMATIONS,
			data: { id, value },
		});
	};
};

export const resetTempServiceEstimation = () => {
	return (dispatch) => {
		dispatch({ type: jobConstants.RESET_TEMP_SERVICE_ESTIMATION });
	};
};
// ----------------------------------------------------------------------

// ------------------------------Reset state----------------------------

export const resetJobExports = () => {
	return (dispatch) => {
		dispatch({ type: jobConstants.RESET_JOB_EXPORTS });
	};
};

// ----------------------------------------------------------------------

/**
 * Update file URL
 * @param {*} data
 * @returns
 */
export const changeJobFileViewerUrl = (data) => {
	return { type: jobConstants.CHANGE_FILE_URL, data };
};

export const setJobSuccessMessage = (data) => {
	return { type: jobConstants.SET_SUCCESS_MESSAGE, data };
};

export const jobActionSuccess = (message = null) => {
	return { type: jobConstants.JOB_ACTION_SUCCESS, message };
};

export const jobActionFailure = (error) => {
	return { type: jobConstants.JOB_ACTION_FAILURE, error };
};

export const clearJobRequestState = () => {
	return { type: jobConstants.CLEAR_JOB_REQUEST_STATE };
};
