import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form/immutable";
import localize, { LocKeys } from "../../../../constants/localizations";
import BuildingLevels from "../../../buildings/crud/steps/BuildingLevels";
import { getDisciplines, updateCreateOrderLevels } from "../../../../actions";

/**
 * Step 2 - Create offer (BUILDING CONFIGURATION)
 * @param {*} props
 * @returns
 */
const BuildingConfiguration = (props) => {
	const {
		disciplines,
		nextPage,
		cancelButtonAction,
		previousPage,
		updateCreateOrderLevels,
		getDisciplines,
	} = props;

	useEffect(() => {
		getDisciplines({});
	}, [getDisciplines]);

	const onSubmit = useCallback(
		(levels) => {
			const data = [];
			disciplines &&
				disciplines.map((discipline) => {
					return data.push({
						disciplineId: discipline.get("id"),
						levels,
					});
				});

			updateCreateOrderLevels(data, () => {
				nextPage && nextPage();
			});
		},
		[disciplines, updateCreateOrderLevels, nextPage]
	);
	return (
		<BuildingLevels
			nextPage={onSubmit}
			formName="createOrderForm"
			previousPage={previousPage}
			isNaviate={true}
			rightButtonLabel={localize(LocKeys.NEXT)}
			rightButtonClasses="btn--primary"
			cancelButtonAction={() => cancelButtonAction()}
		/>
	);
};

const mapStateToProps = (state, ownProps) => {
	return {
		form: ownProps.formName,
		disciplines: state.getIn(["administration", "disciplines"]),
	};
};

export default connect(mapStateToProps, {
	updateCreateOrderLevels,
	getDisciplines,
})(
	reduxForm({
		touchOnBlur: false,
		destroyOnUnmount: false,
	})(BuildingConfiguration)
);
