import React from "react";
import { useParams } from "react-router-dom-v5-compat";
import Viewer from "../Viewer";

const ShareModel = () => {
	const { urn } = useParams();

	return (
		<div className="share-view">
			<Viewer shareModelUrn={urn} />
		</div>
	);
};

export default ShareModel;
