import React, { useEffect } from "react";
import HeaderLayout from "../../../layout/content/HeaderLayout";
import { tokenParams } from "../../../../utils/local-storage.util";
import { connect } from "react-redux";
import {
	getDeliveryFormat,
	getLodSpecifications,
	getPrice,
	getRevitTemplates,
	removeBuildingTotalPrice,
	setInitNumberOfBuildings,
} from "../../../../actions";

const HeaderCreateOrder = ({ title, setPriceLoader, ...props }) => {
	const {
		init,
		stopPricing,
		customer,
		resellerCompanyId,
		lodSpecification,
		configurationSpecification,
		buildingDisciplineComponentCategoryLods,
		tempBuildings,
		tempBuildingId,
		//Actions
		getDeliveryFormat,
		getLodSpecifications,
		getPrice,
		getRevitTemplates,
		removeBuildingTotalPrice,
		setInitNumberOfBuildings,
	} = props;
	const shouldEstimatePrice = tokenParams().company.shouldEstimatePrice;

	useEffect(() => {
		getRevitTemplates({
			usePagination: false,
			customer,
			resellerCompanyId,
		});
	}, [getRevitTemplates, resellerCompanyId, customer]);

	useEffect(() => {
		getLodSpecifications({
			usePagination: false,
			filterPublic: true,
			clearSingleLodSpecification: false,
		});
		getDeliveryFormat();
	}, [getDeliveryFormat, getLodSpecifications]);

	useEffect(() => {
		if (init) setInitNumberOfBuildings("numberOfBuildings", 1);
	}, [setInitNumberOfBuildings, init]);

	useEffect(() => {
		const controller = new AbortController();

		if (shouldEstimatePrice && !stopPricing) {
			// let buildingsValidity = getBuildingsValidity();
			// let buildingValid = false;
			// if (buildingsValidity.length > 0) {
			// 	const isValid = buildingsValidity.some((key) => key === true);

			// 	buildingValid = !isValid;
			// }

			if (!!lodSpecification) {
				setPriceLoader({ loader: true, buildingId: tempBuildingId });

				const isDraft =
					`${configurationSpecification}-draft` === lodSpecification;
				getPrice({
					data: {
						buildings: tempBuildings,
						lodSpecification: isDraft ? null : lodSpecification,
						buildingDisciplineComponentCategoryLods: isDraft
							? buildingDisciplineComponentCategoryLods
							: null,
					},
					controller,
					cb: () => setPriceLoader({ loader: false, buildingId: null }),
				});
			} else {
				removeBuildingTotalPrice();
			}
		}

		return () => {
			setPriceLoader({ loader: false, buildingId: null });
			controller.abort();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		buildingDisciplineComponentCategoryLods,
		//getPrice,
		lodSpecification,
		configurationSpecification,
		shouldEstimatePrice,
		removeBuildingTotalPrice,
		tempBuildings,
		stopPricing,
	]);

	return (
		<HeaderLayout wrapperClasses="px-24 d-flex align-items-center">
			<h3>{title}</h3>
		</HeaderLayout>
	);
};

const mapStateToProps = (state) => {
	return {
		//
		init: state.getIn(["projectTemp", "init"]),
		tempBuildings: state.getIn(["projectTemp", "buildings"]),
		tempBuildingId: state.getIn(["projectTemp", "tempBuildingId"]),
		stopPricing: state.getIn(["projectTemp", "stopPricing"]),
		lodSpecification: state.getIn(["projectTemp", "specification"]),
		configurationSpecification: state.getIn([
			"projectTemp",
			"configurationSpecification",
		]),
		buildingDisciplineComponentCategoryLods: state.getIn([
			"projectTemp",
			"buildingDisciplineComponentCategoryLods",
		]),
		customer: state.getIn(["global", "customer"]),
		resellerCompanyId: state.getIn(["global", "reseller"]),
	};
};

export default connect(mapStateToProps, {
	getDeliveryFormat,
	getLodSpecifications,
	getPrice,
	getRevitTemplates,
	removeBuildingTotalPrice,
	setInitNumberOfBuildings,
})(HeaderCreateOrder);
