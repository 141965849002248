import React, { useEffect, useState } from "react";
import renderError from "./renderError";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import clsx from "clsx";
import CustomLabel from "./CustomLabel";
import { MenuItem, ListSubheader } from "@mui/material";
import { ExpandMoreSharp } from "@mui/icons-material";
import localize, { LocKeys } from "../../constants/localizations";
import { colorConstants } from "../../constants/constants";

/**
 * Component for select field
 */

export default ({
	input,
	label = "",
	labelInfoContent = "",
	labelNote = "",
	required = true,
	meta: { touched, error, value },
	options,
	size = "w-100",
	classField,
	initialValue = "",
	valueClasses = "",
	customError = false,
	customErrorLabel,
	customErrorMessagePositionClass = "",
	placeholder = "",
	showPlaceholder = false,
	customOnChange,
	openDropdown = false,
	customHandleClose,
	disabled = false,
	type = false,
	buildingList = false,
	listSubHeader = false,
	...custom
}) => {
	const [val, setVal] = useState(
		options.size > 0 || options.length > 0 ? initialValue : ""
	);
	const [open, setOpen] = useState(openDropdown);

	useEffect(() => {
		if (
			(val === "" && initialValue) ||
			(initialValue && val !== initialValue && buildingList)
		) {
			let find = null;
			if (listSubHeader) {
				find =
					options &&
					options.find((option) => {
						const findSubOption = option?.subOptions.find(
							(subOption) => subOption.value === initialValue
						);

						if (findSubOption) {
							return option;
						}

						return null;
					});
			} else {
				find = options.find((option) => option.value === initialValue);
			}

			if (!!find) {
				setVal(options.size > 0 || options.length > 0 ? initialValue : "");
			}
		}

		if (initialValue === "" && val !== "") {
			handleChange("");
		}

		if (val !== "" && !listSubHeader) {
			if (options.findIndex((option) => option.value === val) === -1) {
				handleChange("");
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialValue, label, options, val, listSubHeader, buildingList]);

	useEffect(() => {
		setOpen(openDropdown);
	}, [openDropdown]);

	let errorExist = false;
	if ((touched && error) || customError) {
		errorExist = true;
	}

	const handleChange = (newValue) => {
		setVal(newValue);
		customOnChange && customOnChange(newValue);
	};

	const handleClose = (e) => {
		e.stopPropagation();
		setOpen(false);
		customHandleClose && customHandleClose();
	};

	const handleOpen = () => {
		setOpen(true);
	};

	let children = [];

	if (!options || options.length === 0) {
		children = [
			<MenuItem
				key={"no-options"}
				classes={{
					root: "dropdown-item",
					selected: "dropdown-item--selected",
				}}
				value={null}
				disabled
			>
				{localize(LocKeys.NO_OPTIONS_AVAILABLE)}
			</MenuItem>,
		];
	} else {
		if (listSubHeader) {
			children = options.map((option) => {
				const tempChildred = [
					<ListSubheader
						disableGutters={true}
						classes={{
							root: "dropdown-subheader body-font--small semi",
						}}
					>
						{option.label}
					</ListSubheader>,
				];

				const subOptions = option.subOptions.map((subOption) => {
					return (
						<MenuItem
							key={subOption.value}
							classes={{
								root: "dropdown-item dropdown-item--sub",
								selected: "dropdown-item--selected",
							}}
							value={subOption.value}
						>
							{subOption.label}
						</MenuItem>
					);
				});

				return tempChildred.concat(subOptions);
			});
		} else {
			children = options.map((option) => {
				return (
					<MenuItem
						key={option.value}
						classes={{
							root: type
								? "dropdown-item dropdown-item--group"
								: " dropdown-item",
							selected: "dropdown-item--selected",
						}}
						value={option.value}
					>
						{option.label}
					</MenuItem>
				);
			});
		}

		if (!required) {
			children = [
				<MenuItem
					key={"clear-selection"}
					classes={{
						root: "dropdown-item dropdown-item--clear",
						selected: "dropdown-item--selected--clear",
					}}
					value={""}
				>
					{localize(LocKeys.CLEAR_SELECTION)}
				</MenuItem>,
			].concat(children);
		}
	}

	const renderValue = (value) => {
		if (!value || !options) {
			return showPlaceholder && placeholder ? placeholder : "";
		} else {
			let selectedOption = null;
			if (listSubHeader) {
				selectedOption = options
					.find((option) =>
						option.subOptions.find((subOption) => subOption.value === value)
					)
					.subOptions.find((item) => item.value === value);
			} else {
				selectedOption = options.find((option) => option.value === value);
			}

			if (selectedOption) {
				return selectedOption.label;
			} else {
				return showPlaceholder && placeholder ? placeholder : "";
			}
		}
	};

	return (
		<FormControl
			variant="standard"
			className={clsx("form__input form__input--select", size, classField)}
		>
			{label && (
				<CustomLabel
					label={label}
					required={required}
					infoContent={labelInfoContent}
					note={labelNote}
				/>
			)}

			<Select
				disabled={disabled}
				displayEmpty={true}
				classes={{
					root: `field__root
					${disabled ? "field__root--disabled" : ""}
            ${value || val ? "field__root--complete" : ""}
            ${errorExist ? "field__root--error" : ""}
			${valueClasses}`,
					select: "field__select",
					icon: "field__select__icon",
				}}
				error={errorExist}
				{...input}
				children={children ? children : null}
				{...custom}
				value={val}
				onChange={(event) => {
					event.stopPropagation();
					handleChange(event.target.value);
				}}
				open={open}
				onClose={(event) => handleClose(event)}
				onOpen={handleOpen}
				renderValue={(value) => renderValue(value)}
				native={false}
				IconComponent={(props) => (
					<ExpandMoreSharp style={{ color: colorConstants.N300 }} {...props} />
				)}
				MenuProps={{
					classes: {
						list: "dropdown-list",
					},
					// PaperProps: {
					// 	sx: {
					// 		transform: "scale(0.8) !important",
					// 	},
					// },
					disableScrollLock: true,
				}}
			/>

			{renderError(error, touched, customError, customErrorLabel)}
		</FormControl>
	);
};
