import React, { useState, useMemo } from "react";
import { connect } from "react-redux";
import {
	Field,
	reduxForm,
	change,
	formValueSelector,
} from "redux-form/immutable";
import localize, { LocKeys } from "../../../../constants/localizations";
import renderTextField from "../../../fields/renderTextField";
import validate from "../../../fields/validators/offer/createOfferValidator";
import { setStateTempFieldValue } from "../../../../actions";
import CustomStepNavigation from "../../../../shared/components/CustomStepper/CustomStepNavigation";
import BodyLayout from "../../../layout/content/BodyLayout";
import FooterLayout from "../../../layout/content/FooterLayout";
import IconWrapper from "../../../../shared/icons/IconWrapper";
import BuildingInformation, {
	customRenderLodSpecification,
} from "../parts/BuildingInformation";
import CustomAutocomplete from "../../../fields/CustomAutocomplete";
import { getDeliveryFormatIcon } from "../../../../utils/getTypeIcon";
import HeaderCreateOrder from "../parts/HeaderCreateOrder";
import { renderPrice } from "../../single-view/view/SpecificationView";

/**
 * Step 1 - Create offer - Offer information (Name, Revit version, Buildings list)
 * @param {*} props
 * @returns
 */
const OrderInformation = (props) => {
	const {
		renderTotalPrice = null,
		isNaviate = false,
		lodSpecifications,
		configurationSpecification,
		isActiveConfiguration,
		numberOfBuildings,
		deliveryFormats,
		customer,
		initialValues,
		fieldsValidity,
		formName,
		buildingFieldsValidity,
		tempBuildings,
		revitTemplateList,
		//Actions
		setStateTempFieldValue,
		cancelButtonAction,
	} = props;
	const [priceLoader, setPriceLoader] = useState({
		loader: false,
		buildingId: null,
	});

	const fields = useMemo(
		() => [
			"name",
			"numberOfBuildings",
			"deliveryFormat",
			"specification",
			"template",
		],
		[]
	);

	const buildingFields = useMemo(
		() => ["size", "inputFormat", "type", "levelsValidity"],
		[]
	);

	const returnBuildingsValidity = useMemo(() => {
		let buildingsValidity = [];
		buildingFieldsValidity &&
			buildingFieldsValidity.forEach((building) => {
				const validity = building.validity;

				let hasInvalidValueBuildings = Object.keys(validity).some(
					(key) => buildingFields.includes(key) && !validity[key]
				);

				buildingsValidity.push(hasInvalidValueBuildings);
			});

		return buildingsValidity;
	}, [buildingFieldsValidity, buildingFields]);

	const isValid = useMemo(() => {
		let hasInvalidValue = Object.keys(fieldsValidity.toJS()).some(
			(key) => fields.includes(key) && !fieldsValidity.get(key)
		);

		let buildingsValidity = returnBuildingsValidity;

		if (buildingsValidity.length > 0 && !hasInvalidValue) {
			const isValidTemp = buildingsValidity.some((key) => key === true);

			hasInvalidValue = isValidTemp;
		}

		if (!customer) {
			return false && !hasInvalidValue;
		} else {
			return true && !hasInvalidValue;
		}
	}, [fields, customer, fieldsValidity, returnBuildingsValidity]);

	const onSubmit = () => {
		try {
			if (!isValid || priceLoader.loader) return;

			props.nextPage();
		} catch (error) {
			return;
		}
	};

	const setValue = (fieldKey, value) => {
		setStateTempFieldValue(fieldKey, value);
	};

	const updateFormValue = (field, value) => {
		if (!field || (!value && value !== 0)) {
			return;
		}

		setValue(field, value);
		props.dispatch(change(formName, field, value));
	};

	let deliveryFormatOptions = [];
	if (deliveryFormats && deliveryFormats.size > 0) {
		deliveryFormats.map((format, i) => {
			let subHeader = (
				<div
					className={`dropdown-subheader body-font--small  semi  ${+i === 0 ? "" : "dropdown-subheader--border"}`}
				>
					<div className="d-flex align-items-center w-100">
						<div>
							<IconWrapper
								size="24"
								wrapperClasses="mr-s"
								icon={getDeliveryFormatIcon(format.get("type"))}
							/>
						</div>
						<div className="text-overflow-ellipsis">{format.get("type")}</div>
					</div>
				</div>
			);

			const children = format?.get("children");

			children &&
				children.map((item) => {
					deliveryFormatOptions.push({
						subHeader,
						label: item.get("type"),
						value: item.get("id"),
					});

					return deliveryFormatOptions;
				});

			return deliveryFormatOptions;
		});
	}

	const lodSpecificationOptions = useMemo(() => {
		let options = [];
		if (lodSpecifications && lodSpecifications.size > 0) {
			lodSpecifications.map((item) => {
				if (
					Boolean(isActiveConfiguration) &&
					+item.get("id") === +configurationSpecification
				) {
					options.push({
						value: `${item.get("id")}-draft`,
						label: `${item.get("name")} (Draft)`,
					});
				}
				return options.push({
					value: item.get("id"),
					label: item.get("name"),
				});
			});
		}
		return options;
	}, [isActiveConfiguration, configurationSpecification, lodSpecifications]);

	const templateOptions = useMemo(() => {
		const options = [];

		revitTemplateList &&
			revitTemplateList.map((template) => {
				return options.push({
					label: template.get("name"),
					value: template.get("id"),
				});
			});

		return options;
	}, [revitTemplateList]);

	const templateDisabled = useMemo(
		() => !initialValues.get("deliveryFormat") || !customer,
		[initialValues, customer]
	);

	const renderHeader = useMemo(() => {
		return (
			<HeaderCreateOrder
				title={localize(LocKeys.OFFER_INFO)}
				setPriceLoader={setPriceLoader}
			/>
		);
	}, [setPriceLoader]);

	const renderBuildingInformation = useMemo(() => {
		return (
			+numberOfBuildings > 0 && (
				<BuildingInformation
					isNaviate={isNaviate}
					formName={formName}
					priceLoader={priceLoader}
					initialValues={initialValues}
					tempBuildings={tempBuildings}
				/>
			)
		);
	}, [
		tempBuildings,
		initialValues,
		priceLoader,
		formName,
		numberOfBuildings,
		isNaviate,
	]);

	return (
		<form
			className={`form d-flex flex-column flex-auto standardized-form	`}
			onSubmit={props.handleSubmit(onSubmit)}
			autoComplete="off"
			noValidate
		>
			{renderHeader}

			<BodyLayout wrapperClasses="p-24">
				<div className="d-flex">
					{!isNaviate && (
						<div className="w-auto">
							<Field
								name="numberOfBuildings"
								id="numberOfBuildings"
								classField="mb-24 mr-10"
								component={renderTextField}
								label={localize(LocKeys.NUMBER_OF_BUILDINGS)}
								inputMode="numeric"
								required={true}
								onKeyDown={(value) => setValue("numberOfBuildings", value)}
								numberAdornment={true}
								customUpdateValue={updateFormValue}
								min="1"
							/>
						</div>
					)}

					<div className="row row--6p-gutters">
						<div
							className={`col col-100 ${!isNaviate ? "col-desktop-25" : ""}`}
						>
							<Field
								name="name"
								id="name"
								classField="mb-24"
								label={localize(LocKeys.OFFER_NAME)}
								placeholder={localize(LocKeys.ENTER_OFFER_NAME) + "..."}
								required={true}
								onKeyDown={(value) => setValue("name", value)}
								component={renderTextField}
								min="0"
								type="text"
								showPlaceholder={true}
							/>
						</div>

						<div
							className={`col col-100 ${!isNaviate ? "col-desktop-25" : "col-tablet-33 col-mobile-100"}`}
						>
							<Field
								name="specification"
								disableClearable={true}
								component={CustomAutocomplete}
								dropdownHeight="400px"
								customRenderOption={(props, option) =>
									customRenderLodSpecification(props, option)
								}
								label={localize(LocKeys.LOD_SPECIFICATION)}
								placeholder={localize(LocKeys.SELECT_LOD_SPECIFICATION) + "..."}
								showPlaceholder={true}
								isKeyStrigify={true}
								options={lodSpecificationOptions}
								initialValue={initialValues?.get(`specification`) || null}
								customOnChange={(value) => setValue(`specification`, value)}
								required={true}
							/>
						</div>
						<div
							className={`col col-100 ${!isNaviate ? "col-desktop-25" : "col-tablet-33 col-mobile-100"}`}
						>
							<Field
								name="deliveryFormat"
								id="deliveryFormat"
								classField="mb-24"
								dropdownHeight="400px"
								disableClearable={true}
								groupBy={true}
								label={localize(LocKeys.DELIVERY_FORMAT)}
								placeholder={localize(LocKeys.SELECT_DELIVERY_FORMAT) + "..."}
								required={true}
								showPlaceholder={true}
								customOnChange={(value) => {
									setValue("deliveryFormat", value);
								}}
								component={CustomAutocomplete}
								options={deliveryFormatOptions}
								initialValue={initialValues.get("deliveryFormat") || null}
							/>
						</div>
						<div
							className={`col col-100 ${!isNaviate ? "col-desktop-25" : "col-tablet-33 col-mobile-100"}`}
						>
							<Field
								name="template"
								id="template"
								classField="mb-24"
								dropdownHeight="400px"
								disabled={templateDisabled}
								label={localize(LocKeys.TEMPLATE_LABEL)}
								placeholder={localize(LocKeys.SELECT_TEMPLATE) + "..."}
								required={true}
								showPlaceholder={true}
								customOnChange={(value) => {
									setValue("template", value);
								}}
								component={CustomAutocomplete}
								disableClearable={true}
								options={templateOptions}
								initialValue={initialValues.get("template") || null}
							/>
						</div>
					</div>
				</div>

				{renderBuildingInformation}
			</BodyLayout>

			<FooterLayout wrapperClasses="px-24">
				<div className="row row--6p-gutters d-flex align-items-center">
					{isNaviate && (
						<>
							<div className={`col col-33`}>
								<h4 className="d-flex align-items-center">
									<span className="color-n300">{localize(LocKeys.TOTAL)}:</span>
									&nbsp;
									<span className="color-n500">
										{priceLoader.loader ? (
											<span className="loading-icon"></span>
										) : (
											renderPrice(renderTotalPrice, "color-n500")
										)}
									</span>
								</h4>
							</div>
							<div className={`col col-33 color-n500`}>
								<h4 className="d-flex align-items-center">
									<span className="color-n300">
										{localize(LocKeys.DELIVERY_DAYS)}:
									</span>
									&nbsp;
									<span className="color-n500">2-5 weekdays</span>
								</h4>
							</div>
						</>
					)}

					<div className={`col ${isNaviate ? "col-33" : "col-100"} color-n500`}>
						<CustomStepNavigation
							leftButtonLabel={localize(LocKeys.CANCEL)}
							leftButtonClasses="btn--secondary-error"
							rightButtonClasses="btn--primary"
							leftButtonAction={() => cancelButtonAction()}
							rightButtonLoading={priceLoader.loader}
							rightButtonDisabled={!isValid || priceLoader.loader}
						/>
					</div>
				</div>
			</FooterLayout>
		</form>
	);
};

const mapStateToProps = (state, ownProps) => {
	const selector = formValueSelector(ownProps.formName);
	const tempBuildings = state.getIn(["projectTemp", "buildings"]);

	let initialValues = {
		name: state.getIn(["projectTemp", "name"]),
		numberOfBuildings:
			selector(state, "numberOfBuildings") || tempBuildings?.length,

		deliveryFormat: state.getIn(["projectTemp", "deliveryFormat"]),
		specification: state.getIn(["projectTemp", "specification"]),
		template: state.getIn(["projectTemp", "template"]),
	};

	tempBuildings &&
		tempBuildings.forEach((building) => {
			initialValues[`name_${building.id}`] = building.name;
			initialValues[`size_${building.id}`] = building.size;
			initialValues[`price_${building.id}`] = building.price;
			// initialValues[`lodSpecification_${building.id}`] =
			// 	building.lodSpecification;
			initialValues[`input_format_${building.id}`] = building.inputFormat;
			initialValues[`type_${building.id}`] = building.type;
			initialValues[`levels_${building.id}`] = building.levels;
			initialValues[`uniqueLevels_${building.id}`] = building.uniqueLevels;
		});

	return {
		form: ownProps.formName,
		//init: state.getIn(["projectTemp", "init"]),
		//	lodSpecification: state.getIn(["projectTemp", "specification"]),
		deliveryFormats: state.getIn(["administration", "deliveryFormats"]),
		numberOfBuildings: selector(state, "numberOfBuildings") || 1,
		customer: state.getIn(["global", "customer"]),
		projectTemp: state.get("projectTemp"),
		fieldsValidity: state.getIn(["projectTemp", "validity"]),
		buildingFieldsValidity: state.getIn(["projectTemp", "buildings"]),
		//buildingPrice: state.getIn(["price", "buildingPrice"]),
		initialValues,
		tempBuildings: state.getIn(["projectTemp", "buildings"]),
		//tempBuildingId: state.getIn(["projectTemp", "tempBuildingId"]),
		lodSpecifications: state.getIn([
			"lodSpecification",
			"lodSpecificationList",
		]),
		//stopPricing: state.getIn(["projectTemp", "stopPricing"]),
		revitTemplateList: state.getIn(["template", "revitTemplateList"]),
		configurationSpecification: state.getIn([
			"projectTemp",
			"configurationSpecification",
		]),
		// buildingDisciplineComponentCategoryLods: state.getIn([
		// 	"projectTemp",
		// 	"buildingDisciplineComponentCategoryLods",
		// ]),
		renderTotalPrice: state.getIn([
			"price",
			"buildingPrice",
			"total",
			"modelingPrice",
		]),
		enableReinitialize: ownProps?.isNaviate ? false : true,
	};
};

export default connect(mapStateToProps, {
	setStateTempFieldValue,
})(
	reduxForm({
		validate,
		touchOnBlur: false,
		destroyOnUnmount: false,
	})(OrderInformation)
);
