import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form/immutable";
import { clearResetPassword, resetPassword } from "../../actions";
import renderTextField from "../fields/renderTextField";
import validate from "../fields/validateAuth";
import SetPassword from "./SetPassword";
import getURLParams from "../../utils/getURLParams";
import localize, { LocKeys } from "../../constants/localizations";
import history from "../../history";
import ArrowLeft from "../../shared/icons/ArrowLeft";
import Container from "./parts/Container";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";

/**
 * Reset password Component
 * There are 3 parts of the screen:
 * 1. When user needs to enter email address and press button for reseting password
 * 2. When user press button and message is displayed
 * 3. When user entering new password
 */

const ResetPassword = (props) => {
	const params = getURLParams();
	const navigate = useNavigate();
	const location = useLocation();
	const isNaviate = location?.state?.isNaviate || false;

	const urlEmail = params.get("email");
	const { resetPasswordSuccess, resetPasswordEmail, clearResetPassword } =
		props;

	const onSubmitReset = (formValues) => {
		if (formValues)
			try {
				let formValuesJS = formValues.toJS();

				let data = { ...formValuesJS, email: formValuesJS.email.toLowerCase() };
				return props.resetPassword(data);
			} catch (error) {
				console.log({ error });
				return;
			}
	};

	const backToLogin = () => {
		navigate(isNaviate ? "/naviate-login" : "/login");
		clearResetPassword();
	};

	const renderContent = () => {
		if (!resetPasswordSuccess && urlEmail === null) {
			return (
				<Container
					isNaviate={isNaviate}
					pageTitle={localize(LocKeys.PASSWORD_RESET)}
					pageSubTitle={localize(LocKeys.PASSWORD_RESET_INFO)}
				>
					<button
						type="button"
						className="btn--stripped return-link d-flex flex-wrap align-items-center"
						onClick={() => navigate(isNaviate ? "/naviate-login" : "/login")}
					>
						<ArrowLeft iconClass="mr-10 d-flex" />
						<h6 className="text--uppercase">
							{localize(LocKeys.BACK_TO_LOGIN)}
						</h6>
					</button>

					<form
						className={"form d-flex flex-auto standardized-form   flex-column "}
						onSubmit={props.handleSubmit(onSubmitReset)}
						autoComplete="off"
						noValidate
					>
						<Field
							name="email"
							id="email"
							component={renderTextField}
							type="email"
							placeholder={localize(LocKeys.EMAIL)}
							size="w-100"
							label={localize(LocKeys.ENTER_EMAIL)}
						/>

						<button
							type="submit"
							className="btn mt-40 btn--primary"
							disabled={props.invalid || props.submitting}
						>
							{localize(LocKeys.SEND_RESET_LINK)}
						</button>
					</form>
				</Container>
			);
		} else if (resetPasswordSuccess && urlEmail === null) {
			return (
				<Container
					isNaviate={isNaviate}
					pageTitle={localize(LocKeys.PASSWORD_RESET)}
					pageSubTitle={localize(LocKeys.RESET_LINK_SENT)}
					resetEmail={resetPasswordEmail}
				>
					<button
						type="button"
						className="btn--stripped return-link d-flex flex-wrap align-items-center"
						onClick={() => backToLogin()}
					>
						<ArrowLeft iconClass="mr-10 d-flex" />
						<h6 className="text--uppercase">
							{localize(LocKeys.BACK_TO_LOGIN)}
						</h6>
					</button>
				</Container>
			);
		} else {
			return (
				<SetPassword
					hidePolicy={true}
					pageTitle={localize(LocKeys.CHOOSE_NEW_PASSWORD)}
					pageSubTitle={localize(LocKeys.FILL_TO_LOGIN)}
				/>
			);
		}
	};
	return renderContent();
};

const mapStateToProps = (state) => {
	const email = history?.location?.state?.email || null;

	return {
		initialValues: { email },
		resetPasswordEmail: state.getIn(["authentication", "resetPasswordEmail"]),
		resetPasswordSuccess: state.getIn([
			"authentication",
			"resetPasswordSuccess",
		]),
	};
};
export default connect(mapStateToProps, { resetPassword, clearResetPassword })(
	reduxForm({ form: "resetPasswordForm", enableReinitialize: true, validate })(
		ResetPassword
	)
);
