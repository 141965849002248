import React, { useEffect } from "react";
import { connect } from "react-redux";
import { change, Field, reduxForm } from "redux-form/immutable";
import { updateBuildingLevel } from "../../../../../actions";
import { adornmentTypeConstants } from "../../../../../constants/constants";
import localize, { LocKeys } from "../../../../../constants/localizations";
import renderTextField from "../../../../fields/renderTextField";

/**
 * Single building floor - view & edit (level name, elevation)
 * @param {*} props
 * @returns
 */
const BuildingFloor = (props) => {
	const {
		isSecondFloor,
		floor,
		isLowestFloor,
		isHighestFloor,
		formName,
		dispatch,
		updateBuildingLevel,
	} = props;

	useEffect(() => {
		dispatch(change(formName, `elevation-${floor.key}`, floor.elevation));
		dispatch(change(formName, `thickness-${floor.key}`, floor?.thickness || 0));
		dispatch(change(formName, `floor-${floor.level}`, floor.name));
	}, [
		dispatch,
		floor.key,
		floor.elevation,
		floor.level,
		floor.thickness,
		floor.name,
		formName,
	]);

	let fieldKey = `floor-${floor.level}`;

	let upperFloor = floor.level >= 0 || floor.level === "Roof";

	let floorBg = "bg-n000";

	if (floor.level === 0) {
		floorBg = "bg-n100";
	}

	if (floor.level < 0) {
		floorBg = "bg-n200";
	}

	const setValue = (floorKey, stateKey, value) => {
		updateBuildingLevel(floorKey, stateKey, value);
	};

	return (
		<div className="row row--m-gutters">
			<div className="col col-60 d-flex">
				{isHighestFloor ? (
					<div className={`roof-edges`}>
						<div className={`roof-bg ${floorBg}`}></div>
					</div>
				) : (
					<div
						className={`
					floor-bg w-100
					${
						upperFloor
							? isSecondFloor
								? "floor-bg--roof floor-bg--upper"
								: "floor-bg--upper"
							: "floor-bg--lower"
					}
					${floorBg}
					${!upperFloor && floor.level === -1 ? "bt-n300" : ""}
					${!upperFloor && isLowestFloor ? "bb-n500" : ""}
				`}
					>
						{upperFloor && (
							<div className="upper-floor-additional-border"></div>
						)}
						<Field
							name={fieldKey}
							id={fieldKey}
							component={renderTextField}
							placeholder={localize(LocKeys.ENTER_LEVEL_NAME)}
							showPlaceholder={true}
							size="w-100"
							onKeyDown={(value) => setValue(floor.key, "name", value)}
						/>
					</div>
				)}
			</div>
			<div className={`col col-40 d-flex align-items-end`}>
				{isHighestFloor ? (
					<div className="floor-elevation__wrapper pb-xs  row row--no-gutters">
						<div className="col col-20 align-items-end pl-0 pr-0 text-capitalize">
							{localize(LocKeys.ROOF)}
						</div>

						<div className="col col-40 pr-0">
							<div
								className={`d-flex flex-column w-100 ${
									!floor.valid ? "pt-xs" : ""
								}`}
							>
								<Field
									name={`elevation-${floor.key}`}
									id={`elevation-${floor.key}`}
									component={renderTextField}
									min="0"
									type="number"
									adornmentType={adornmentTypeConstants.MILIMETERS}
									onKeyDown={(value) =>
										setValue("floor__roof", "elevation", value)
									}
									customError={!floor.valid}
									customErrorLabel={localize(LocKeys.ELAVATION_ERROR_MESSAGE)}
								/>
							</div>
						</div>
						<div className="col col-40 pr-0 pl-m">
							<div
								className={`d-flex flex-column w-100 ${
									!floor.valid ? "pt-xs" : ""
								}`}
							>
								<Field
									name={`thickness-${floor.key}`}
									id={`thickness-${floor.key}`}
									component={renderTextField}
									min="0"
									type="number"
									adornmentType={adornmentTypeConstants.MILIMETERS}
									onKeyDown={(value) =>
										setValue("floor__roof", "thickness", value)
									}
									// customError={!floor.valid}
									// customErrorLabel={localize(LocKeys.ELAVATION_ERROR_MESSAGE)}
								/>
							</div>
						</div>
					</div>
				) : (
					<div
						className={`
					floor-elevation__wrapper
					${floor.level === 0 ? "floor-elevation__wrapper--no-border" : ""}
					pb-xs row row--no-gutters`}
					>
						<div className="col col-20 align-items-end pl-0 pr-0 text-capitalize">
							{floor.level}
						</div>
						<div className="col col-40 pr-0">
							<div
								className={`d-flex w-100 flex-column ${
									!floor.valid ? "pt-xs" : ""
								}`}
							>
								<Field
									name={`elevation-${floor.key}`}
									id={`elevation-${floor.key}`}
									component={renderTextField}
									min="0"
									type="number"
									adornmentType={adornmentTypeConstants.MILIMETERS}
									onKeyDown={(value) => setValue(floor.key, "elevation", value)}
									customError={!floor.valid}
									customErrorLabel={localize(LocKeys.ELAVATION_ERROR_MESSAGE)}
								/>
							</div>
						</div>
						<div className="col col-40 pr-0 pl-m">
							<div
								className={`d-flex w-100 flex-column ${
									!floor.valid ? "pt-xs" : ""
								}`}
							>
								<Field
									name={`thickness-${floor.key}`}
									id={`thickness-${floor.key}`}
									component={renderTextField}
									min="0"
									type="number"
									adornmentType={adornmentTypeConstants.MILIMETERS}
									onKeyDown={(value) => setValue(floor.key, "thickness", value)}
									// customError={!floor.valid}
									// customErrorLabel={localize(LocKeys.ELAVATION_ERROR_MESSAGE)}
								/>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	return { form: ownProps.formName };
};

export default connect(mapStateToProps, {
	updateBuildingLevel,
})(
	reduxForm({
		touchOnBlur: false,
		destroyOnUnmount: false,
	})(BuildingFloor)
);
