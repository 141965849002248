import React, { useCallback, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import {
	getForgePublicToken,
	getModelTranslationStatus,
	updateModelThumbnail,
} from "../../../../../actions";
import { alertTypeConstants } from "../../../../../constants/constants";
import localize, { LocKeys } from "../../../../../constants/localizations";

// const { Autodesk } = window;

// const runtime = {
// 	options: null,
// 	ready: null,
// };

// function initializeViewerRuntime(options) {
// 	if (!runtime.ready) {
// 		runtime.options = { ...options };

// 		runtime.ready = new Promise((resolve) =>
// 			Autodesk.Viewing.Initializer(runtime.options, resolve)
// 		);
// 	} else {
// 		if (
// 			["accessToken", "getAccessToken", "env", "api", "language"].some(
// 				(prop) => options[prop] !== runtime.options[prop]
// 			)
// 		) {
// 			return Promise.reject(
// 				"Cannot initialize another viewer runtime with different settings."
// 			);
// 		}
// 	}
// 	return runtime.ready;
// }

/**
 * FORGE viewer - AUTODESK FORGE
 * @param {*} props
 * @returns
 */
const RevitForgeViewer = (props) => {
	const {
		enableResize = false,
		forgeAuth,
		viewerUrn,
		//actions
		getForgePublicToken,
		getModelTranslationStatus,
	} = props;

	//const forgeAuth = props.forgeAuth && props.forgeAuth;
	let Autodesk = window.Autodesk;
	const myViewer = React.useRef();
	const modelData = React.useRef({});
	const viewer = React.useRef();
	// let options = {
	// 	env: "AutodeskProduction",
	// 	api: "derivativeV2_EU", // TODO: for models uploaded to EMEA change this option to 'derivativeV2_EU'
	// 	accessToken: getForgeToken,
	// };

	const options = useMemo(() => {
		if (forgeAuth?.access_token)
			return {
				env: "AutodeskProduction",
				api: "derivativeV2_EU", // TODO: for models uploaded to EMEA change this option to 'derivativeV2_EU'
				accessToken: forgeAuth.access_token,
			};

		return null;
	}, [forgeAuth]);
	//let documentId = "urn:" + viewerUrn;

	const get3DModel = useCallback(
		(htmlElement) => {
			if (viewerUrn && forgeAuth?.access_token) {
				getModelTranslationStatus(viewerUrn, forgeAuth?.access_token)
					.then((res) => {
						if (res.status === alertTypeConstants.SUCCESS) {
							//	updateModelThumbnail(viewerUrn, buildingId, deliverableId);
							// Create and start the viewer in that element
							//  {
							// 	extensions: [
							// 		"Autodesk.DocumentBrowser",
							// 		"Autodesk.AEC.LevelsExtension",
							// 	],
							// }
							try {
								viewer.current = new Autodesk.Viewing.GuiViewer3D(htmlElement);
								viewer.current.start();
								Autodesk.Viewing.Document.load(
									"urn:" + viewerUrn,
									onDocumentLoadSuccess,
									onDocumentLoadFailure
								);
							} catch (error) {
								console.log({ error });
							}
						} else if (
							res.status === alertTypeConstants.FAILED ||
							!!res?.diagnostic
						) {
							myViewer.current.innerHTML = ` 
					<div  class="viewer--forge__message text-center">
						<p>${localize(LocKeys.FAILED_MODEL_MESSAGE)}.</p>
					</div>
				    `;
						} else {
							myViewer.current.innerHTML = ` 
					<div  class="viewer--forge__message text-center">
						<span class="loading-icon mx-auto"></span>  
						<p>${localize(LocKeys.TRANSLATING_MODEL)}.</p>
					</div>
				    `;
							setTimeout(() => {
								get3DModel(htmlElement);
							}, 5000);
						}
					})
					.catch((err) => {
						try {
							onDocumentLoadFailure(err);
						} catch (error) {
							console.log({ error });
						}
					});
			}
		},
		[
			Autodesk.Viewing.Document,
			Autodesk.Viewing.GuiViewer3D,
			forgeAuth,
			getModelTranslationStatus,
			viewerUrn,
		]
	);

	useEffect(() => {
		getForgePublicToken();
	}, [getForgePublicToken]);

	useEffect(() => {
		if (viewer.current) {
			// viewer.current.loadExtension("Autodesk.ViewCubeUi").then((res) => {
			// 	console.log("ViewCubeUi loaded");
			// 	console.log(res);
			// 	//res.setVisible(false);
			// 	res.displayViewCube(true, true);
			// });
			// console.log({ viewer: viewer.current });
			// console.log({ toolbar: viewer.current.getToolbar() });

			// const levelsExtension = viewer.current.getExtension(
			// 	"Autodesk.AEC.LevelsExtension"
			// );
			// console.log({ levelsExtension });
			// levelsExtension.filterByLevel(levels[0].name); // Filter to the first level

			//-----------------Levels Extension!!!!!!!-------------------

			// viewer.current
			// 	.loadExtension("Autodesk.AEC.LevelsExtension")
			// 	.then(function (ext) {
			// 		// Access the Levels Extension instance
			// 		const levelsExtension = ext;

			// 		console.log({
			// 			levelsExtension,
			// 			floorSelector: levelsExtension.floorSelector,
			// 		});
			// 		// Now you can use the methods of the Levels Extension
			// 		// (e.g., filterByLevel, getLevels)

			// 		// Example: Get a list of all levels
			// 		//	const levels = levelsExtension.getLevels();

			// 		// Example: Filter the model to display only objects on Level 1
			// 		//levelsExtension.filterByLevel(levels[0].name);
			// 		levelsExtension.floorSelector.selectFloor(4);
			// 	})
			// 	.catch(function (error) {
			// 		console.error("Error loading Levels Extension:", error);
			// 	});

			//-----------------Levels Extension-------------------
			viewer.current.resize();
			viewer.current.fitToView();
		}
	}, [enableResize]);

	useEffect(() => {
		if (options) {
			try {
				Autodesk.Viewing.Initializer(options || {}, function () {
					var htmlElement = myViewer.current;
					if (htmlElement) {
						get3DModel(htmlElement);
					}
				}).catch((err) => console.error(err));
			} catch (error) {
				console.log({ error });
			}
		}

		// return () => {
		// 	if (viewer.current) {
		// 		console.log("Unloading viewer...");
		// 		viewer.current.tearDown();
		// 		viewer.current.finish();
		// 		viewer.current = null;
		// 	}
		// };
	}, [viewerUrn, options, Autodesk.Viewing, get3DModel]);
	// Run this when the page is loaded

	/**
	 * Autodesk.Viewing.Document.load() success callback.
	 * Proceeds with model initialization.
	 */
	function onDocumentLoadSuccess(doc) {
		// Load the default viewable geometry into the viewer.
		// Using the doc, we have access to the root BubbleNode,
		// which references the root node of a graph that wraps each object from the Manifest JSON.

		//-----------------Read 2D sheets-------------------
		// var sheets = doc.getRoot().search(
		// 	{
		// 		type: "geometry",
		// 		role: "2d",
		// 	},
		// 	true
		// );

		// console.log({ sheets });
		//viewer.current.loadDocumentNode(doc, sheets[1], {});

		//-----------------Read 2D sheets-------------------

		var viewable = doc.getRoot().getDefaultGeometry();
		if (viewable) {
			viewer.current
				?.loadDocumentNode(doc, viewable)
				.then(function (result) {
					console.log("Viewable Loaded!");
					//	getModelProperties();
				})
				.catch(function (err) {
					console.log("Viewable failed to load.");
					console.log(err);
				});
		}
	}

	/**
	 * Autodesk.Viewing.Document.load() failure callback.
	 */
	function onDocumentLoadFailure(viewerErrorCode) {
		console.error("onDocumentLoadFailure() - errorCode: " + viewerErrorCode);
		myViewer.current.innerHTML = `<p  class="viewer--forge__message" >Translation in progress... Error!</p>`;
	}

	function getModelProperties() {
		if (!viewer.current) {
			console.error("Viewer is not initialized yet.");
			return;
		}

		let queue = [];

		function processProperty(dbId) {
			return new Promise((resolve) => {
				viewer.current.getProperties(dbId, (props) => {
					// console.log("Properties for dbId:", dbId, props);

					let category =
						props.properties.find((p) => p.displayName === "Category")
							?.displayValue || "Unknown";
					let typeName =
						props.properties.find((p) => p.displayName === "Type Name")
							?.displayValue || "Unknown";

					//	console.log({ category, typeName, discipline });
					if (typeName !== "Unknown") {
						if (!modelData.current[category]) {
							modelData.current[category] = { elements: [], types: {} };
						}

						modelData.current[category].elements.push(dbId);

						if (typeName !== "Unknown") {
							if (!modelData.current[category].types[typeName]) {
								modelData.current[category].types[typeName] = [];
							}
							modelData.current[category].types[typeName].push(dbId);
						}
					}

					resolve();

					// if (!modelData.current[category]) {
					// 	modelData.current[category] = { elements: [], types: {} };
					// }

					// modelData.current[category].elements.push(dbId);

					// if (typeName !== "Unknown") {
					// 	if (!modelData.current[category].types[typeName]) {
					// 		modelData.current[category].types[typeName] = [];
					// 	}
					// 	modelData.current[category].types[typeName].push(dbId);
					// }

					// resolve();
				});
			});
		}

		viewer.current.getObjectTree((tree) => {
			tree.enumNodeChildren(
				tree.getRootId(),
				(dbId) => {
					queue.push(dbId);
				},
				true
			);

			let promises = queue.map((dbId) => processProperty(dbId));

			Promise.all(promises).then(() => {
				console.log("Extracted Model Properties:", modelData.current);
				sessionStorage.setItem("modelData", JSON.stringify(modelData.current));

				highlightElementsByCategory("walls");
			});
		});
	}

	function highlightElementsByCategory(category) {
		if (!viewer.current) return;

		// let dbIdsToShow = modelData.current[`Revit ${category}`]
		// 	? modelData.current[`Revit ${category}`].elements
		// 	: [];

		// if (dbIdsToShow.length === 0) {
		// 	console.log(`No elements found for category: ${category}`);
		// 	return;
		// }
		viewer.current.search(category, (dbIds) => {
			console.log({ dbIds });
			viewer.current.isolate(dbIds);
			viewer.current.fitToView(dbIds);
			viewer.current.clearSelection();
			viewer.current.select(dbIds);
		});
		// viewer.current.isolate(dbIdsToShow);
		// viewer.current.fitToView(dbIdsToShow);
		// viewer.current.clearSelection();
		// viewer.current.select(dbIdsToShow);
	}

	// Get public access token for read only
	// function getForgeToken(callback) {
	// 	forgeAuth && callback(forgeAuth.access_token, forgeAuth.expires_in);
	// }

	return <div className="viewer--forge" ref={myViewer}></div>;
};

const MapStateToProps = (state) => {
	return {
		forgeAuth: state.getIn(["building", "building", "forgeAuth"]),
	};
};

export default connect(MapStateToProps, {
	getForgePublicToken,
	getModelTranslationStatus,
	updateModelThumbnail,
})(RevitForgeViewer);
