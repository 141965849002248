import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form/immutable";
import HeaderLayout from "../../../layout/content/HeaderLayout";
import localize, { LocKeys } from "../../../../constants/localizations";
import FileUploadBody from "../../../model-update/building/steps/parts/step2/Step2.0-Body";
import FileUploadFooter from "../../../model-update/building/steps/parts/step2/FileUploadFooter";

/**
 * Step 2 - Create offer (FILE UPLOAD)
 * @param {*} props
 * @returns
 */
const FileUpload = (props) => {
	const {
		cancelButtonAction,

		formName,
		previousPage,
		nextPage,
	} = props;

	const onSubmit = () => {
		try {
			nextPage && nextPage();
		} catch (error) {
			console.log(error);
			return;
		}
	};

	return (
		<form
			className={`form d-flex flex-column flex-auto standardized-form`}
			onSubmit={props.handleSubmit(onSubmit)}
			autoComplete="off"
			noValidate
		>
			<HeaderLayout wrapperClasses="px-24 d-flex align-items-center">
				<h3>{localize(LocKeys.FILE_UPLOAD)}</h3>
			</HeaderLayout>

			<FileUploadBody isNaviate={true} formName={formName} />

			<FileUploadFooter
				cancelButtonAction={cancelButtonAction}
				previousPage={previousPage}
				isNaviate={true}
			/>
		</form>
	);
};

const mapStateToProps = (state, ownProps) => {
	return {
		form: ownProps.formName,
	};
};

export default connect(
	mapStateToProps,
	{}
)(
	reduxForm({
		touchOnBlur: false,
		destroyOnUnmount: false,
		enableReinitialize: false,
	})(FileUpload)
);
