import React, { useCallback } from "react";
import CustomStepNavigation from "../../../../../../shared/components/CustomStepper/CustomStepNavigation";
import { useNavigate } from "react-router-dom-v5-compat";
import localize, { LocKeys } from "../../../../../../constants/localizations";
import {
	setTempCreateOrderFiles,
	setTempModelUpdateFiles,
} from "../../../../../../actions";
import { connect } from "react-redux";
import FooterLayout from "../../../../../layout/content/FooterLayout";
import CustomBadge from "../../../../../../shared/components/CustomBadge/CustomBadge";

const FileUploadFooter = ({
	cancelButtonAction = null,
	isNaviate = false,
	buildingId,
	previousPage,
	...props
}) => {
	const { files, setTempModelUpdateFiles, setTempCreateOrderFiles } = props;
	const navigate = useNavigate();

	const handleUploadFiles = useCallback(
		(files) => {
			if (isNaviate) {
				setTempCreateOrderFiles(files);
			} else {
				setTempModelUpdateFiles(files);
			}
		},
		[setTempModelUpdateFiles, setTempCreateOrderFiles, isNaviate]
	);

	const renderBadgeContent = useCallback(() => {
		if (files && files.size > 0) {
			return (
				<div className="d-flex w-50 align-items-center">
					<CustomBadge
						infobarPlacement={true}
						invisible={false}
						badgeContent={files.size}
						customClasses="files-upload-indicator mr-s bg-g300"
					/>
					{localize(LocKeys.FILES_UPLOADED_SUCCESSFULLY)}
				</div>
			);
		}
		return <></>;
	}, [files]);

	return (
		<FooterLayout wrapperClasses="px-24 d-flex align-items-center">
			{renderBadgeContent()}
			<CustomStepNavigation
				showCancel={true}
				cancelButtonAction={() =>
					cancelButtonAction
						? cancelButtonAction()
						: navigate(`/buildings/${buildingId}`)
				}
				leftButtonLabel={localize(LocKeys.BACK)}
				rightButtonClasses="btn--primary"
				rightButtonLabel={localize(LocKeys.NEXT)}
				showUploadFile={true}
				handleUploadFiles={handleUploadFiles}
				leftButtonAction={() => previousPage()}
			/>
		</FooterLayout>
	);
};

const mapStateToProps = (state, ownProps) => {
	const { isNaviate = false } = ownProps;
	return {
		files: isNaviate
			? state.getIn(["projectTemp", "tempFiles"])
			: state.getIn(["modelUpdate", "tempFiles"]),
	};
};

export default connect(mapStateToProps, {
	setTempModelUpdateFiles,
	setTempCreateOrderFiles,
})(FileUploadFooter);
