import React, { useEffect, useState, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import {
	Field,
	reduxForm,
	change,
	formValueSelector,
} from "redux-form/immutable";
import {
	setBuildingTempFieldValue,
	updateBuildingLevelInfo,
	initialBuildingLevelInfo,
	createBuilding,
	clearTempBuilding,
} from "../../../../actions";
import {
	adornmentTypeConstants,
	generalConstants,
} from "../../../../constants/constants";
import localize, { LocKeys } from "../../../../constants/localizations";
import CustomStepNavigation from "../../../../shared/components/CustomStepper/CustomStepNavigation";
import renderTextField from "../../../fields/renderTextField";
import BuildingFloor from "./parts/BuildingFloor";
import "./bimifyBuilding.scss";
import validate from "../../../fields/validators/building/createBuildingValidator.js";
import { useHistory } from "react-router-dom";
import FormSubmitButtons from "../../../../shared/components/FormComponents/FormSubmitButtons";
import HeaderLayout from "../../../layout/content/HeaderLayout";
import BodyLayout from "../../../layout/content/BodyLayout";
import FooterLayout from "../../../layout/content/FooterLayout";
import { getItemFromLocalStorage } from "../../../../utils/local-storage.util";

/**
 * Shared component - for building levels edit and create
 *
 * Building levels (formLevel, toLevel, height, building floors) - view & edit
 * @param {*} props
 * @returns BuildingFloor
 */
const BuildingLevels = (props) => {
	const {
		isNaviate = false,
		previousPage,
		buildingFloors,
		updateBuildingLevelInfo,
		levelFrom,
		levelTo,
		defaultLevelHeight,
		setBuildingTempFieldValue,
		initialBuildingLevelInfo,
		fullAddress,
		formName,
		createBuilding,
		initial,
		customer,
		type,
		onClose,
		isForJob = false,
		clearTempBuilding,
		rightButtonLabel = localize(LocKeys.CREATE),
		rightButtonClasses = "btn--success",
		cancelButtonAction = null,
	} = props;
	const isEdit = generalConstants.EDIT === type;
	const history = useHistory();
	const [isValid, setIsValid] = useState(false);
	const [canUpdate, setCanUpdate] = useState(false);
	const [loading, setLoading] = useState(false);

	const setValidity = useCallback(() => {
		if (!isEdit) {
			if (!customer) {
				setIsValid(false);
			} else {
				setIsValid(true);
			}
		}
	}, [customer, isEdit]);

	useEffect(() => {
		setValidity();
	}, [setValidity]);

	useEffect(() => {
		if (initial && !isEdit) {
			initialBuildingLevelInfo(formName);
		}
	});

	useEffect(() => {
		if (
			!Number.isNaN(+levelFrom) &&
			!Number.isNaN(+levelTo) &&
			+levelFrom <= 0 &&
			+levelTo >= 0 &&
			defaultLevelHeight &&
			canUpdate
		) {
			updateBuildingLevelInfo(
				levelFrom,
				Math.abs(+levelTo),
				defaultLevelHeight
			);
		}
	}, [
		levelFrom,
		levelTo,
		defaultLevelHeight,
		updateBuildingLevelInfo,
		canUpdate,
	]);

	const onSubmit = () => {
		try {
			const tempBuilding = props.tempBuilding?.toJS();
			setLoading(true);
			const fields = [
				"name",
				"type",
				"size",
				"address",
				"additionalInfo",
				"levelFrom",
				"levelTo",
				"defaultLevelHeight",
			];
			let body = {};
			let levels = [];

			tempBuilding &&
				tempBuilding.floors.forEach((floor) => {
					levels.push({
						id: floor.id,
						isRoof: floor?.isRoof || false,
						name: floor.name,
						elevation: floor.elevation,
						thickness: floor?.thickness || 0,
						height: floor.height,
						level: floor.level,
					});
				});

			fields.forEach((field) => (body[field] = tempBuilding[field]));
			body["levels"] = levels;
			body["selectedCompany"] = +getItemFromLocalStorage("Customer");
			if (isNaviate) {
				props.nextPage(levels);
			} else if (isEdit) {
				props.updateLevels(body);
			} else {
				if (fullAddress) {
					body.address = fullAddress;
				}
				createBuilding(body);
				history.goBack();
			}
		} catch (error) {
			return;
		}
	};

	const updateFormValue = (field, value) => {
		if (!field || (!value && value !== 0)) {
			return;
		}

		props.dispatch(change(formName, field, value));
		setCanUpdate(true);
	};

	const setValue = (fieldKey, value) => {
		if (!Number.isNaN(+value)) {
			setBuildingTempFieldValue(fieldKey, value);
			setCanUpdate(true);
		}
	};

	//const lowestLevel = buildingFloors?.slice(-1)[0]?.level || 0;

	const floorValid = () => {
		return (
			buildingFloors && buildingFloors.some((floor) => floor.valid === false)
		);
	};

	const bgClass = useMemo(() => {
		if (isNaviate) {
			return "bg-n000";
		}

		return "bg-primary--lighter";
	}, [isNaviate]);
	return (
		<form
			className={`form d-flex flex-column flex-auto standardized-form	`}
			onSubmit={props.handleSubmit(onSubmit)}
			autoComplete="off"
			noValidate
		>
			{!isForJob && !isEdit && (
				<HeaderLayout wrapperClasses="px-24 d-flex align-items-center">
					<h3>{localize(LocKeys.LEVELS)}</h3>
				</HeaderLayout>
			)}
			<BodyLayout
				isTableView={true}
				wrapperClasses={`single-building__bimify ${
					isEdit && !isForJob
						? "page-content--medium"
						: isForJob
							? "job--description "
							: "page-content--small"
				} overflow-y-auto w-100 bg-n300 pb-24`}
			>
				<div className={` ${isForJob ? "px-124" : "px-24"} pt-24 ${bgClass}`}>
					<div className="row row--m-gutters align-items-end">
						<div
							className={`col col-30   ${
								(isEdit && !isForJob) || isNaviate
									? "col-tablet-30"
									: "col-tablet-15"
							}`}
						>
							<Field
								name={"levelFrom"}
								id={"levelFrom"}
								component={renderTextField}
								onKeyDown={(value) => setValue("levelFrom", value)}
								customUpdateValue={updateFormValue}
								inputMode="numeric"
								classField="mb-m"
								reverseAdornment={true}
								numberAdornment={true}
								max="0"
								label={localize(LocKeys.LOWER_LEVELS)}
							></Field>
						</div>
						<div
							className={`col col-30   ${
								(isEdit && !isForJob) || isNaviate
									? "col-tablet-30"
									: "col-tablet-15"
							}`}
						>
							<Field
								name={"levelTo"}
								id={"levelTo"}
								classField="mb-m"
								component={renderTextField}
								onKeyDown={(value) => setValue("levelTo", value)}
								customUpdateValue={updateFormValue}
								inputMode="numeric"
								numberAdornment={true}
								min="0"
								label={localize(LocKeys.UPPER_LEVELS)}
							></Field>
						</div>

						<div
							className={` col col-60   ${
								(isEdit && !isForJob) || isNaviate
									? "col-tablet-40"
									: "col-tablet-30"
							}`}
						>
							<Field
								name="defaultLevelHeight"
								id="defaultLevelHeight"
								classField="mb-m"
								onKeyDown={(value) => setValue("defaultLevelHeight", value)}
								component={renderTextField}
								label={localize(LocKeys.DEFAULT_LEVEL_HEIGHT)}
								type="number"
								adornmentType={adornmentTypeConstants.MILIMETERS}
							/>
						</div>
					</div>
				</div>

				<div className="building-model">
					{floorValid() && (
						<div
							className={` ${isForJob ? "px-124" : "px-24"} py-m ${bgClass}`}
						>
							<div className={`elevation__error ${bgClass}`}>
								{localize(LocKeys.ELAVATION_INFO_MESSAGE)}
							</div>
						</div>
					)}

					<div className={` ${isForJob ? "px-124" : "px-24"} ${bgClass}`}>
						<div className="border-b-1 border-n150">
							<div className="row row--m-gutters pt-m pb-s">
								<div className="col col-60"></div>
								<div className="col col-40 d-flex align-items-end">
									<div className="row row--no-gutters">
										<div className="col col-20 pl-0 pr-0"></div>
										<div className="col col-40 pr-0">
											<h5 className="small color-n300">
												{localize(LocKeys.ELEVATION)}
											</h5>
										</div>
										<div className="col col-40 pr-0">
											<h5 className="small color-n300">
												{localize(LocKeys.THICKNESS)}
											</h5>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className={`floors pt-24 ${bgClass}`}>
						<div>
							{buildingFloors &&
								buildingFloors.map((floor) => {
									let last = buildingFloors.length - 1;
									let isLowestFloor = buildingFloors[last].key === floor.key;
									let isHighestFloor = buildingFloors[0].key === floor.key;
									let isSecondFloor = buildingFloors[1].key === floor.key;

									return (
										<div
											id={`level_${floor.level}`}
											className={`
													floor__wrapper w-100 ${isForJob ? "px-124" : "px-24"}  
													${floor.level >= 0 || !!floor?.isRoof ? bgClass : "bg-n300"}
												`}
											key={floor.level}
										>
											<BuildingFloor
												isSecondFloor={isSecondFloor}
												formName={formName}
												isLowestFloor={isLowestFloor}
												isHighestFloor={isHighestFloor}
												floor={floor}
											/>
										</div>
									);
								})}
						</div>
					</div>
				</div>
			</BodyLayout>

			<FooterLayout wrapperClasses="px-24">
				{!isEdit ? (
					<CustomStepNavigation
						showCancel={true}
						cancelButtonAction={() => {
							clearTempBuilding();
							cancelButtonAction
								? cancelButtonAction()
								: history.push("/buildings");
						}}
						rightButtonClasses={rightButtonClasses}
						leftButtonAction={() => previousPage()}
						rightButtonDisabled={!isValid || floorValid() || loading}
						rightButtonLoading={loading}
						rightButtonLabel={rightButtonLabel}
					/>
				) : (
					<FormSubmitButtons
						wrapperClasses="w-100 pt-24"
						onCancel={onClose}
						submitDisabled={floorValid()}
						onSubmit={() => {}}
						loading={loading}
					/>
				)}
			</FooterLayout>
		</form>
	);
};

const MapStateToProps = (state, ownProps) => {
	const selector = formValueSelector(ownProps.formName);

	let levelFrom = selector(state, "levelFrom");
	let levelTo = selector(state, "levelTo");
	let defaultLevelHeight = selector(state, "defaultLevelHeight");

	let initialValues = {
		levelFrom:
			state.getIn(["building", "tempBuilding", "levelFrom"]) ||
			selector(state, "levelFrom"),
		levelTo:
			state.getIn(["building", "tempBuilding", "levelTo"]) ||
			selector(state, "levelTo"),
		defaultLevelHeight:
			state.getIn(["building", "tempBuilding", "defaultLevelHeight"]) ||
			selector(state, "defaultLevelHeight"),
	};

	initialValues["defaultLevelHeight"] = Number(
		initialValues.defaultLevelHeight
	)?.toFixed(0);

	let buildingFloors = state.getIn(["building", "tempBuilding", "floors"]);

	return {
		form: ownProps.formName,
		initial: state.getIn(["building", "tempBuilding", "initial"]),
		tempBuilding: state.getIn(["building", "tempBuilding"]),

		fullAddress: state.getIn(["building", "fullAddress"]),
		buildingFloors,
		customer: state.getIn(["global", "customer"]),

		initialValues,
		levelFrom,
		levelTo,
		defaultLevelHeight,
	};
};

export default connect(MapStateToProps, {
	updateBuildingLevelInfo,
	setBuildingTempFieldValue,
	initialBuildingLevelInfo,
	createBuilding,
	clearTempBuilding,
})(
	reduxForm({
		validate,
		touchOnBlur: false,
		destroyOnUnmount: false,
	})(BuildingLevels)
);
