/**
 * Function for validating fields from project form
 */

import localize, { LocKeys } from "../../../../constants/localizations";

export default (formValues) => {
	const errors = {};
	if (!formValues.get("numberOfBuildings")) {
		errors.numberOfBuildings = localize(LocKeys.FIELD_REQUIRED, [
			localize(LocKeys.NUMBER_OF_BUILDINGS),
		]);
	}

	if (!formValues.get("name")) {
		errors.name = localize(LocKeys.FIELD_REQUIRED, [localize(LocKeys.NAME)]);
	}

	if (!formValues.get("deliveryFormat")) {
		errors.clientCompany = localize(LocKeys.FIELD_REQUIRED, [
			localize(LocKeys.DELIVERY_FORMAT),
		]);
	}

	return errors;
};
