import { Map, fromJS } from "immutable";
import {
	fileStatusConstants,
	snackbarConstants,
	snackbarTypeConstants,
} from "../constants/constants";
import {
	getItemFromLocalStorage,
	removeItemFromLocalStorage,
	setItemToLocalStorage,
} from "../utils/local-storage.util";
import localize, { LocKeys } from "../constants/localizations";

/**
 * Global snackbar reducer redux
 */

const initialState = Map({
	open: false,
	type: null,
	files: Map({}),
	bimifyProgress: Map({
		inProgress: false,
		progress: 1,
		message: "",
		canCancelApsJob: false,
	}),
	zipName: "",
});

//

export default (state = initialState, action) => {
	/**
	 * Update value for upload file progress
	 * @param {*} files - updated value
	 * @returns
	 */
	const updateUploadProgress = (files) => {
		const oldFiles = state.get("files");
		const oldFilesJS = oldFiles.size > 0 ? oldFiles.toJS() : [];

		files.forEach((updateFile) => {
			const index = oldFilesJS.findIndex(
				(oldFile) => oldFile.id === updateFile.id
			);

			if (index !== -1) {
				oldFilesJS[index].value = updateFile.value;
				if (updateFile.value === 100) {
					oldFilesJS[index].status = fileStatusConstants.COMPLETED;
				}
			}
		});

		const inProgress = oldFilesJS.find(
			(file) => file.status === fileStatusConstants.IN_PROGRESS
		);
		if (!inProgress) {
			return state.set("open", false).set("type", null).set("files", Map());
		}

		return state.set("files", fromJS(oldFilesJS));
	};

	/**
	 * Set initial value for FILES in state
	 * @param {*} files - initial value
	 * @returns
	 */
	const setFiles = (files) => {
		const oldFiles = state.get("files");
		const oldFilesJS = oldFiles ? oldFiles.toJS() : [];
		const newFiles = [];

		oldFilesJS.length > 0 &&
			oldFilesJS.forEach((file) => {
				newFiles.push(file);
			});

		files.forEach((file) => {
			const exist = newFiles.some((newFile) => newFile.id === file.id);

			if (!exist)
				newFiles.push({
					id: file.id,
					name: file.name,
					size: file.size,
					value: file.value,
					source: file.source,
					status: fileStatusConstants.IN_PROGRESS,
				});
		});

		return state.set("files", fromJS(newFiles));
	};

	/**
	 * Remove single file
	 * @param {*} fileId - file to be removed
	 * @returns
	 */
	const removeFile = (fileId) => {
		const oldFiles = state.get("files");
		const oldFilesJS = oldFiles ? oldFiles.toJS() : [];

		const newFiles = oldFilesJS.filter((file) => file.id !== fileId);

		const filesLength = newFiles.length > 0;

		return filesLength
			? state.set("files", fromJS(newFiles))
			: state.set("open", false).set("type", null).set("files", Map());
	};

	/**
	 * Cancel API request for upload file
	 * @param {*} fileId - file to be canceled upload
	 * @returns
	 */
	const cancelUploadRequest = (fileId) => {
		const oldFiles = state.get("files");
		let oldFilesJS = oldFiles ? oldFiles.toJS() : [];
		const index = oldFilesJS.findIndex((file) => file.id === fileId);

		if (index !== -1) {
			if (oldFilesJS[index].status !== fileStatusConstants.CANCELED) {
				oldFilesJS[index].source.cancel();
				oldFilesJS[index].status = fileStatusConstants.CANCELED;
			}
		}

		return state.set("files", fromJS(oldFilesJS));
	};

	/**
	 * Remove all files from state and CANCEL API request
	 * @returns
	 */
	const removeFiles = () => {
		const files = state.get("files");
		let filesJS = files ? files.toJS() : [];

		filesJS.forEach((file) => {
			if (file.status !== fileStatusConstants.CANCELED) {
				file.source.cancel();
			}
		});
		return state.set("files", Map());
	};

	const updateBimifyProgress = (data) => {
		const {
			open = true,
			type = snackbarTypeConstants.BIMIFY_PROGRESS,
			jobId,
			jobStatusId,
			progress = 1,
			message = "",
			inProgress,
			errorResponse = false,
			successResponse = false,
			canCancelApsJob,
		} = data;

		const tempData = {
			jobId,
			jobStatusId,
			progress,
			message,
			inProgress,
			open,
			type,
			errorResponse,
			successResponse,
			canCancelApsJob:
				canCancelApsJob || state.getIn(["bimifyProgress", "canCancelApsJob"]),
		};
		setItemToLocalStorage("bimifyProgress", JSON.stringify(tempData));
		return state
			.set("open", open)
			.set("type", type)
			.setIn(["bimifyProgress", "progress"], progress)
			.setIn(["bimifyProgress", "message"], message)
			.setIn(["bimifyProgress", "inProgress"], inProgress)
			.setIn(["bimifyProgress", "jobId"], jobId)
			.setIn(["bimifyProgress", "jobStatusId"], jobStatusId)
			.setIn(["bimifyProgress", "errorResponse"], errorResponse)
			.setIn(["bimifyProgress", "successResponse"], successResponse)
			.setIn(
				["bimifyProgress", "canCancelApsJob"],
				canCancelApsJob || state.getIn(["bimifyProgress", "canCancelApsJob"])
			);
	};

	const updateExtractProgress = (data) => {
		const {
			open = true,
			type = snackbarTypeConstants.EXTRACT_PROGRESS,
			jobId,
			tempProgress = 0,
			totalLevels,
			disciplineId,
			completeExtraction = false,
			buildingLevels = [],
			buildingId,
			progress = 1,
			message = "",
			inProgress,
			errorResponse = false,
			successResponse = false,
		} = data;

		const tempData = {
			jobId,
			totalLevels,
			completeExtraction,
			tempProgress,
			buildingLevels,
			disciplineId,
			buildingId,
			progress,
			message,
			inProgress,
			open,
			type,
			errorResponse,
			successResponse,
		};
		setItemToLocalStorage("extractProgress", JSON.stringify(tempData));

		return state
			.set("open", open)
			.set("type", type)
			.setIn(["extractProgress", "completeExtraction"], completeExtraction)
			.setIn(["extractProgress", "totalLevels"], totalLevels)
			.setIn(["extractProgress", "tempProgress"], tempProgress)
			.setIn(["extractProgress", "buildingLevels"], buildingLevels)
			.setIn(["extractProgress", "progress"], progress)
			.setIn(["extractProgress", "message"], message)
			.setIn(["extractProgress", "inProgress"], inProgress)
			.setIn(["extractProgress", "jobId"], jobId)
			.setIn(["extractProgress", "disciplineId"], disciplineId)
			.setIn(["extractProgress", "buildingId"], buildingId)
			.setIn(["extractProgress", "errorResponse"], errorResponse)
			.setIn(["extractProgress", "successResponse"], successResponse);
	};

	const updateExtractProgressLabelingTool = (data) => {
		const { action } = data;
		const extractProgress = getItemFromLocalStorage("extractProgress")
			? JSON.parse(getItemFromLocalStorage("extractProgress"))
			: null;

		if (action) {
			extractProgress.progress = 95;
			extractProgress.message = localize(LocKeys.CREATE_XML_FILE);
		} else {
			extractProgress.progress = 100;
			extractProgress.tempProgress = 0;
			extractProgress.open = false;
			extractProgress.type = null;
			extractProgress.inProgress = false;
			extractProgress.successResponse = true;
		}

		return updateExtractProgress(extractProgress);
	};

	switch (action.type) {
		case snackbarConstants.OPEN:
			return state.set("open", true).set("type", action.data);

		case snackbarConstants.CLOSE:
			return state.set("open", false).set("type", null);

		case snackbarConstants.SET_FILES_DATA:
			return setFiles(action.data);

		case snackbarConstants.REMOVE_FILE:
			return removeFile(action.data);

		case snackbarConstants.CANCEL_UPLOAD_REQUEST:
			return cancelUploadRequest(action.data);

		case snackbarConstants.UPDATE_UPLOAD_PROGRESS:
			return updateUploadProgress(action.data);

		case snackbarConstants.REMOVE_FILES:
			return removeFiles();

		case snackbarConstants.UPDATE_BIMIFY_PROGRESS:
			return updateBimifyProgress(action.data);

		case snackbarConstants.UPDATE_EXTRACT_PROGRESS:
			return updateExtractProgress(action.data);

		case snackbarConstants.UPDATE_EXTRACT_PROGRESS_LABELING_TOOL:
			return updateExtractProgressLabelingTool(action.data);

		case snackbarConstants.CANCEL_BIMIFY_PROGRESS:
			const bimifyProgress = getItemFromLocalStorage("bimifyProgress")
				? JSON.parse(getItemFromLocalStorage("bimifyProgress"))
				: null;

			const tempData = {
				...bimifyProgress,
				canCancelApsJob: action.data,
			};

			setItemToLocalStorage("bimifyProgress", JSON.stringify(tempData));

			return state.setIn(["bimifyProgress", "canCancelApsJob"], action.data);

		case snackbarConstants.RESET_BIMIFY_PROGRESS:
			removeItemFromLocalStorage("bimifyProgress");
			return state
				.set("open", false)
				.set("type", null)
				.set("bimifyProgress", initialState?.get("bimifyProgress"));

		case snackbarConstants.SET_ZIP_NAME:
			return state.set("zipName", action.data);

		default:
			return state;
	}
};
