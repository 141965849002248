import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom-v5-compat";
import {
	clearOfferQueryParameter,
	clearPricing,
	clearTempProject,
	reConfigurationPackage,
} from "../../../../../actions";
import localize, { LocKeys } from "../../../../../constants/localizations";
import { canOrderFromPackage } from "../../../permission";
import { areDeeplyEqual } from "../../../../../utils/object.util";
import {
	getItemFromLocalStorage,
	setItemToLocalStorage,
} from "../../../../../utils/local-storage.util";

const OrderAction = (props) => {
	const {
		isForPublicView,
		initialExports,
		lodSpecification,
		exports,
		initialDisciplineComponentCategoryLods,
		disciplineComponentCategoryLods,
		//Actions
		clearPricing,
		clearTempProject,
		clearOfferQueryParameter,
		reConfigurationPackage,
	} = props;
	const navigate = useNavigate();
	const { packageId } = useParams();
	const isNaviate = getItemFromLocalStorage("isNaviate");

	useEffect(() => {
		if (disciplineComponentCategoryLods) {
			reConfigurationPackage(packageId, {
				disciplineComponentCategoryLods,
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [packageId, disciplineComponentCategoryLods]);

	const orderAction = useCallback(() => {
		const isExportEqual = areDeeplyEqual(initialExports, exports);
		const isDisciplineEqual = areDeeplyEqual(
			initialDisciplineComponentCategoryLods,
			disciplineComponentCategoryLods
		);

		clearTempProject({ clearBuildingState: true });
		clearPricing();
		clearOfferQueryParameter();

		const data = {
			exports,
			disciplineComponentCategoryLods,
			lodSpecification,
			isDisciplineEqual,
		};

		setItemToLocalStorage("packageConfiguration", JSON.stringify(data));
		const path = isNaviate ? "/offers/naviate-new" : "/offers/new";
		navigate(
			`${path}?packageId=${packageId}${!isExportEqual || !isDisciplineEqual ? "&isActiveConfiguration=true" : ""}`
		);
	}, [
		initialExports,
		exports,
		clearPricing,
		clearTempProject,
		navigate,
		packageId,
		clearOfferQueryParameter,
		initialDisciplineComponentCategoryLods,
		disciplineComponentCategoryLods,
		lodSpecification,
		isNaviate,
	]);
	return (
		<button
			className="btn btn--primary w-100"
			type="button"
			disabled={isForPublicView ? false : canOrderFromPackage()}
			onClick={orderAction}
		>
			{localize(LocKeys.ORDER)}
		</button>
	);
};

const mapStateToProps = (state) => {
	const exports = state
		.getIn(["dashboard", "tempSalesPackage", "editedExports"])
		?.toJS();

	return {
		disciplineComponentCategoryLods: state.getIn([
			"lodSpecification",
			"tempLodSpecification",
			"disciplineComponentCategoryLods",
		]),
		initialDisciplineComponentCategoryLods: state.getIn([
			"lodSpecification",
			"tempLodSpecification",
			"initialDisciplineComponentCategoryLods",
		]),
		lodSpecification: state.getIn([
			"dashboard",
			"tempSalesPackage",
			"lodSpecification",
		]),
		initialExports: state.getIn([
			"dashboard",
			"tempSalesPackage",
			"initialEditedExports",
		]),
		exports,
	};
};

export default connect(mapStateToProps, {
	clearOfferQueryParameter,
	clearPricing,
	clearTempProject,
	reConfigurationPackage,
})(OrderAction);
