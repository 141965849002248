import { Badge } from "@mui/material";
import React from "react";

const CustomBadge = ({
	invisible = true,
	listIndicator = false,
	badgeContent = "",
	infobarPlacement = false,
	children,
	chatIndicator = false,
	customClasses = false,
	wrapperClasses = "",
}) => {
	const placementClasses = infobarPlacement
		? !!badgeContent
			? "badge--relative badge--transform-none"
			: "badge--relative"
		: "";

	return (
		<Badge
			classes={{
				root: `badge__wrapper
					${invisible ? "badge__wrapper--invisible" : ""}
					${wrapperClasses}
				`,
				badge: `badge
					${chatIndicator ? "chat-indicator" : ""}
					${listIndicator ? "list-indicator" : ""}
					${infobarPlacement ? "badge--infobar" : ""}
					${!!badgeContent ? "badge--with-content" : ""}
					${placementClasses}
					${customClasses}
				`,
				invisible: "badge--invisible",
			}}
			invisible={invisible}
			overlap="circular"
			badgeContent={badgeContent}
			max={99}
		>
			{children}
		</Badge>
	);
};

export default CustomBadge;
