import React from "react";
import EditButton from "../../../../shared/components/buttons/EditButton";
import CustomBadge from "../../../../shared/components/CustomBadge/CustomBadge";

/**
 * Custom section header - offer view
 * @param {*} param0
 * @returns
 */
const SectionHeader = ({
	title,
	wrapperClasses = "",
	isEdit = false,
	customBadge = false,
	badgeContent = "",
	handleEdit = () => {},
}) => {
	return (
		<div className={`d-flex align-items-center ${wrapperClasses}`}>
			{customBadge ? (
				<CustomBadge
					infobarPlacement={true}
					invisible={false}
					badgeContent={badgeContent}
					customClasses={`text-info`}
					wrapperClasses={`align-items-center`}
				>
					<h4 className={`color-n350`}>{title}</h4>
				</CustomBadge>
			) : (
				<h4 className={`color-n350`}>{title}</h4>
			)}

			{!!isEdit && (
				<EditButton wrapperClasses="ml-auto" onClick={() => handleEdit()} />
			)}
		</div>
	);
};

export default SectionHeader;
